import {
  Card,
  CardContent,
  Typography,
  Grid,
  Stack,
  Button,
  Box,
} from "@mui/material";
import Person2Icon from "@mui/icons-material/Person2Outlined";
import ArrowRightIcon from "../Commons/Icons/ArrowRightIcon";

const ContactCard = ({
  icon,
  title,
  info,
  linkTo,
  contactStatus,
  contactStatusText,
}) => {
  return (
    <Card
      onClick={linkTo}
      sx={{
        borderRadius: "16px",
        px: 2,
        pt: { xs: 1, sm: 2 },
        mt: 2,
        boxShadow: "4px 4px 20px 0px #0000001A",
        cursor: "pointer",
      }}
    >
      <CardContent>
        <Grid container>
          <Grid item xs={6}>
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
              <Box>
                <Person2Icon
                  sx={{
                    color: "primary.main",
                    width: "32px",
                    height: "32px",
                  }}
                />
              </Box>

              <Stack>
                <Typography
                  variant="body4"
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  {title}{" "}
					{contactStatus && (
				  <Box
                    sx={{
                      borderRadius: "3px",
                      display: "inline",
                      ml: "1rem",
                      p: "3px",
                      pl: "6px",
                      pr: "6px",
                      fontWeight: 400,
                      color: "primary.light",
                    }}
                  >
				    
					{contactStatusText}
					  </Box>)}
                </Typography>

                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: 400,
                    color: "primary.main",
                  }}
                >
                  {info}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              variant="text"
              sx={{ minWidth: "44px", width: "44px !important", height: "44px" }}
            >
              <ArrowRightIcon />
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ContactCard;