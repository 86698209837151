import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchWithAuth } from "../api";

export const fetchHats = createAsyncThunk("hats/fetchHats", async () => {
  const response = await fetchWithAuth("hats");
  return response.hats;
});

const getAccess = (type, date) => {
  switch (type) {
    case "instant":
      return true;
    case "timed":
      if (date) {
        const currentDate = new Date();
        const accessDate = new Date(date);
        return currentDate >= accessDate;
      }
      return false;
    case "onDeath":
      return false;
    default:
      return false;
  }
};

const getAccessOnDeath = (type) => {
  return type === "onDeath";
}

export const checkAccess = createAsyncThunk(
  "hats/checkAccess",
  async (id, { getState }) => {
    let { hats } = getState().hats;

    if (!id || id === "null") {
      return {
        accessAssets: true,
        accessDiaryItems: true,
        accessPostMortemRequests: true,
      };
    }

    if (hats.length === 0) {
      const response = await fetchWithAuth("hats");
      hats = response.hats;
    }

    const hat = hats.find((hat) => hat.id == id);
    if (!hat) {
      return {
        accessAssets: false,
        accessDiaryItems: false,
        accessPostMortemRequests: false,
      };
    }

    return {
      accessAssets: getAccess(hat.accessTypeAssets),
      accessAssetsOnDeath: getAccessOnDeath(hat.accessTypeAssets),
      accessDiaryItems: getAccess(
        hat.accessTypeDiaryItems,
        hat.accessAtDiaryItems
      ),
      accessDiaryItemsOnDeath: getAccessOnDeath(hat.accessTypeDiaryItems),
      accessPostMortemRequests: getAccess(
        hat.accessTypePostMortemRequests,
        hat.accessAtPostMortemRequests
      ),
      accessPostMortemRequestsOnDeath: getAccessOnDeath(hat.accessTypePostMortemRequests),
    };
  }
);

const hatsSlice = createSlice({
  name: "hats",
  initialState: {
    hats: [],
    status: "idle",
    error: null,
    access: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHats.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchHats.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.hats = action.payload;
      })
      .addCase(fetchHats.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(checkAccess.fulfilled, (state, action) => {
        state.access = action.payload;
      })
      .addCase(checkAccess.rejected, (state, action) => {
        state.access = null;
        state.error = action.error.message;
      });
  },
});

export const getHat = (state) => state.hats.hats[0];

export const getHats = (state) => state.hats.hats;

export default hatsSlice.reducer;
