import { MainMenu } from "../Navigation/MainMenu";
import { MainMobileMenu } from "../Navigation/MainMobileMenu";
import { Footer } from "../Footer/Footer";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DesktopTopMenu } from "../Navigation/HomeNavigation/DesktopTopMenu";
import { Alert, Box, Snackbar } from "@mui/material";
import { MobileTopMenu } from "../Navigation/HomeNavigation/MobileTopMenu";
import {
  getCurrentUser,
  logoutUser,
  recoverProfile,
} from "../../store/Actions/userAction";
import { checkAccess } from "../../store/Slices/hatsSlice";
import { hideSnackbar } from "../../store/Slices/snackbarSlice";
import {
  fetchExchangeRates,
  setSuccessStatus,
} from "../../store/Slices/exchangeRatesSlice";
import FAB from "../Common/FAB";
import ConfirmationModal from "../Commons/Modals/ConfirmationModal";
import { t } from "i18next";
import HandGestureIcon from "../Commons/Icons/HandGestureIcon";
import { fetchHints } from "../../store/Slices/hintSlice";

const DefaultLayout = ({ children, globalEvent, setGlobalEvent }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { loggedIn } = useSelector((state) => state.user);
  const { status: hatsStatus } = useSelector((state) => state.hats);
  const snackbar = useSelector((state) => state.snackbar);
  const { status: exchangeRatesStatus } = useSelector(
    (state) => state.exchangeRates
  );
  const { user } = useSelector((state) => state.user);
  const [recoverOpen, setRecoverOpen] = useState(false);
  const navigate = useNavigate();

  const [mobileView, setMobileView] = useState(
    window.matchMedia("(max-width: 900px)").matches
  );

  const chid = localStorage.getItem("chid");

  useEffect(() => {
    const fetchRates = async () => {
      try {
        await dispatch(getCurrentUser());
        await dispatch(checkAccess(chid));

        if (typeof setGlobalEvent === "function") {
          setGlobalEvent({ type: "doGetUser", data: {} });
        }

        if (
          exchangeRatesStatus === "idle" ||
          localStorage.getItem("exchangeRates") === null
        ) {
          await dispatch(fetchExchangeRates());
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchRates();
  }, []);

  useEffect(() => {
    dispatch(checkAccess(chid));

    if (user && user?.deletedAt) {
      setRecoverOpen(true);
    }
  }, [chid, dispatch, hatsStatus]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, location.pathname);

  useEffect(() => {
    const handleResize = () => {
      const mql = window.matchMedia("(max-width: 900px)");
      setMobileView(mql.matches);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSnackbarClose = () => {
    dispatch(hideSnackbar());
  };

  const handleRecoverClose = () => {
    setRecoverOpen(false);
    dispatch(logoutUser());
    navigate("/login");
  };

  const handleRecover = () => {
    dispatch(recoverProfile());
    setRecoverOpen(false);
  };

  useEffect(() => {
    const slug = location.pathname.substring(1);
    if (slug) {
      dispatch(fetchHints(slug));
    } else {
      dispatch(fetchHints('dashboard'));
    }
  }, [location, dispatch]);

  return (
    <>
      {loggedIn && user?.id ? (
        !mobileView ? (
          <MainMenu globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} />
        ) : (
          <MainMobileMenu
            globalEvent={globalEvent}
            setGlobalEvent={setGlobalEvent}
          />
        )
      ) : !mobileView ? (
        <>
          <DesktopTopMenu
            globalEvent={globalEvent}
            setGlobalEvent={setGlobalEvent}
          />
        </>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <MobileTopMenu
            globalEvent={globalEvent}
            setGlobalEvent={setGlobalEvent}
          />
        </Box>
      )}
      <div className="my-container">
        <div
          className="FAB"
          style={{
            flex: 1,
            position: "relative",
            display: user ? "abolute" : "none",
          }}
        >
          <FAB />
        </div>
      </div>
      <div style={{ flex: 1, position: "relative" }}>{children}</div>

      <Footer />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <ConfirmationModal
        open={recoverOpen}
        onClose={handleRecoverClose}
        title={t("recoverProfileModal.title")}
        description={t("recoverProfileModal.text")}
        cancelText={t("recoverProfileModal.cancelBtn")}
        confirmText={t("recoverProfileModal.confirmBtn")}
        onConfirm={handleRecover}
        icon={<HandGestureIcon />}
      />
    </>
  );
};

export default DefaultLayout;
