import React, { useEffect, useState } from "react";

import EyeIcon from "@mui/icons-material/VisibilityOutlined";
import EyeOffIcon from "@mui/icons-material/VisibilityOffOutlined";

import { useForm } from "react-hook-form";

import {
  Box,
  Button,
  Stack,
  TextField,
  InputAdornment,
  Typography,
  IconButton,
  Checkbox,
  Link as MuiLink,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";
import { Link, useNavigate } from "react-router-dom";
import { setRegConsentToMarketing } from "../../store/Actions/userAction";
import { useDispatch } from "react-redux";

import PasswordChecklist from "react-password-checklist";
import "./RegStep2.css";

export const RegStep2 = ({ globalEvent, setGlobalEvent }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    mode: "onBlur",
  });
  
  const onSubmit = async (data, e) => {
    //const recaptcha = await load(process.env.REACT_APP_RECAPTCHA_SITE_KEY);
    //console.log("recaptcha", recaptcha);
    //const token = await recaptcha.execute("register");
    //console.log("recaptcha token", token);
    doRegister(e, data);
    //console.log(data, e);
  };

  const onError = (errors, e) => {
    //console.log(errors, e);
  };

  const setMarketing = (boolVal) => {
    dispatch(setRegConsentToMarketing(boolVal));
  };

  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);

  const [type, setType] = useState("password");
  const [visibility, setVisibility] = useState(true);
  const [promoCodeOn, setPromoCodeOn] = useState(false);

  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const doRegister = (event, data) => {
    event.stopPropagation();
    if (data.hasOwnProperty("promoCode")) {
      if (!promoCodeOn || data.promoCode == "") delete data.promoCode;
    }
    setGlobalEvent({ type: "doRegister", data: data });
  };

  useEffect(() => {

    if (globalEvent?.type == "showRegisterError") {
      if (globalEvent?.data.hasOwnProperty("postData")) {

        setValue("email", globalEvent?.data.postData.email, {
          shouldValidate: true,
          shouldDirty: true,
        });
      }

      if (globalEvent?.data.hasOwnProperty("postData")) {
        if (globalEvent?.data.postData.promoCode) {
          setPromoCodeOn(true);
          setValue("promoCode", globalEvent?.data.postData.promoCode, {
            shouldValidate: true,
            shouldDirty: true,
          });
        }
      }

      if (globalEvent?.data.hasOwnProperty("errors")) {
        if (
          globalEvent?.data.errors[0] &&
          globalEvent?.data.errors[0].hasOwnProperty("code")
        ) {
          setGlobalEvent({
            type: "showToast",
            data: {
              message: t("errors.login." + globalEvent?.data.errors[0].code),
            },
          });
        } else if (globalEvent?.data.hasOwnProperty("message")) {
          setGlobalEvent({ type: "showToast", data: globalEvent?.data });
        } else {
          setGlobalEvent({
            type: "showToast",
            data: { message: t("errors.login.register") },
          });
        }
      } else {
        
        setGlobalEvent({
          type: "showToast",
          data: { message: t("errors.login.register") },
        });
      }
    }
  }, [globalEvent]);

  return (
    <Box
      sx={{
        mt: { sx: 0, sm: 16 },
        px: { xs: 2, lg: 0 },
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Box>
        <Typography variant="h6" sx={{}}>
          {ReactHtmlParser(t("registration.title"))}
        </Typography>
      </Box>
      <Box sx={{ mt: 1 }}>
        <Box component="form" onSubmit={handleSubmit(onSubmit, onError)}>
          <div>
            <TextField
              key="email"
              className="fullwidth-field"
              label={ReactHtmlParser(t("field.email"))}
              type="email"
              {...register("email", {
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: ReactHtmlParser(t("changeemail.error")),
                },
              })}
            />

            {errors.email && <span role="alert">{errors.email.message}</span>}

            <TextField
              key="password"
              className="fullwidth-field"
              label={ReactHtmlParser(t("field.password"))}
              type={type}
              {...register("password", {
                required: ReactHtmlParser(t("errors.field.required")),
              })}
              InputProps={{
                onChange: (e) => {
                  setPassword(e.target.value);
                },
                endAdornment: (
                  <InputAdornment sx={{ mt: -1.5 }} position="end">
                    <IconButton
                      onClick={(e) => {
                        setType(visibility ? "text" : "password");
                        setVisibility(!visibility);
                      }}
                    >
                      {visibility ? <EyeIcon /> : <EyeOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {errors.password && (
              <span role="alert">{errors.password.message}</span>
            )}

            {password.length > 0 && (
              <PasswordChecklist
                rules={[
                  "minLength",
                  "specialChar",
                  "number",
                  "capital",
                  "match",
                ]}
                minLength={12}
                value={password}
                valueAgain={passwordAgain}
                className="password-checklist"
                messages={{
                  match: t("error.password.match"),
                  minLength: t("error.password.minLength"),
                  specialChar: t("error.password.specialChar"),
                  number: t("error.password.number"),
                  capital: t("error.password.capital"),
                }}
                onChange={(isValid) => {
                  setPasswordValid(isValid);
                }}
              />
            )}

            <TextField
              key="confirm_password"
              className="fullwidth-field"
              label={ReactHtmlParser(t("field.password"))}
              type={type}
              {...register("confirm_password", {
                required: ReactHtmlParser(t("errors.field.required")),
                validate: (val) => {
                  //if (watch("password") != val) {
                  //return t("error.password.match");
                  //}
                },
              })}
              InputProps={{
                onChange: (e) => {
                  setPasswordAgain(e.target.value);
                },
              }}
            />
            {errors.confirm_password && (
              <span role="alert">{errors.confirm_password.message}</span>
            )}

            {!promoCodeOn && (
              <Typography sx={{ textAlign: "end" }}>
                <MuiLink
                  className="secondary-blue-text-link"
                  sx={{
                    cursor: "pointer",
                    color: "primary.light",
                    textDecoration: "none",
                  }}
                  onClick={(e) => {
                    setPromoCodeOn(true);
                  }}
                >
                  {ReactHtmlParser(t("common.set_promotion_code"))}
                </MuiLink>
              </Typography>
            )}

            {promoCodeOn && (
              <>
                <TextField
                  key="promoCode"
                  className="fullwidth-field"
                  label={ReactHtmlParser(t("field.promoCode"))}
                  type="text"
                  {...register("promoCode")}
                />
                {errors.promoCode && (
                  <span role="alert">{errors.promoCode.message}</span>
                )}
                <Typography sx={{ textAlign: "end" }}>
                  <MuiLink
                    className="secondary-blue-text-link"
                    sx={{
                      cursor: "pointer",
                      color: "primary.light",
                      textDecoration: "none",
                    }}
                    onClick={(e) => {
                      setPromoCodeOn(false);
                    }}
                  >
                    {ReactHtmlParser(t("common.remove_promotion_code"))}
                  </MuiLink>
                </Typography>
              </>
            )}

            <Stack direction={"row"} sx={{ mt: 3, pl: { xs: 0, sm: 1 } }}>
              <Checkbox required />
              <Typography variant="body2" className="regpolicytxt">
                {ReactHtmlParser(t("registration.regpolicy"))}
              </Typography>
            </Stack>

            <Stack direction={"row"} sx={{ mt: 3, pl: { xs: 0, sm: 1 } }}>
              <Checkbox
                onChange={(event) => {
                  setMarketing(event.target.checked);
                }}
              />
              <Typography variant="body2">
                Szeretnék a szolgáltatással kapcsolatos információkról
                hírlevelet kapni
              </Typography>
            </Stack>

            <Button
              type="submit"
              variant="contained"
              className="secondary-blue-btn"
              endIcon={
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.7929 13H8C7.72386 13 7.5 12.7761 7.5 12.5C7.5 12.2239 7.72386 12 8 12H17.7929L14.6464 8.85355C14.4512 8.65829 14.4512 8.34171 14.6464 8.14645C14.8417 7.95118 15.1583 7.95118 15.3536 8.14645L19.3536 12.1464C19.5488 12.3417 19.5488 12.6583 19.3536 12.8536L15.3536 16.8536C15.1583 17.0488 14.8417 17.0488 14.6464 16.8536C14.4512 16.6583 14.4512 16.3417 14.6464 16.1464L17.7929 13Z"
                    fill="#E5FE4A"
                  />
                </svg>
              }
              sx={{
                mt: 4,
                bgcolor: "primary.light",
                lineHeight: "32px",
                width: "100%",
                py: "10px !important",
              }}
            >
              {ReactHtmlParser(t("registration.buttondoreg"))}
            </Button>
          </div>
        </Box>
      </Box>
      <Stack
        direction={"row"}
        sx={{ mt: 4, alignItems: "center", justifyContent: "center" }}
      >
        <Typography variant="body2" sx={{ lineHeight: "20.16px" }}>
          {ReactHtmlParser(t("common.alreadyauser"))}
        </Typography>
        <Button
          className="secondary-blue-text-btn"
          onClick={(e) => {
            navigate("/login");
          }}
          sx={{ lineHeight: "20.16px" }}
        >
          {ReactHtmlParser(t("common.dologin"))}
        </Button>
      </Stack>
    </Box>
  );
};
