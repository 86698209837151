import {
    CardContent,
    Typography,
    Grid,
  } from "@mui/material";
  import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
  import { t } from "i18next";
  import {  useSelector } from "react-redux";
  
  export const CouponDetails = () => {
    const { user } = useSelector((state) => state.user);

  
    return (
    <CardContent>
        <Grid container >
                <Grid xs={12} item>
                  <Typography
                    variant="body2"
                    sx={{
                      fontWeight: 700,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {t("profile.promotion_code.title")}
                    <InfoOutlinedIcon
                      sx={{ fontSize: "inherit", strokeWidth: "2", ml: 1 }}
                    />
                  </Typography>
                </Grid>
                <Grid>
                <Typography 
                    variant="body2"
                    sx={{
                        fontWeight: 700,
                        display: "flex",
                        alignItems: "center",
                        mt: 2
                      }}>
                          {t(`profile.promotion_code.discount_info_${user?.activeCoupon?.discountType}`, {
                            couponCode: user?.couponCode,
                            discountValue: user?.activeCoupon?.discountValue
                        })}
                </Typography> 
                </Grid>
                </Grid>
    </CardContent>
    );
  };
  
  export default CouponDetails;
  