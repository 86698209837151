import { configureStore } from "@reduxjs/toolkit";

import assetReducer from "./Reducers/assetReducer";
import contactReducer from "./Reducers/contactReducer";
import contentReducer from "./Slices/contentSlice";
import countryReducer from "./Reducers/countryReducer";
import postMortemReducer from "./Reducers/postMortemReducer";
import timeCapsuleReducer from "./Reducers/timeCapsuleReducer";
import userReducer from "./Reducers/userReducer";
import paymentReducer from "./Slices/paymentSlice"; 
import planReducer from "./Reducers/planReducer"; 
import faqReducer from "./Reducers/faqReducer"; 
import fileReducer from "./Reducers/fileReducer";
import bannerReducer from './Slices/bannerSlice';
import systemMessagesReducer from './Slices/systemMessageSlice';
import hatsReducer from './Slices/hatsSlice';
import snackbarReducer from './Slices/snackbarSlice';
import exchangeRatesReducer from "./Slices/exchangeRatesSlice";
import hintSlice from "./Slices/hintSlice";

const store = configureStore({
  reducer: {
    asset: assetReducer,
    banner: bannerReducer,
    contact: contactReducer,
    content: contentReducer,
    country: countryReducer,
    exchangeRates: exchangeRatesReducer,
    faq: faqReducer,
    file: fileReducer,
    hats: hatsReducer,
    hints: hintSlice,
    payment: paymentReducer,
    plan: planReducer,
    postMortem: postMortemReducer,
    timeCapsule: timeCapsuleReducer,
    user: userReducer,
    snackbar: snackbarReducer,
    systemMessages: systemMessagesReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['FETCH_ASSETS_SUCCESS'],
        ignoredPaths: ['asset.assetTypes.icon'],
      },
    }),
});
export default store;
