import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import DefaultLayout from "../Layout/DefaultLayout";
import NewAssetSelector from "./NewAssetSelector";
import { t } from "i18next";
import FirstLoginStepper from "./FirstLoginStepper";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import {
  fetchAssets,
  prepareAssetCards,
} from "../../store/Actions/assetAction";
import { TotalValueCard } from "./TotalValueCard";
import { DashboardList } from "./DashboardList";
import {
  fetchContacts,
  prepareContactCards,
} from "../../store/Actions/contactAction";
import { HelpCard } from "../Shared/Cards/HelpCard";
import { ReferralCard } from "../Shared/Cards/ReferralCard";
import { fetchPostMortemEntries } from "../../store/Actions/postMortemActions";
import { fetchPlans } from "../../store/Actions/planAction";
import BannerDisplayer from "../Banner/BannerDisplayer";
import SystemMessages from "../Commons/SystemMessages";
import { InfoCard } from "./InfoCard";
import { EmptyCard } from "../Common/EmptyCard";
import ListAltRoundedIcon from "@mui/icons-material/ListAltRounded";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import { useNavigate } from "react-router-dom";
import ReactHtmlParser from "html-react-parser";
import ExpandIcon from "../Commons/Icons/ExpandIcon";
import { fetchFaqData } from "../../store/Actions/faqActions";
import CloseIcon from "../Commons/Icons/CloseIcon";
import { fetchTimeCapsuleEntries } from "../../store/Actions/timeCapsuleEntriesActions";
import HintDisplay from "../Commons/HintDisplay";

const Dashboard = ({ globalEvent, setGlobalEvent, title }) => {
  const dispatch = useDispatch();
  const {
    items: assets = [],
    sums: assetSums,
    cards: assetCards = [],
    loading: assetLoading,
  } = useSelector((state) => state.asset);

  const {
    items: contacts = [],
    cards: contactCards = [],
    loading: contactLoading,
  } = useSelector((state) => state.contact);
  const displaycurrency =
    useSelector((state) => state.user.displayCurrency) ||
    localStorage.getItem("baseCurrency") ||
    "huf";

  const [chid, setChid] = useState(localStorage.chid);
  const navigate = useNavigate();

  const { categoryVisibility } = useSelector((state) => state.asset);
  const {
    data: faqData = [],
    loading: faqLoading,
    error: faqError,
  } = useSelector((state) => state.faq);
  const filteredAssets = assets?.filter((asset) => asset?.currency !== null);

  useEffect(() => {
    if (assets.length === 0 && !assetLoading) {
      dispatch(fetchAssets());
    }
    if (contacts.length === 0 && !contactLoading) {
      dispatch(fetchContacts());
    }
    dispatch(fetchPostMortemEntries());
    dispatch(fetchTimeCapsuleEntries());
    dispatch(fetchPlans());

    if (faqData.length === 0 && !faqLoading && !faqError) {
      dispatch(fetchFaqData());
    }
  }, [dispatch]);

  useEffect(() => {
    if (assets?.length > 0) {
      dispatch(prepareAssetCards(assets));
    }
  }, [dispatch, assets]);

  useEffect(() => {
    if (contacts?.length > 0) {
      dispatch(prepareContactCards(contacts));
    }
  }, [dispatch, contacts]);

  useEffect(() => {
    if (globalEvent?.type == "responseChangeHat") {
      setChid(globalEvent?.data.currentHatId);
    }
  }, [globalEvent]);

  const hideButtonStyle = {
    color: "rgba(43, 54, 116, .6)",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "17.64px",
    justifyContent: "end",
  };

  const toggleCategoriesVisibility = () => {
    dispatch({
      type: "SET_HOMEPAGE_CATEGORY_VISIBILITY",
      payload: !categoryVisibility,
    });
  };

  return (
    <>
      <DefaultLayout
        globalEvent={globalEvent}
        setGlobalEvent={setGlobalEvent}
        title={title}
      >
        <Box className="my-container">
          <BannerDisplayer position={"top"} page={"dashboard"} />
          <Stack
            spacing={3}
            sx={{
              mt: { xs: 12 },
              mb: 0,
            }}
            container
          >
            <Typography
              variant="h2"
              sx={{ mb: 0, fontSize: 48, fontWeight: 700, color: "#2B3674" }}
            >
              {t("home.welcome")}
            </Typography>

            <SystemMessages type="customer" />

            {(!chid || chid === "null") && (
              <>
                <FirstLoginStepper globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} />
                <NewAssetSelector
                  globalEvent={globalEvent}
                  setGlobalEvent={setGlobalEvent}
                />
              </>
            )}
          </Stack>

          <Grid
            sx={{
              mt: { xs: 0, sm: 8 },
              mb: 20,
              minHeight: "100vh",
              textAlign: "left",
            }}
            container
          >
            <Grid item xs={12} md={7} sx={{ pr: { md: 3 }, mt: 2.1 }}>
              {assets?.length > 0 && (
                <>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    sx={{ mx: 3 }}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{ mb: 2, color: "primary.main", fontWeight: 700 }}
                    >
                      {t("home.assetListTitle") +
                        ` (${filteredAssets?.length})`}
                    </Typography>
                    <Button
                      className="main-blue-text-btn"
                      variant={"text"}
                      onClick={(event) => {
                        navigate("/asset-list");
                      }}
                      endIcon={
                        <svg
                          width="16"
                          height="12"
                          viewBox="0 0 16 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.6116 6.75081H1.25C1.03718 6.75081 0.858983 6.67902 0.7154 6.53543C0.5718 6.39185 0.5 6.21365 0.5 6.00083C0.5 5.78802 0.5718 5.60982 0.7154 5.46623C0.858983 5.32265 1.03718 5.25086 1.25 5.25086H12.6116L9.3192 1.95851C9.17048 1.80979 9.09709 1.63576 9.09902 1.43641C9.10094 1.23706 9.17433 1.05982 9.3192 0.904684C9.47433 0.749567 9.65253 0.669442 9.8538 0.664309C10.0551 0.659176 10.2333 0.734176 10.3884 0.889309L14.8672 5.36816C14.9608 5.46174 15.0269 5.56046 15.0653 5.66431C15.1038 5.76814 15.123 5.88032 15.123 6.00083C15.123 6.12135 15.1038 6.23353 15.0653 6.33736C15.0269 6.44121 14.9608 6.53993 14.8672 6.63351L10.3884 11.1124C10.2397 11.2611 10.0631 11.3345 9.85863 11.3326C9.65414 11.3306 9.47433 11.2521 9.3192 11.097C9.17433 10.9419 9.09933 10.7662 9.0942 10.5701C9.08908 10.3739 9.16408 10.1983 9.3192 10.0432L12.6116 6.75081Z"
                            fill="#62C275"
                          />
                        </svg>
                      }
                      sx={{ fontSize: "14px", lineHeight: "17.64px" }}
                    >
                      {ReactHtmlParser(t("common.showAll"))}
                    </Button>
                  </Stack>

                  <TotalValueCard
                    price={assetSums?.total}
                    currency={displaycurrency}
                    globalEvent={globalEvent}
                    setGlobalEvent={setGlobalEvent}
                  />

                  <DashboardList
                    key="1"
                    hideTitle={true}
                    cards={assetCards}
                    addBtnText={t("home.newAssetButton")}
                    target="/add-new-asset"
                    data={assets}
                    globalEvent={globalEvent}
                    setGlobalEvent={setGlobalEvent}
                  />
                </>
              )}
              {assets?.length === 0 && (
                <>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: 18, fontWeight: 700, mb: 3 }}
                  >
                    {t("dashboard.asset.title")}
                  </Typography>
                  <EmptyCard
                    icon={
                      <ListAltRoundedIcon
                        sx={{ fontSize: 30, fill: "#2B3674" }}
                      />
                    }
                    title={t("dashboard.asset.noAssetTitle")}
                    description={t("dashboard.asset.noAssetDescription")}
                    buttonText={t("dashboard.asset.noAssetButtonText")}
                    buttonLink="/add-new-asset"
                    hasShadow={false}
                  />
                </>
              )}
              {(!chid || chid === "null") && contacts?.length > 0 && (
                <DashboardList
                  key="2"
                  title={t("home.contactListTitle") + `(${contacts?.length})`}
                  linkTo={"/contact-list"}
                  cards={contactCards}
                  addBtnText={t("home.newContactBtn")}
                  target="/add-new-contact"
                  targetData={{ editmode: true, data: {} }}
                  data={contacts}
                  globalEvent={globalEvent}
                  setGlobalEvent={setGlobalEvent}
                />
              )}

              {contacts?.length === 0 && (
                <>
                  <Typography
                    variant="body1"
                    sx={{ fontSize: 18, fontWeight: 700, my: 3 }}
                  >
                    {t("dashboard.contact.title")}
                  </Typography>
                  <EmptyCard
                    icon={
                      <PersonAddAltOutlinedIcon
                        sx={{ fontSize: 30, fill: "#2B3674" }}
                      />
                    }
                    title={t("dashboard.contact.noContactTitle")}
                    description={t("dashboard.contact.noContactDescription")}
                    buttonText={t("dashboard.contact.noContactButtonText")}
                    buttonLink={(e) => {
                      navigate("/add-new-contact", {
                        state: { editmode: true, data: {} },
                      });
                    }}
                    hasShadow={false}
                  />
                </>
              )}

              <Stack sx={{ mb: 3 }}></Stack>
              <HintDisplay />
              <InfoCard />

              <BannerDisplayer position={"bottom"} page={"dashboard"} />
            </Grid>

            <Grid
              item
              xs={12}
              md={5}
              sx={{ mt: 0, px: { md: 3 }, pt: { xs: 3, md: 1.2 } }}
            >
              <Stack direction={"column"} spacing={2}>
                {categoryVisibility !== true && (
                  <Button
                    sx={hideButtonStyle}
                    onClick={() => toggleCategoriesVisibility()}
                  >
                    {t("home.showAssetCategories")}

                    <div style={{ marginLeft: "10px" }}>
                      <ExpandIcon />
                    </div>
                  </Button>
                )}

                {categoryVisibility !== false && (
                  <Button
                    sx={hideButtonStyle}
                    onClick={() => toggleCategoriesVisibility()}
                  >
                    {t("home.hideAssetCategories")}

                    <div style={{ marginLeft: "10px" }}>
                      <CloseIcon />
                    </div>
                  </Button>
                )}

                <HelpCard />
                <ReferralCard />

                <BannerDisplayer position="right" page={"dashboard"} />
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </DefaultLayout>
    </>
  );
};

export default Dashboard;
