import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en/translation.json';
import hu from './locales/hu/translation.json';

const getUserLocale = () => {
  const locale = localStorage.getItem('locale');
  return locale || 'hu'; 
};

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: en },
      hu: { translation: hu }
    },
    lng: getUserLocale(), // Kezdő nyelv a localStorage alapján
    fallbackLng: 'hu', // Ha nincs localStorage adat, akkor alapértelmezett a magyar
    interpolation: {
      escapeValue: false // React már kezeli az escape-elést
    }
  });

export default i18n;
