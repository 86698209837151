const initialState = {
  items: [],
  images: [],
  question: [],
  loading: false,
  error: null,
  newEntry: {
    id: null,
    title: "",
    content: "",
    location: "",
  },
};

const timeCapsuleEntriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_TIMECAPSULE_ENTRIES_REQUEST":
    case "FETCH_TIMECAPSULE_IMAGES_REQUEST":
    case "FETCH_TIMECAPSULE_QUESTION_REQUEST":
      return { ...state, loading: true, error: null };
    case "FETCH_TIMECAPSULE_ENTRIES_SUCCESS":
      return { ...state, loading: false, items: action.payload };
    case "FETCH_TIMECAPSULE_IMAGES_SUCCESS":
      //console.log('FETCH_TIMECAPSULE_IMAGES_SUCCESS', action.payload);
      return { ...state, loading: false, images: action.payload };
    case "FETCH_TIMECAPSULE_QUESTION_SUCCESS":
      return { ...state, loading: false, question: action.payload };
    case "FETCH_TIMECAPSULE_ENTRIES_FAILURE":
    case "FETCH_TIMECAPSULE_IMAGES_FAILURE":
    case "FETCH_TIMECAPSULE_QUESTION_FAILURE":
      return { ...state, loading: false, error: action.payload };
    case "SET_TIMECAPSULE_ENTRY_TITLE":
      return {
        ...state,
        newEntry: {
          ...state.newEntry,
          title: action.payload,
        },
      };
    case "SET_TIMECAPSULE_ENTRY_CONTENT":
      return {
        ...state,
        newEntry: {
          ...state.newEntry,
          content: action.payload,
        },
      };
    case "SET_TIMECAPSULE_ENTRY_LOCATION":
      return {
        ...state,
        newEntry: {
          ...state.newEntry,
          location: action.payload,
        },
      };
    case "RESET_TIMECAPSULE_ENTRY":
      return {
        ...state,
        newEntry: initialState.newEntry,
      };

    case "SAVE_TIMECAPSULE_ENTRY":
      let newEntryObj = { ...state.newEntry };

      if (!newEntryObj.id) {
        newEntryObj.id = Math.round(Math.random() * 10000);
        newEntryObj.date = formatDate(new Date());
        newEntryObj.topic = "digitális emlék";

        return {
          ...state,
          items: [newEntryObj, ...state.items],
          newEntry: { id: null, title: "", content: "", location: "" },
        };
      } else {
        const updatedItems = state.items.map((item) =>
          item.id === newEntryObj.id ? { ...item, ...newEntryObj } : item
        );

        return {
          ...state,
          items: updatedItems,
          newEntry: initialState.newEntry,
        };
      }
    case "EDIT_TIMECAPSULE_ENTRY":
      const entry = state.items.find((row) => row.id === action.payload);

      if (entry) {
        return {
          ...state,
          newEntry: entry,
        };
      }

      return state;

    case "DELETE_TIMECAPSULE_ENTRY":
      const index = state.items.findIndex((row) => row.id === action.payload);
      let stateItems = [...state.items];

      if (index !== -1) {
        stateItems.splice(index, 1);
        return { ...state, loading: false, items: stateItems };
      }

      return state;
    default:
      return state;
  }
};

const formatDate = (date) => {
  const monthNames = [
    "január",
    "február",
    "március",
    "április",
    "május",
    "június",
    "július",
    "augusztus",
    "szeptember",
    "október",
    "november",
    "december",
  ];

  const dayNames = [
    "vasárnap",
    "hétfő",
    "kedd",
    "szerda",
    "csütörtök",
    "péntek",
    "szombat",
  ];

  const year = date.getFullYear();
  const month = monthNames[date.getMonth()];
  const day = date.getDate();
  const dayOfWeek = dayNames[date.getDay()];

  return `${year}. ${month} ${day}. - ${dayOfWeek}`;
};

export default timeCapsuleEntriesReducer;
