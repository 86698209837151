import { Alert, Box, Grid, Snackbar } from "@mui/material";
import { Footer } from "../Footer/Footer";
import { LoginTopMenu } from "../Navigation/LoginNavigation/LoginTopMenu";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideSnackbar } from "../../store/Slices/snackbarSlice";

const AuthLayout = ({
  children,
  backgroundImage,
  globalEvent,
  setGlobalEvent,
  title,
}) => {
  const [mobileView, setMobileView] = useState(
    window.matchMedia("(max-width: 900px)").matches
  );
  const dispatch = useDispatch();
  const snackbar = useSelector((state) => state.snackbar);

  useEffect(() => {
    const handleResize = () => {
      const mql = window.matchMedia("(max-width: 900px)");
      setMobileView(mql.matches);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  const handleSnackbarClose = () => {
    dispatch(hideSnackbar());
  };

  return (
    <>
      <Box
        sx={{
          px: { xs: 2, md: 0 },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <LoginTopMenu />
              {children}
            </Grid>
            {!mobileView ? (
              <Grid item xs={12} md={6}>
                <Box
                  component="img"
                  src={backgroundImage}
                  sx={{
                    top: 0,
                    width: "100%",
                    height: "100%",
                  }}
                ></Box>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Box>
      </Box>
      
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      {mobileView ? <Footer /> : ""}
    </>
  );
};

export default AuthLayout;
