import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  TextField,
  MenuItem,
  Tooltip,
  Stack,
  Button,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchContacts } from "../../store/Actions/contactAction";
import { getCurrentUser } from "../../store/Actions/userAction";
import EditIcon from "@mui/icons-material/EditOutlined";
import ButtonwithArrow from "../Commons/ButtonWithArrow";

export const LifeSignalCard = ({ globalEvent, setGlobalEvent }) => {
  const { user } = useSelector((state) => state.user);
  const { items: contacts = null } = useSelector((state) => state.contact);
  const { t } = useTranslation();
  const [lifeSignal, setLifeSignal] = useState("semiannually");
  const [isLifeSignalEnabled, setIsLifeSignalEnabled] = useState(false);
  const dispatch = useDispatch();
  const [editBtn, setEditBtn] = useState(false);
  const [contactsLoaded, setContactsLoaded] = useState(false);

  useEffect(() => {
    if (user?.lifeSignal && isLifeSignalEnabled) {
      setLifeSignal(user.lifeSignal);
    }
  }, [user]);

  useEffect(() => {
    if ((contacts === null || contacts.length === 0) && !contactsLoaded) {
      dispatch(fetchContacts());
      setContactsLoaded(true);
    } else {
      const hasOnDeathBeneficiary = contacts?.some(
        (contact) =>
          contact.accessTypeAssets === "onDeath" ||
          contact.accessTypeDiaryItems === "onDeath" ||
          contact.accessTypePostMortemRequests === "onDeath"
      );
      setIsLifeSignalEnabled(hasOnDeathBeneficiary);
    }

    if (!user?.id) {
      dispatch(getCurrentUser());
    }
  }, [contacts, user, dispatch]);

  const handleLifeSignalChange = (ev) => {
    const newLifeSignal = ev.target.value;
    setLifeSignal(newLifeSignal);
  };

  const saveLifeSignal = () => {
    setEditBtn(!editBtn);
    setGlobalEvent({
      type: "doSaveLifeSignal",
      data: {
        user: { lifeSignal: lifeSignal },
      },
    });
  }

  return (
    isLifeSignalEnabled && (
      <Card
        sx={{
          borderRadius: "16px",
          px: 2,
          pt: { xs: 1, sm: 2 },
          mt: 2,
          boxShadow: "4px 4px 20px 0px #0000001A",
        }}
      >
        <CardContent>
          <Grid container>
            {!editBtn ? (
              <>
                <Grid item sm={8}>
                  <Box>
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      spacing={1}
                      sx={{ mb: 1 }}
                    >
                      <Typography variant="body2" sx={{ fontWeight: 700 }}>
                        {t("profile.lifesignal.title")}{" "}
                      </Typography>
                      <Tooltip
                        slotProps={{
                          tooltip: {
                            sx: {
                              backgroundColor: "#2B3674",
                              borderRadius: "8px",
                            },
                          },
                        }}
                        title={
                          <Typography
                            sx={{ color: "#FFFFFF", fontSize: "12px" }}
                          >
                            {t("profile.lifesignal.tooltip")}
                          </Typography>
                        }
                      >
                        <InfoOutlinedIcon
                          color="primary"
                          sx={{ width: "16px", height: "16px" }}
                        />
                      </Tooltip>
                    </Stack>

                    <Typography variant="body2">
                      {t("profile.lifesignal.description")}
                    </Typography>
                    {isLifeSignalEnabled && (
                      <Typography variant="body2" sx={{ fontWeight: 700 }}>
                        {t("profile.lifesignal." + lifeSignal)}
                      </Typography>
                    )}
                  </Box>
                </Grid>
                <Grid
                  item
                  sm={4}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    mt: { xs: 2, md: 0 },
                  }}
                >
                  <Button
                    onClick={() => {
                      setEditBtn(!editBtn);
                    }}
                    variant="text"
                    sx={{
                      minWidth: "50px",
                      width: "50px !important",
                      height: "50px",
                      p: 0,
                      my: 0.3,
                      boxShadow: "0px 0px 10px 0.2px #0000001A",
                      borderRadius: "32px",
                    }}
                  >
                    <EditIcon />
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                <Grid item sm={12}>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    spacing={1}
                    sx={{ mb: 1 }}
                  >
                    <Typography variant="body2" sx={{ fontWeight: 700 }}>
                      {t("profile.lifesignal.title")}{" "}
                    </Typography>
                    <Tooltip
                      slotProps={{
                        tooltip: {
                          sx: {
                            backgroundColor: "#2B3674",
                            borderRadius: "8px",
                          },
                        },
                      }}
                      title={
                        <Typography sx={{ color: "#FFFFFF", fontSize: "12px" }}>
                          {t("profile.lifesignal.tooltip")}
                        </Typography>
                      }
                    >
                      <InfoOutlinedIcon
                        color="primary"
                        sx={{ width: "16px", height: "16px" }}
                      />
                    </Tooltip>
                  </Stack>

                  <Typography variant="body2" sx={{ mb: 2 }}>
                    {t("profile.lifesignal.edit.description1")}
                  </Typography>

                  <Typography variant="body2" sx={{ mb: 2 }}>
                    {t("profile.lifesignal.edit.description2")}
                  </Typography>

                  <Typography
                    variant="body2"
                    sx={{ fontWeight: "bold", mb: 1 }}
                  >
                    {t("profile.lifesignal.edit.description3")}
                  </Typography>

                  <Grid container spacing={2}>
                    <Grid item sm={8}>
                      <TextField
                        select
                        className="asset-field-currency"
                        label={t("field.lifeSignal")}
                        value={lifeSignal}
                        onChange={handleLifeSignalChange} 
                        sx={{ width: "100% !important", mt: 1 }}
                        disabled={!isLifeSignalEnabled}
                      >
                        <MenuItem value={"monthly"}>
                          {t("profile.lifesignal.monthly")}
                        </MenuItem>
                        <MenuItem value={"quarterly"}>
                          {t("profile.lifesignal.quarterly")}
                        </MenuItem>
                        <MenuItem value={"semiannually"}>
                          {t("profile.lifesignal.semiannually")}
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item sm={4}>
                      <ButtonwithArrow
                        text={t("profile.lifesignal.edit.save")}
                        py={3}
                        my={1}
                        onClick={saveLifeSignal}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </CardContent>
      </Card>
    )
  );
};

export default LifeSignalCard;
