import { SideBarCard } from "./SideBarCard";
import { useTranslation } from 'react-i18next';
import ChatPasteGoIcon from '../../Commons/Icons/ChatPasteGoIcon';
import { useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { checkAccess, fetchHats } from "../../../store/Slices/hatsSlice";

const MakeOwnAssetCard = ({globalEvent, setGlobalEvent}) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [chid, setChid] = useState(localStorage.chid);
    const dispatch = useDispatch();

    const callbackFn = () => {
      handleHatChange(chid, null);
    }

    const handleHatChange = useCallback(
      (currentHatId, newHatId) => {
        if (currentHatId !== newHatId) {
          setGlobalEvent({ type: "doChangeHat", data: { hatId: newHatId } });
          dispatch(checkAccess(newHatId));
          localStorage.setItem('forceRedirect', '/add-new-asset');
        }
      },
      [setGlobalEvent]
    );

    return (
      <SideBarCard
          IconComponent={ChatPasteGoIcon}
          title={t("cards.makeOwnAssetList.title")}
          description={t("cards.makeOwnAssetList.description")}
          buttonText={t("cards.makeOwnAssetList.linkText")}
          buttonCallback={callbackFn}
          buttonColor={'#62C275'}
        />
    );
};

export default MakeOwnAssetCard;