import {
  Box,
  Grid,
} from "@mui/material";

import { ValidationStep1 } from '../ValidationComponents/ValidationStep1';
import passwordbg from '../../Assets/nathan-mcbride-DEmPhCx7y8Q-unsplash 1.png'

export const ValidationHolder = ({
  globalEvent,
  setGlobalEvent,
  validationType,
}) => {
  const mql = window.matchMedia("(max-width: 600px)");
  let mobileView = mql.matches;
  
  return (
	<Box sx={{ display: "flex", justifyContent: "center" }}>
      <Grid container sx={{}}>
        <Grid item xs={12} md={6} sx={{ width: "50%" }}>
          <div className="login-container">
            <Box
              sx={{
                mt: { xs: 0, sm: 16 },
              }}
            >
              <ValidationStep1 globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} validationType={validationType}/>
            </Box>
          </div>
        </Grid>
        <Grid item xs={12} md={6} sx={{ width: "50%" }}>
          {!mobileView ? 
            <Box
              component="img"
              alt="background"
              src={passwordbg}
              sx={{
                top: 0,
                width: "100%",
                height: "100%",
              }}
		  ></Box> : <></> }
		</Grid>
      </Grid>
    </Box>
);
}