import ReactHtmlParser from "html-react-parser";
import { t } from "i18next";
import DefaultLayout from "../Layout/DefaultLayout";
import {
  Box,
  Typography,
  Stack,
  Button,
  Card,
  CardContent,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const ImpressumPage = ({ globalEvent, setGlobalEvent, title }) => {
  const navigate = useNavigate();
  return (
    <>
      <DefaultLayout globalEvent={globalEvent} setGlobalEvent={setGlobalEvent}>
        <div
          className="my-container"
          style={{ minHeight: "100vh", paddingBottom: 10 }}
        >
          <Stack
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            sx={{ mt: 8, mb: 6 }}
          >
            <Button
              onClick={() => {
                navigate(-1);
              }}
              variant="text"
              sx={{
                height: "50px",
                width: "50px !important",
                minWidth: "50px",
                p: 0,
                boxShadow: "0px 0px 10px 0px #0000001A",
                borderRadius: "32px",
              }}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M9.19697 14.5546L8.4042 15.3477C8.06852 15.6834 7.52573 15.6834 7.19362 15.3477L0.251538 8.40706C-0.0841421 8.07128 -0.0841421 7.52833 0.251538 7.19612L7.19362 0.251947C7.5293 -0.0838342 8.07209 -0.0838342 8.4042 0.251947L9.19697 1.04496C9.53622 1.38431 9.52908 1.93798 9.18268 2.27018L4.87959 6.37096H15.1427C15.6177 6.37096 15.9998 6.75317 15.9998 7.22826V8.37134C15.9998 8.84643 15.6177 9.22865 15.1427 9.22865H4.87959L9.18268 13.3294C9.53264 13.6616 9.53978 14.2153 9.19697 14.5546Z"
                  fill="#2B3674"
                />
              </svg>
            </Button>
            <Typography variant="body1" sx={{ fontWeight: 700, pl: 6 }}>
              {ReactHtmlParser(t("impressum.mainTitle"))}
            </Typography>
          </Stack>
          <Card
            sx={{
              borderRadius: "16px",
              boxShadow: "4px 4px 20px  0px rgba(0, 0, 0, 0.1)",
              px: { xs: 4, md: 10 },
              py: { xs: 4, sm: 4 },
              display: { xs: "flex", sm: "block" },
            }}
          >
            <CardContent
              sx={{
                textAlign: "start",
                py: 0,
                px: 0,
                pb: "0 !important",
                width: "100%",
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 700, lineHeight: "24px" }}
              >
                {t("impressum.firstCardTitle")}
              </Typography>
              <Typography variant="body2" sx={{ lineHeight: "24px" }}>
                {t("impressum.firstCardAddress")}
              </Typography>
            </CardContent>
          </Card>
          <Card
            sx={{
              borderRadius: "16px",
              boxShadow: "4px 4px 20px  0px rgba(0, 0, 0, 0.1)",
              mt: 4,
              px: { xs: 4, md: 10 },
              py: { xs: 4, sm: 4 },
              display: { xs: "flex", sm: "block" },
            }}
          >
            <CardContent
              sx={{
                textAlign: "start",
                py: 0,
                px: 0,
                pb: "0 !important",
                width: "100%",
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 700, lineHeight: "24px" }}
              >
                {t("impressum.secondCardTitle")}
              </Typography>
              <Typography variant="body2" sx={{ lineHeight: "24px" }}>
                {t("impressum.secondCardTel")}
              </Typography>
              <Stack direction={"row"}>
                <Typography variant="body2" sx={{ lineHeight: "24px" }}>
                  {t("impressum.secondCardEmailLabel")}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ lineHeight: "24px", cursor: "pointer", ml: 1 }}
                  key="Email"
                  component="a"
                  href={`mailto:${t("impressum.secondCardEmail")}`}
                >
                  {t("impressum.secondCardEmail")}
                </Typography>
              </Stack>
            </CardContent>
          </Card>
          <Card
            sx={{
              borderRadius: "16px",
              boxShadow: "4px 4px 20px  0px rgba(0, 0, 0, 0.1)",
              mt: 4,
              px: { xs: 4, md: 10 },
              py: { xs: 4, sm: 4 },
              display: { xs: "flex", sm: "block" },
            }}
          >
            <CardContent
              sx={{
                textAlign: "start",
                py: 0,
                px: 0,
                pb: "0 !important",
                width: "100%",
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 700, lineHeight: "24px" }}
              >
                {t("impressum.thirdCardTitle")}
              </Typography>
              <Typography variant="body2" sx={{ lineHeight: "24px" }}>
                {t("impressum.thirdCardRegNumber")}
              </Typography>
              <Typography variant="body2" sx={{ lineHeight: "24px" }}>
                {t("impressum.thirdCardTaxNumber")}
              </Typography>
              <Typography variant="body2" sx={{ lineHeight: "24px" }}>
                {t("impressum.thirdCardEUTaxNumber")}
              </Typography>
              <Typography variant="body2" sx={{ lineHeight: "24px" }}>
                {t("impressum.thirdCardStatisticNum")}
              </Typography>
            </CardContent>
          </Card>
          <Card
            sx={{
              borderRadius: "16px",
              boxShadow: "4px 4px 20px  0px rgba(0, 0, 0, 0.1)",
              mt: 4,
              px: { xs: 4, md: 10 },
              py: { xs: 4, sm: 4 },
              display: { xs: "flex", sm: "block" },
            }}
          >
            <CardContent
              sx={{
                textAlign: "start",
                py: 0,
                px: 0,
                pb: "0 !important",
                width: "100%",
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: 700, lineHeight: "24px" }}
              >
                {t("impressum.fourthCardTitle")}
              </Typography>
              <Typography variant="body2" sx={{ lineHeight: "24px" }}>
                {t("impressum.fourthCardUrl")}
              </Typography>
              <Typography variant="body2" sx={{ lineHeight: "24px" }}>
                {t("impressum.fourthCardAddress")}
              </Typography>
            </CardContent>
          </Card>
        </div>
      </DefaultLayout>
    </>
  );
};

export default ImpressumPage;
