import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  TextField,
  MenuItem,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import currencyJSON from "../Assets/Data/Common-Currency.json";
import currencyMinJSON from "../Assets/Data/Common-Currency-min.json";
import EditIcon from "@mui/icons-material/EditOutlined";

import { useForm } from "react-hook-form";

export const CurrencyCard = ({ globalEvent, setGlobalEvent }) => {
  const { user } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const [baseCurrency, setBaseCurrency] = useState(() => {
    const bCurrency = localStorage.getItem("baseCurrency");
    return bCurrency ? bCurrency : "huf";
  });

  const [accountCurrencyMore, setAccountCurrencyMore] = useState(false);
  const [openAccountCurrency, setOpenAccountCurrency] = useState(false);
  const [editBtn, setEditBtn] = useState(true);

  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid, errors },
    reset,
    setValue,
  } = useForm({
    mode: "onBlur",
  });

  useEffect(() => {
    localStorage.setItem("baseCurrency", baseCurrency);
  }, [baseCurrency]);

  useEffect(() => {
    if (user?.displayCurrency) {
      setBaseCurrency(user.displayCurrency.toUpperCase());
    }
  }, [user]);

  const handleCurrencyChange = (ev) => {
    const newCurrency = ev.target.value;
    setGlobalEvent({
      type: "doSaveCurrency",
      data: {
        user: { displayCurrency: newCurrency.toLowerCase() },
      },
    });
    setBaseCurrency(newCurrency);
    setEditBtn(!editBtn);
  };

  return (
    <Card
      sx={{
        borderRadius: "16px",
        px: 2,
        pt: { xs: 1, sm: 2 },
        mt: 2,
        boxShadow: "4px 4px 20px 0px #0000001A",
      }}
    >
      <CardContent>
        <Grid container>
          <Grid item xs={6} sm={8}>
            <Box>
              <Typography variant="body2" sx={{ fontWeight: 700 }}>
                {t("profile.currency.title")}
              </Typography>
              <Typography variant="body2">
                {t("profile.currency.description")}
              </Typography>
              <Typography
                variant="body2"
                sx={{ textTransform: "uppercase", fontWeight: 700 }}
              >
                {baseCurrency}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {editBtn ? (
              <Button
                onClick={() => {
                  setEditBtn(!editBtn);
                }}
                variant="text"
                sx={{
                  minWidth: "50px",
                  width: "50px !important",
                  height: "50px",
                  p: 0,
                  my: 0.3,
                  boxShadow: "0px 0px 10px 0.2px #0000001A",
                  borderRadius: "32px",
                }}
              >
                <EditIcon />
              </Button>
            ) : (
              <TextField
                key="baseCurrency"
                select
                className="asset-field-currency"
                label={t("field.baseCurrency")}
                value={baseCurrency}
                defaultValue={baseCurrency}
                required={false}
                onChange={(ev) => {
                  ev.preventDefault();
                  if (ev.target.value === "MORE") {
                    setAccountCurrencyMore(true);

                    setTimeout(() => {
                      setOpenAccountCurrency(true);
                    }, 1000);
                  } else {
                    handleCurrencyChange(ev);
                  }
                }}
                inputProps={{
                  open: openAccountCurrency,
                  onClose: (ev) => {
                    //console.log(ev.target);
                    ev.stopPropagation();
                    ev.preventDefault();
                    setOpenAccountCurrency(false);
                    return false;
                  },
                  onOpen: (ev) => {
                    //console.log(ev.target);
                    ev.stopPropagation();
                    ev.preventDefault();
                    setOpenAccountCurrency(true);
                    return false;
                  },
                }}
                {...register("currency", {
                  required: t("errors.field.required"),
                  onChange: (ev) => {
                    if (ev.target.value === "MORE") {
                      ev.defaultMuiPrevented = true;
                      ev.stopPropagation();
                      ev.preventDefault();
                      setAccountCurrencyMore(true);
                      setTimeout(() => {
                        setOpenAccountCurrency(true);
                      }, 250);
                      return false;
                    } else {
                      handleCurrencyChange(ev);
                      setValue("currency", ev.target.value);
                    }
                  },
                })}
              >
                {!accountCurrencyMore && (
                  <MenuItem
                    value={baseCurrency}
                    id={0}
                    key={0}
                    onClick={(ev) => {
                      ev.defaultMuiPrevented = true;
                      ev.stopPropagation();
                      ev.preventDefault();
                      return false;
                    }}
                  >
                    {baseCurrency}
                  </MenuItem>
                )}
                {Object.keys(currencyMinJSON).map((key, index) => {
                  return (
                    !accountCurrencyMore &&
                    currencyJSON[key].code !== baseCurrency && (
                      <MenuItem
                        value={currencyJSON[key].code}
                        id={index}
                        key={index}
                      >
                        {currencyJSON[key].code}
                      </MenuItem>
                    )
                  );
                })}
                {!accountCurrencyMore && (
                  <MenuItem
                    value="MORE"
                    id={0}
                    key={0}
                    onClick={(ev) => {
                      ev.defaultMuiPrevented = true;
                      ev.stopPropagation();
                      ev.preventDefault();
                      return false;
                    }}
                  >
                    {t("common.moreCurrency")}
                  </MenuItem>
                )}

                {Object.keys(currencyJSON).map((key, index) => {
                  return (
                    accountCurrencyMore && (
                      <MenuItem
                        value={currencyJSON[key].code}
                        id={index}
                        key={currencyJSON[key].code}
                      >
                        {currencyJSON[key].code}
                      </MenuItem>
                    )
                  );
                })}
              </TextField>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CurrencyCard;
