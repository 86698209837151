import { Box, Paper, Stack, Button, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/DeleteForeverOutlined";
import DragBox from "../DragBox/DragBox";
import DownloadFileWithAuth from "../DataModule/DownloadFileWithAuth";
import "./lightGallery.css";
import { MinimizeSharp } from "@mui/icons-material";

function ShowFileOfAsset({
  globalEvent,
  setGlobalEvent,
  itemList,
  editMode = false,
}) {
  return (
    <Box
      style={{
        textAlign: "center",
        cursor: "pointer",
        width: "fill 399px",
        height: "fill 399px",
        background: "#FAFCFC",
      }}
      sx={{
        position: "relative",
        padding: 0,
        display: "block",
      }}
    >
      <DragBox>
        <Box className="asset-gallery" spacing={1}>
          {itemList &&
            itemList.map((item) => (
              <Box className="gallery-item">
                <Box
                  sx={{
                    minWidth: 100,
                    maxWidth: 200,
                    aspectRatio: 1 / 1,
                    background:
                      "radial-gradient(50% 50% at 50% 50%, rgba(217, 217, 217, 0) 0%, #DADADA 100%)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "16px",
                    position: "relative"
                  }}
                  fileUrl={"/attachments/" + item.id}
                  fileName={item.originalName}
                >
                  {editMode && (
                    <Button
                      variant="text"
                      sx={{
                        position: "absolute",
                        minWidth: "50px",
                        width: "50px !important",
                        height: "50px",
                        p: 0,
                        boxShadow: "0px 0px 10px 0px #0000001A",
                        borderRadius: "32px",
                        right: "5px",
                        top: "5px",
                        backgroundColor: "white",
                      }}
                      onClick={(event) => {
                        console.log('delete')
						event.stopPropagation();
                        setGlobalEvent({
                          type: "doDeleteAttachment",
                          data: { id: item.id ? item.id : 0 },
                        });
                      }}
                    >
                      <DeleteIcon sx={{ color: "info.secondary" }} />
                    </Button>
                  )}

                  <DownloadFileWithAuth
                    sx={{
                      minWidth: 137,
                      maxWidth: 200,
                      aspectRatio: 1 / 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    fileUrl={"/attachments/" + item.id}
                    fileName={item.originalName}
                  >
                    <svg
                      width="68"
                      height="68"
                      viewBox="0 0 68 68"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M12.5282 21.474C13.5165 21.474 14.3177 20.6728 14.3177 19.6845V7.15799C14.3177 5.18137 15.92 3.579 17.8966 3.579C19.8732 3.579 21.4755 5.18137 21.4755 7.15799V19.6845C21.4755 24.626 17.4697 28.632 12.5282 28.632C7.58673 28.632 3.58088 24.626 3.58088 19.6845V1.7895C3.58088 0.801185 2.77971 0 1.79142 0C0.803123 0 0.00195312 0.801185 0.00195312 1.7895V19.6845C0.00195312 26.6027 5.61014 32.211 12.5282 32.211C19.4462 32.211 25.0544 26.6027 25.0544 19.6845V7.15799C25.0544 3.20474 21.8498 0 17.8966 0C13.9434 0 10.7387 3.20474 10.7387 7.15799V19.6845C10.7387 20.6728 11.5399 21.474 12.5282 21.474ZM34.0013 7.15517C33.013 7.15517 32.2118 6.35398 32.2118 5.36567C32.2118 4.37736 33.013 3.57617 34.0013 3.57617H59.0538C63.9952 3.57617 68.0011 7.5821 68.0011 12.5237V59.0506C68.0011 63.9922 63.9952 67.9981 59.0538 67.9981H19.6856C14.7441 67.9981 10.7383 63.9922 10.7383 59.0506V41.1556C10.7383 40.1673 11.5395 39.3661 12.5277 39.3661C13.516 39.3661 14.3172 40.1673 14.3172 41.1556V59.0506C14.3172 62.0155 16.7207 64.4191 19.6856 64.4191H59.0538C62.0187 64.4191 64.4222 62.0155 64.4222 59.0506V12.5237C64.4222 9.55872 62.0187 7.15517 59.0538 7.15517H34.0013ZM34.0004 25.0536C33.0121 25.0536 32.2109 24.2524 32.2109 23.2641C32.2109 22.2758 33.0121 21.4746 34.0004 21.4746H51.895C52.8833 21.4746 53.6845 22.2758 53.6845 23.2641C53.6845 24.2524 52.8833 25.0536 51.895 25.0536H34.0004ZM26.8442 35.7899C25.8559 35.7899 25.0547 34.9888 25.0547 34.0004C25.0547 33.0121 25.8559 32.2109 26.8442 32.2109H51.8966C52.8849 32.2109 53.6861 33.0121 53.6861 34.0004C53.6861 34.9888 52.8849 35.7899 51.8966 35.7899H26.8442ZM25.0547 44.7368C25.0547 45.7251 25.8559 46.5263 26.8442 46.5263H44.7388C45.7271 46.5263 46.5282 45.7251 46.5282 44.7368C46.5282 43.7485 45.7271 42.9473 44.7388 42.9473H26.8442C25.8559 42.9473 25.0547 43.7485 25.0547 44.7368Z"
                        fill="#C6C6C6"
                      />
                    </svg>
                  </DownloadFileWithAuth>
                </Box>
                <DownloadFileWithAuth
                  sx={{
                    height: 30,
                    maxWidth: 137,
                    minWidth: 100,
                  }}
                  fileUrl={"/attachments/" + item.id}
                  fileName={item.originalName}
                >
                  <Typography variant="caption" sx={{ color: "primary.main" }}>
                    {item.originalName}
                  </Typography>
                </DownloadFileWithAuth>
              </Box>
            ))}
        </Box>
      </DragBox>
    </Box>
  );
}

export default ShowFileOfAsset;
