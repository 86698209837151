import React, { useState, useEffect } from "react";
import { Box, Button, Stack, Grid } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import B120Logo from "../../Commons/Icons/B120Logo";
import { LanguageSelector } from "../LanguageSelector";
import { scrollToSection } from "../../../store/helper";
import ScrollToTop from "../../AuthNavigator/ScrollToTop";

export const DesktopTopMenu = (outer) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    
	const element = document.querySelector(location.hash ? location.hash : 'body');
    if (element) {
      const headerOffset = 100;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, [location]);

  const scrollTop = () => {
    navigate('/');
  }

  const menuItems = [
    { id: 'howitworks', label: t("topmenu.howitworks") },
    { id: 'whoisitfor', label: t("topmenu.whoisitfor") },
    { id: 'prices', label: t("topmenu.prices") },
    { id: 'faq', label: t("topmenu.faq") },
  ];

  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 5,
        bgcolor: "#FFFFFF",
        display: "flex",
        justifyContent: "center",
        boxShadow: "0 0 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Grid
        container
        direction={"row"}
        sx={{
          width: "100%",
          mr: 2,
          alignItems: "center",
        }}
      >
        <Grid
          item
          xs={7}
          sx={{ pr: 2, display: "flex", justifyContent: "center", pl: "20px" }}
        >
          <Box
            sx={{
              my: "13px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Stack
              spacing={{ xs: 1, md: 1, lg: 3 }}
              direction={"row"}
              sx={{ alignItems: "center" }}
            >
              
              <Button onClick={() => scrollTop()}>
                <B120Logo />
              </Button>
              {menuItems.map((item) => (
                <Button
                  key={item.id}
                  variant="text"
                  onClick={() => (outer ? navigate('/#'+item.id) : scrollToSection(item.id))}
                  className="main-blue-text-btn"
                >
                  {item.label}
                </Button>
              ))}
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box
            sx={{
              my: "13px",
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Stack spacing={{ xs: 1, md: 1, lg: 3 }} direction={"row"}>
              <Button
                onClick={() => navigate("/login")}
                variant="text"
                className="main-blue-text-btn"
              >
                {t("topmenu.login")}
              </Button>
              <Button
                onClick={() => navigate("/registration")}
                className="secondary-blue-btn"
                variant="contained"
                sx={{
                  py: 2,
                  px: 4,
                  boxShadow: "none",
                  "&:hover": { boxShadow: "none" },
                }}
              >
                {t("topmenu.registration")}
              </Button>
              <LanguageSelector hasShadow={false} />
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};