import { Alert, Button } from "@mui/material";
import {
  useStripe,
  useElements,
  PaymentElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { t } from "i18next";
import { useState } from "react";

const StripePaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage('');

    if (!stripe || !elements) {
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: process.env.REACT_APP_STRIPE_RETURN_URL
      },
    });

    if (result.error) {
      setErrorMessage(result.error.message);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardNumberElement />
      <PaymentElement />

      {errorMessage && (
        <Alert variant="filled" severity="error">
          {errorMessage}
        </Alert>
      )}

      <Button
        type="submit"
        disabled={!stripe}
        fullWidth
        variant="contained"
        sx={{ mt: 3, mb: 2 }}
      >
        {t("validation.buttondonext")}
      </Button>
    </form>
  );
};

export default StripePaymentForm;
