import { useState, useEffect } from "react";
import b120Text from "../../Assets/b120-text.png";
import lifetresor from "../../Assets/the-LIFETRESOR.png";
import { LanguageSelector } from "../LanguageSelector";
import { Box, Button, Stack, Link, Drawer } from "@mui/material";
import { t } from "i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { scrollToSection } from "../../../store/helper";

export const MobileTopMenu = (outer) => {
  const [language, setLanguage] = useState("hu");
  const [toggleMenu, setToggleMenu] = useState(false);
  const location = useLocation();
  const toggleDrawer = () => {
    setToggleMenu(!toggleMenu);
  };
  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
    //console.log(language);
  };

  useEffect(() => {
    let element = document.querySelector(
      location.hash ? location.hash : "body"
    );
    if (element) {
      let headerOffset = 30;
      let elementPosition = element.getBoundingClientRect().top;
      let offsetPosition = elementPosition + window.scrollY - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }, [location]);

  const navigate = useNavigate();

  const loginPage = () => {
    navigate("/login");
  };

  const registrationPage = () => {
    navigate("/registration");
  };

  return (
    <>
      <Stack
        direction={"row"}
        spacing={2}
        sx={{
          position: "fixed",
          zIndex: 5,
          bgcolor: "#FFFFFF",
          borderRadius: "10px",
          mt: 4,
          mb: 22,
          py: 2,
          px: 2,
          alignItems: "center",
        }}
      >
        <Box component="img" alt="background" src={b120Text}></Box>
        <Box component="img" alt="background" src={lifetresor}></Box>
        <Button sx={{ ml: 8, width: "auto" }} onClick={toggleDrawer}>
          <svg
            width="46"
            height="46"
            viewBox="0 0 46 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="46" height="46" rx="23" fill="#2B3674" />
            <line x1="13" y1="15.5" x2="32" y2="15.5" stroke="white" />
            <line x1="13" y1="22.5" x2="32" y2="22.5" stroke="white" />
            <line x1="13" y1="29.5" x2="32" y2="29.5" stroke="white" />
          </svg>
        </Button>
      </Stack>
      <Stack
        direction={"row"}
        spacing={2}
        sx={{
          position: "relative",
          zIndex: 4,
          bgcolor: "#FFFFFF",
          borderRadius: "10px",
          mt: 6,
          mb: 22,
          py: 2,
          px: 2,
          alignItems: "center",
        }}
      ></Stack>
      <Drawer
        open={toggleMenu}
        PaperProps={{
          sx: { width: "100%", bgcolor: "primary.dark" },
        }}
      >
        <Box sx={{ mt: 10, px: 3 }}>
          <Stack
            direction={"row"}
            sx={{
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                bgcolor: "#FFFFFF",
                borderRadius: "100px",
              }}
            >
              <LanguageSelector />
            </Box>
            <Button
              onClick={toggleDrawer}
              variant="text"
              sx={{
                bgcolor: "#FFFFFF",
                minWidth: "50px",
                width: "50px !important",
                height: "50px",
                p: 0,
                borderRadius: "32px",
              }}
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.9997 15.0578L21.5283 9.52925C21.7886 9.2689 22.2107 9.2689 22.4711 9.52925C22.7314 9.7896 22.7314 10.2117 22.4711 10.4721L16.9425 16.0007L22.4711 21.5292C22.7314 21.7896 22.7314 22.2117 22.4711 22.4721C22.2107 22.7324 21.7886 22.7324 21.5283 22.4721L15.9997 16.9435L10.4711 22.4721C10.2107 22.7324 9.78862 22.7324 9.52827 22.4721C9.26792 22.2117 9.26792 21.7896 9.52827 21.5292L15.0569 16.0007L9.52827 10.4721C9.26792 10.2117 9.26792 9.7896 9.52827 9.52925C9.78862 9.2689 10.2107 9.2689 10.4711 9.52925L15.9997 15.0578Z"
                  fill="#2B3674"
                />
              </svg>
            </Button>
          </Stack>
          <Box
            sx={{
              height: "350px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Stack
              direction={"column"}
              spacing={2}
              sx={{ mt: 4, pl: 2 }}
              onClick={toggleDrawer}
            >
              <Link
                onClick={() =>
                  outer
                    ? navigate("/#" + "howitworks")
                    : scrollToSection("howitworks", 30)
                }
                sx={{ color: "#FFFFFF" }}
              >
                {t("topmenu.howitworks")}
              </Link>
              <Link
                onClick={() =>
                  outer
                    ? navigate("/#" + "whoisitfor")
                    : scrollToSection("whoisitfor", 30)
                }
                sx={{ color: "#FFFFFF" }}
              >
                {t("topmenu.whoisitfor")}
              </Link>
              <Link
                onClick={() =>
                  outer ? navigate("/#" + "prices") : scrollToSection("prices", 30)
                }
                sx={{ color: "#FFFFFF" }}
              >
                {t("topmenu.prices")}
              </Link>
              <Link
                onClick={() =>
                  outer ? navigate("/#" + "faq") : scrollToSection("faq", 30)
                }
                sx={{ color: "#FFFFFF" }}
              >
                {t("topmenu.faq")}
              </Link>
            </Stack>
          </Box>
          <Stack spacing={0}>
            <Button
              variant="contained"
              onClick={loginPage}
              endIcon={
                <svg
                  width="16"
                  height="9"
                  viewBox="0 0 16 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.6535 5H1.34244C0.995288 5 0.713867 4.77614 0.713867 4.5C0.713867 4.22386 0.995288 4 1.34244 4H13.6535L9.69797 0.853553C9.4525 0.658291 9.4525 0.341709 9.69797 0.146447C9.94344 -0.0488155 10.3414 -0.0488155 10.5869 0.146447L15.6155 4.14645C15.861 4.34171 15.861 4.65829 15.6155 4.85355L10.5869 8.85355C10.3414 9.04882 9.94344 9.04882 9.69797 8.85355C9.4525 8.65829 9.4525 8.34171 9.69797 8.14645L13.6535 5Z"
                    fill="white"
                  />
                </svg>
              }
              sx={{
                marginTop: "20px",
                bgcolor: "primary.dark",
                border: "2px solid #FFFFFF",
                borderRadius: "50px",
                height: "52px",
                width: "100%",
              }}
            >
              {t("topmenu.login")}
            </Button>
            <Button
              className="fullwidth-menuBtn"
              variant="contained"
              onClick={registrationPage}
              sx={{
                bgcolor: "primary.light",
                borderRadius: "50px",
              }}
            >
              {t("topmenu.registration")}
            </Button>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};
