import React from "react";

const CheckBoxIcons = ({ checked = false }) => {
  return (
    <>
      {checked === false ? (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="0.5" y="0.5" width="23" height="23" rx="7.5" fill="white" />
          <rect
            x="0.5"
            y="0.5"
            width="23"
            height="23"
            rx="7.5"
            stroke="#A6ADBA"
          />
        </svg>
      ) : (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="24" height="24" rx="8" fill="#62C275" />
          <path
            d="M16 8.99951L9.57143 14.7852L7 11.5934"
            stroke="white"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      )}
    </>
  );
};

export default CheckBoxIcons;
