import React, { useState, useEffect } from "react";
import { Box, Button, Stack, Grid } from "@mui/material";
import ProfileMenu from "./ProfileMenu";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import B120Logo from "../Commons/Icons/B120Logo";

export const MainMenu = ({ globalEvent, setGlobalEvent }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const { access, status: hatsStatus } = useSelector((state) => state.hats);
  const { items: assets = [], error: assetError } = useSelector(
    (state) => state.asset
  );
  const { error: postMortemError } = useSelector((state) => state.postMortem);
  const { error: timeCapsuleError } = useSelector((state) => state.timeCapsule);
  const { t } = useTranslation();

  const [chid, setChid] = useState(localStorage.chid);

  useEffect(() => {
    if (globalEvent?.type === "responseChangeHat") {
      setChid(globalEvent?.data.currentHatId);
    }
  }, [globalEvent]);

  useEffect(() => {
    setLoaded(true);
  }, [assets, access]);

  const isActive = (path) => {
    if (!path) return false;
    const search = path.split(";");
    if (path === "/") return location.pathname === path;
    return search.some((item) => location.pathname.startsWith(item));
  };

  const activeStyle = (path) => ({
    padding: "0px",
    "&:after": {
      position: "absolute",
      bottom: "0",
      content: '""',
      display: isActive(path) ? "block" : "none",
      height: "3px",
      width: "100%",
      bgcolor: "#62C275",
    },
  });

  const navigationItems = [
    {
      label: t("mainMenu.dashboard"),
      path: "/",
      enabled: true,
    },
    loaded &&
    (access?.accessAssets !== false ||
      (access?.accessAssetsOnDeath === true && !assetError))
      ? {
          label: t("mainMenu.assetList"),
          path: "/asset-list;/add-new-asset",
          enabled: true,
        }
      : null,
    //(!chid || chid === "null") ? {
    loaded &&
    (access?.accessAssets !== false ||
      (access?.accessAssetsOnDeath === true && !assetError))
      ? {
          label: t("mainMenu.contactList"),
          path: "/contact-list",
          enabled: true,
        }
      : null,
    loaded &&
    (access?.accessPostMortemRequests !== false ||
      (access?.accessPostMortemRequestsOnDeath === true && !!postMortemError))
      ? {
          label: t("mainMenu.instructions"),
          path: "/post-mortem-request",
          enabled: true,
        }
      : null,
    loaded &&
    (access?.accessDiaryItems !== false ||
      (access?.accessDiaryItemsOnDeath === true && !!timeCapsuleError))
      ? {
          label: t("mainMenu.diary"),
          path: "/time-capsule",
          enabled: true,
        }
      : null,
    {
      label: t("mainMenu.faq"),
      path: "/faq",
      enabled: true,
    },
  ].filter(Boolean);

  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        zIndex: 5,
        bgcolor: "#FFFFFF",
        display: "flex",
        justifyContent: "center",
        boxShadow: "0 0 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Grid
        container
        direction="row"
        sx={{
          width: "100%",
          pl: 10,
          pr: 2,
          alignItems: "center",
        }}
      >
        <Grid item xs={9} sx={{ pr: 1 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Stack
              width={{ xs: "100%", md: "900px", lg: "1200px" }}
              spacing={{ md: 2, lg: 3.5 }}
              direction="row"
              alignItems="center"
            >
              <div
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "ccenter",
                }}
                onClick={() => navigate("/")}
              >
                <B120Logo />
              </div>
              {!!loaded && (
                <>
                  {navigationItems.map((item) => (
                    <Button
                      key={item.path}
                      className="main-blue-text-btn"
                      variant="text"
                      sx={{
                        ...activeStyle(item.path),
                        [theme.breakpoints.up("md")]: {
                          fontSize: "14px",
                        },
                        [theme.breakpoints.up("lg")]: {
                          fontSize: "16px",
                        },
                        py: 3.25,
                        display: item.enabled ? "auto" : "none",
                      }}
                      onClick={() => navigate(item.path.split(";")[0])}
                    >
                      {item.label}
                    </Button>
                  ))}
                </>
              )}
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={3} sx={{ pl: 1 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <ProfileMenu
              globalEvent={globalEvent}
              setGlobalEvent={setGlobalEvent}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
