import React from "react";

const B120Logo = () => {
  return (
    <svg
      width="73"
      height="41"
      viewBox="0 0 73 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.50392 26.006V21.1213H11.0422C11.9825 21.1213 12.682 20.8805 13.1406 20.3989C13.5993 19.9173 13.8286 19.2179 13.8286 18.3006C13.8286 17.3832 13.5993 16.6952 13.1406 16.2366C12.682 15.755 11.9825 15.5142 11.0422 15.5142H5.50392V11.7303H10.8358C12.6017 11.7303 14.1841 11.9481 15.583 12.3838C16.9819 12.7966 18.0827 13.4846 18.8853 14.4478C19.688 15.411 20.0893 16.6952 20.0893 18.3006V18.7822C20.0893 20.2957 19.7339 21.5914 19.0229 22.6693C18.312 23.7471 17.2686 24.5727 15.8926 25.1461C14.5166 25.7194 12.831 26.006 10.8358 26.006H5.50392ZM0 26.006V0.481613H6.19191V26.006H0ZM5.50392 14.551V10.7671H10.1135C11.0537 10.7671 11.7302 10.5377 12.143 10.0791C12.5788 9.59748 12.7966 8.93243 12.7966 8.0839C12.7966 7.21245 12.5788 6.54739 12.143 6.08873C11.7302 5.60714 11.0537 5.36634 10.1135 5.36634H5.50392V0.481613H9.83826C12.7737 0.481613 15.0441 1.1008 16.6494 2.33919C18.2547 3.55464 19.0573 5.29755 19.0573 7.56791V8.0839C19.0573 9.66628 18.6445 10.9391 17.8189 11.9023C17.0163 12.8425 15.9155 13.519 14.5166 13.9318C13.1406 14.3446 11.5812 14.551 9.83826 14.551H5.50392Z"
        fill="#2B3674"
      />
      <path
        d="M26.2584 25.7996V2.33917L27.772 3.50875H20.6857V0.687992H29.5607V25.7996H26.2584Z"
        fill="#2B3674"
      />
      <path
        d="M33.5484 25.7996V21.6717C33.5484 20.6626 33.686 19.8027 33.9612 19.0917C34.2593 18.3808 34.7638 17.7387 35.4748 17.1654C36.1857 16.5691 37.1489 15.9958 38.3643 15.4454L43.0771 13.2782C44.2237 12.7508 45.1296 12.0742 45.7946 11.2486C46.4597 10.4001 46.7922 9.3108 46.7922 7.98069C46.7922 6.37538 46.345 5.12553 45.4506 4.23114C44.5562 3.33675 43.2949 2.88956 41.6667 2.88956C40.0614 2.88956 38.8001 3.33675 37.8827 4.23114C36.9654 5.12553 36.5068 6.37538 36.5068 7.98069H33.2732C33.2732 6.53591 33.5713 5.20579 34.1676 3.99034C34.7868 2.77489 35.7156 1.81171 36.9539 1.10078C38.1923 0.366928 39.7632 0 41.6667 0C43.5701 0 45.1296 0.366928 46.345 1.10078C47.5834 1.81171 48.5007 2.75196 49.097 3.92155C49.7162 5.09113 50.0258 6.36391 50.0258 7.73989V8.22148C50.0258 10.0332 49.4983 11.5582 48.4434 12.7966C47.4114 14.035 46.0125 15.0555 44.2466 15.8582L39.5339 18.0253C38.479 18.5069 37.7681 18.9885 37.4011 19.4701C37.0342 19.9517 36.8507 20.5938 36.8507 21.3965V24.5613L35.9564 22.9789H50.3354V25.7996H33.5484Z"
        fill="#2B3674"
      />
      <path
        d="M62.2432 26.4188C60.3627 26.4188 58.7574 26.0978 57.4273 25.4556C56.1201 24.7906 55.0537 23.8962 54.2281 22.7725C53.4255 21.6488 52.8407 20.353 52.4738 18.8853C52.1068 17.3947 51.9234 15.8238 51.9234 14.1726V12.315C51.9234 9.99879 52.2788 7.91189 52.9898 6.05432C53.7236 4.19674 54.8473 2.72903 56.3609 1.65118C57.8974 0.550393 59.8582 0 62.2432 0C64.6283 0 66.5776 0.550393 68.0911 1.65118C69.6047 2.72903 70.717 4.19674 71.4279 6.05432C72.1618 7.91189 72.5287 9.99879 72.5287 12.315V14.1726C72.5287 15.8238 72.3452 17.3947 71.9783 18.8853C71.6114 20.353 71.0151 21.6488 70.1895 22.7725C69.3869 23.8962 68.3319 24.7906 67.0248 25.4556C65.7176 26.0978 64.1237 26.4188 62.2432 26.4188ZM62.2432 23.3917C64.6283 23.3917 66.3712 22.589 67.472 20.9837C68.5957 19.3555 69.1575 16.787 69.1575 13.2782C69.1575 9.60893 68.5842 6.9831 67.4376 5.40072C66.3138 3.81835 64.5824 3.02716 62.2432 3.02716C59.8811 3.02716 58.1268 3.81835 56.9801 5.40072C55.8564 6.9831 55.2945 9.586 55.2945 13.2094C55.2945 16.7411 55.8564 19.3211 56.9801 20.9493C58.1038 22.5775 59.8582 23.3917 62.2432 23.3917Z"
        fill="#2B3674"
      />
      <path
        d="M65.97 39.999V31.1922H66.8831V39.999H65.97ZM71.4126 39.999L68.421 36.1182H69.4903L72.53 39.999H71.4126ZM66.5107 36.6709V35.8539H68.8655C69.2981 35.8539 69.6585 35.7698 69.9469 35.6016C70.2352 35.4334 70.4515 35.2051 70.5956 34.9168C70.7398 34.6284 70.8119 34.3 70.8119 33.9316C70.8119 33.5631 70.7398 33.2347 70.5956 32.9464C70.4515 32.658 70.2352 32.4297 69.9469 32.2615C69.6585 32.0853 69.2981 31.9972 68.8655 31.9972H66.5107V31.1922H68.7093C69.3581 31.1922 69.9068 31.2964 70.3554 31.5046C70.8119 31.7049 71.1563 32.0052 71.3886 32.4057C71.6289 32.7982 71.7491 33.2788 71.7491 33.8475V34.0157C71.7491 34.5844 71.6289 35.069 71.3886 35.4694C71.1483 35.8619 70.8039 36.1623 70.3554 36.3705C69.9068 36.5708 69.3581 36.6709 68.7093 36.6709H66.5107Z"
        fill="#2B3674"
      />
      <path
        d="M59.3957 40.2032C58.6348 40.2032 57.974 40.0671 57.4133 39.7947C56.8526 39.5224 56.3881 39.162 56.0196 38.7134C55.6512 38.2649 55.3748 37.7763 55.1906 37.2476C55.0064 36.719 54.9143 36.2023 54.9143 35.6977V35.4815C54.9143 34.9448 55.0064 34.4122 55.1906 33.8835C55.3828 33.3469 55.6672 32.8623 56.0436 32.4297C56.4201 31.9972 56.8887 31.6528 57.4494 31.3965C58.01 31.1322 58.6588 31 59.3957 31C60.1406 31 60.7894 31.1322 61.3421 31.3965C61.9028 31.6528 62.3714 31.9972 62.7478 32.4297C63.1243 32.8623 63.4046 33.3469 63.5888 33.8835C63.7811 34.4122 63.8772 34.9448 63.8772 35.4815V35.7097C63.8772 36.2144 63.7851 36.731 63.6009 37.2596C63.4246 37.7803 63.1483 38.2649 62.7718 38.7134C62.4034 39.162 61.9388 39.5224 61.3781 39.7947C60.8175 40.0671 60.1567 40.2032 59.3957 40.2032ZM59.3957 39.3622C59.9564 39.3622 60.457 39.2621 60.8976 39.0618C61.3381 38.8536 61.7106 38.5732 62.0149 38.2208C62.3193 37.8604 62.5516 37.4559 62.7118 37.0073C62.872 36.5588 62.9521 36.0902 62.9521 35.6016C62.9521 35.089 62.872 34.6084 62.7118 34.1599C62.5516 33.7113 62.3153 33.3148 62.0029 32.9704C61.6985 32.618 61.3261 32.3416 60.8855 32.1414C60.445 31.9411 59.9484 31.841 59.3957 31.841C58.8511 31.841 58.3585 31.9411 57.9179 32.1414C57.4774 32.3416 57.1009 32.618 56.7885 32.9704C56.4842 33.3148 56.2479 33.7113 56.0797 34.1599C55.9195 34.6084 55.8394 35.089 55.8394 35.6016C55.8394 36.0902 55.9195 36.5588 56.0797 37.0073C56.2399 37.4559 56.4722 37.8604 56.7765 38.2208C57.0809 38.5732 57.4534 38.8536 57.8939 39.0618C58.3424 39.2621 58.8431 39.3622 59.3957 39.3622Z"
        fill="#2B3674"
      />
      <path
        d="M49.9851 40.2032C49.2482 40.2032 48.6274 40.0871 48.1228 39.8548C47.6182 39.6145 47.2337 39.2861 46.9694 38.8696C46.7131 38.4451 46.5849 37.9685 46.5849 37.4399H47.498C47.498 37.7763 47.5781 38.0926 47.7383 38.389C47.9065 38.6774 48.1709 38.9136 48.5313 39.0979C48.8997 39.2741 49.3843 39.3622 49.9851 39.3622C50.5377 39.3622 50.9943 39.2821 51.3547 39.1219C51.7232 38.9617 51.9955 38.7454 52.1717 38.4731C52.356 38.2008 52.4481 37.8964 52.4481 37.56C52.4481 37.1355 52.2799 36.7831 51.9435 36.5027C51.6151 36.2144 51.1185 36.0421 50.4536 35.9861L49.4444 35.89C48.6915 35.8259 48.0908 35.5936 47.6422 35.1931C47.2017 34.7846 46.9814 34.248 46.9814 33.5831C46.9814 33.0785 47.1016 32.634 47.3418 32.2495C47.5821 31.8651 47.9226 31.5607 48.3631 31.3364C48.8116 31.1121 49.3483 31 49.9731 31C50.6058 31 51.1465 31.1161 51.595 31.3484C52.0516 31.5727 52.4 31.8851 52.6403 32.2856C52.8806 32.6861 53.0007 33.1506 53.0007 33.6793H52.0876C52.0876 33.3589 52.0155 33.0585 51.8714 32.7782C51.7272 32.4978 51.4989 32.2736 51.1865 32.1053C50.8742 31.9291 50.4697 31.841 49.9731 31.841C49.5005 31.841 49.108 31.9211 48.7956 32.0813C48.4912 32.2415 48.263 32.4538 48.1108 32.7181C47.9666 32.9824 47.8945 33.2708 47.8945 33.5831C47.8945 33.9756 48.0347 34.308 48.315 34.5804C48.5954 34.8527 48.9999 35.0089 49.5285 35.0489L50.5377 35.1451C51.1305 35.1931 51.6351 35.3253 52.0516 35.5415C52.4761 35.7498 52.8005 36.0261 53.0248 36.3705C53.249 36.707 53.3612 37.1034 53.3612 37.56C53.3612 38.0726 53.221 38.5292 52.9407 38.9297C52.6683 39.3302 52.2799 39.6425 51.7753 39.8668C51.2706 40.0911 50.6739 40.2032 49.9851 40.2032Z"
        fill="#2B3674"
      />
      <path
        d="M40.0399 39.999V31.2283H40.9531V39.999H40.0399ZM40.7368 39.999V39.17H45.1221V39.999H40.7368ZM40.7368 35.95V35.133H44.7136V35.95H40.7368ZM40.7368 32.0573V31.2283H44.99V32.0573H40.7368Z"
        fill="#2B3674"
      />
      <path
        d="M31.7916 39.999V31.1922H32.7047V39.999H31.7916ZM37.2342 39.999L34.2426 36.1182H35.3119L38.3516 39.999H37.2342ZM32.3323 36.6709V35.8539H34.6871C35.1197 35.8539 35.4801 35.7698 35.7685 35.6016C36.0568 35.4334 36.2731 35.2051 36.4172 34.9168C36.5614 34.6284 36.6335 34.3 36.6335 33.9316C36.6335 33.5631 36.5614 33.2347 36.4172 32.9464C36.2731 32.658 36.0568 32.4297 35.7685 32.2615C35.4801 32.0853 35.1197 31.9972 34.6871 31.9972H32.3323V31.1922H34.5309C35.1797 31.1922 35.7284 31.2964 36.1769 31.5046C36.6335 31.7049 36.9779 32.0052 37.2102 32.4057C37.4505 32.7982 37.5706 33.2788 37.5706 33.8475V34.0157C37.5706 34.5844 37.4505 35.069 37.2102 35.4694C36.9699 35.8619 36.6255 36.1623 36.1769 36.3705C35.7284 36.5708 35.1797 36.6709 34.5309 36.6709H32.3323Z"
        fill="#2B3674"
      />
      <path
        d="M26.4344 39.999V31.829H27.3475V39.999H26.4344ZM23.6951 32.0573V31.2283H30.0868V32.0573H23.6951Z"
        fill="#2B3674"
      />
      <path
        d="M17.2828 39.999V31.2283H18.9528V39.999H17.2828ZM18.7125 39.999V38.5572H22.6894V39.999H18.7125ZM18.7125 36.2624V34.8207H22.377V36.2624H18.7125ZM18.7125 32.67V31.2283H22.5933V32.67H18.7125Z"
        fill="#2B3674"
      />
      <path
        d="M10.5597 39.999V31.2283H12.2658V39.999H10.5597ZM12.0255 36.3465V34.9048L15.5338 34.8927V36.3345L12.0255 36.3465ZM12.0255 32.67V31.2283H15.702V32.67H12.0255Z"
        fill="#2B3674"
      />
      <path
        d="M6.66495 39.999V31.2283H8.37103V39.999H6.66495Z"
        fill="#2B3674"
      />
      <path
        d="M0 39.9992V31.2285H1.70608V39.9992H0ZM1.46579 39.9992V38.4974H5.2504V39.9992H1.46579Z"
        fill="#2B3674"
      />
    </svg>
  );
};

export default B120Logo;
