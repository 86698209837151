import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Paper, Typography, Button, Grid, Box } from "@mui/material";
import { t } from "i18next";
import HomeWorkOutlinedIcon from "@mui/icons-material/HomeWorkOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import Check from "@mui/icons-material/Check";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import "./FirstLoginStepper.css";
import ConfirmationModal from "../Commons/Modals/ConfirmationModal";
import HandGestureIcon from "../Commons/Icons/HandGestureIcon";
import { getCurrentUser } from "../../store/Actions/userAction";

const steps = [
  {
    key: 1,
    link: "/add-new-asset",
    state: {},
    icon: HomeWorkOutlinedIcon,
  },
  {
    key: 2,
    link: "/add-new-contact",
    state: { editmode: true, data: {} },
    icon: PersonAddAltOutlinedIcon,
  },
  {
    key: 3,
    link: "/time-capsule",
    state: {},
    icon: PostAddOutlinedIcon,
  },
];

const FirstLoginStepper = ({ globalEvent, setGlobalEvent }) => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(true);
  const { items: assets = [], loading: assetLoading } = useSelector(
    (state) => state.asset
  );
  const { items: contacts = [], loading: contactLoading } = useSelector(
    (state) => state.contact
  );
  const { items: timeCapsule = [], loading: timeCapsuleLoading } = useSelector(
    (state) => state.timeCapsule
  );
  const { user } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);
  const handleClose = () => {
    setModalOpen(false);
  };

  const navigateToPersonalData = () => {
    navigate("/edit-user-data");
  };

  const calculateCurrentStep = () => {
    let step = 1;
    const filteredAssets = assets.filter(
      (row) => row.currency !== null && row.currency !== ""
    );
    if (filteredAssets.length > 0) step = 2;
    if (filteredAssets.length > 0 && contacts.length > 0) step = 3;
    if (
      filteredAssets.length > 0 &&
      contacts.length > 0 &&
      timeCapsule.length > 0
    )
      step = 4;

    return step;
  };

  useEffect(() => {
    if (!assetLoading && !contactLoading && !timeCapsuleLoading) {
      setCurrentStep(calculateCurrentStep());
    }
  }, [
    assetLoading,
    contactLoading,
    timeCapsuleLoading,
    assets.length,
    contacts.length,
    timeCapsule.length,
  ]);

  useEffect(() => {
    if (!assetLoading && !contactLoading && !timeCapsuleLoading) {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [assetLoading, contactLoading, timeCapsuleLoading]);

  const isFirstLogin = currentStep < 4;

  const startCreateContact = () => {
    if (!user) {
      return;
    }

    const { firstName, lastName, phone, address } = user;

    const postalCode = address?.postalCode || "";

    if (
      !firstName ||
      !firstName.trim() ||
      !lastName ||
      !lastName.trim() ||
      !phone ||
      !phone.trim() ||
      !postalCode.trim()
    ) {
      setModalOpen(true);
    } else if (!modalOpen) {
      navigate("add-new-contact", { state: { editmode: true, data: {} } });
    }
  };

  if (!isFirstLogin) return null;

  return loading ? null : (
    <Box>
      <Typography variant="body1" sx={{ fontSize: 18 }}>
        {t("home.intro1")}
      </Typography>

      <Typography variant="body1" sx={{ fontSize: 18, fontWeight: 700 }}>
        {t("home.intro2")}
      </Typography>

      <Grid container>
        <Grid item xs={12} sx={{ mt: 3, mb: 5 }}>
          <div className={"stepper-container step-" + currentStep}>
            <div className="step-number-container">
              <div className="bg">
                <div className="inner">
                  {Array.from({ length: 100 }).map((_, i) => (
                    <div key={i} className="line"></div>
                  ))}
                </div>
                <div className={"inner-active " + "step-" + currentStep}>
                  {Array.from({ length: 100 }).map((_, i) => (
                    <div key={i} className="line"></div>
                  ))}
                </div>
              </div>
            </div>
            {steps.map((step, index) => (
              <>
                <div
                  key={step.key}
                  className={
                    step.key === currentStep
                      ? "step current"
                      : currentStep > step.key
                      ? "step completed"
                      : "step"
                  }
                >
                  <div className="step-number-container">
                    <div className="step-number">
                      {step.key < currentStep ? <Check /> : step.key}
                    </div>
                  </div>
                  <Paper
                    elevation={3}
                    style={{
                      padding: 30,
                      textAlign: "left",
                      borderRadius: "32px",
                      boxShadow: "4px 4px 20px rgba(0,0,0,0.1)",
                      position: "relative",
                      height: "100%",
                    }}
                  >
                    {React.createElement(step.icon, {
                      style: {
                        width: "64px",
                        height: "64px",
                        color: "#2B3674",
                      },
                    })}
                    <Typography
                      variant="body1"
                      sx={{ fontSize: 18, fontWeight: 700 }}
                      gutterBottom
                    >
                      {t(`home.step${step.key}title`)}
                    </Typography>
                    <Typography
                      variant="body3"
                      sx={{ fontSize: 14, fontWeight: 400, opacity: 0.6 }}
                      gutterBottom
                    >
                      {t(`home.step${step.key}description`)}
                    </Typography>
                    {step.key === currentStep && (
                      <Button
                        className="green-btn"
                        variant="contained"
                        color="success"
                        sx={{
                          bgcolor: "rgba(98, 194, 117, 1)",
                          fontSize: 16,
                          fontWeight: 500,
                          py: 2,
                          px: 5,
                          boxShadow: "none",
                          "&:hover": {
                            boxShadow: "none",
                          },
                        }}
                        onClick={() => {
                          if (step.key == 2) {
                            startCreateContact();
                          } else {
                            navigate(step.link, { state: step.state });
                          }
                        }}
                        endIcon={<ArrowForwardIcon />}
                      >
                        {t(`home.step${step.key}button`)}
                      </Button>
                    )}
                  </Paper>
                </div>
              </>
            ))}
          </div>
        </Grid>
      </Grid>

      <ConfirmationModal
        open={modalOpen}
        onClose={() => handleClose()}
        title={t("contact.dialog.personalDataMissingTitle")}
        description={t("contact.dialog.personalDataMissingDescription")}
        cancelText={t("contact.dialog.personalDataMissingCancel")}
        confirmText={t("contact.dialog.personalDataMissingBtn")}
        onConfirm={() => navigateToPersonalData()}
        icon={<HandGestureIcon />}
      />
    </Box>
  );
};

export default FirstLoginStepper;
