import { Avatar, Box, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import AddAPhotoIcon from "@mui/icons-material/AddAPhotoOutlined";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useCallback, useEffect, useRef } from "react";
import {
  getCurrentUser,
  handleProfileImageUpload,
} from "../../store/Actions/userAction";

import defaultImage from "../../Assets/default-avatar.png";

const ProfileImage = ({ version = "small", withUpload, pos = "left" }) => {
  const dispatch = useDispatch();
  const { user, loading } = useSelector((state) => state.user);
  const inputFile = useRef(null);

  const handleChange = useCallback((event) => {
    if (event.target.files && event.target.files.length > 0) {
      Array.from(event.target.files).forEach((file) => {
        onFileUpload(file);
      });
    }
  }, []);

  const reloadProfileImage = (response) => {
    dispatch(getCurrentUser());
  };

  const onFileUpload = useCallback((file) => {
    dispatch(handleProfileImageUpload(file, reloadProfileImage));
  });

  const initials =
    !loading && user?.lastName && user?.firstName
      ? `${user.lastName[0]}${user.firstName[0]}`
      : "";

  useEffect(() => {
    if (!user) {
      dispatch(getCurrentUser());
    }
  }, [dispatch, user]);

  const avatarStyles = {
    backgroundColor: initials ? "#BDBDBD" : "transparent",
    width: version === "small" ? 45 : version === "large" ? 100 : 60,
    height: version === "small" ? 45 : version === "large" ? 100 : 60,
    fontSize: version === "small" ? 15 : version === "large" ? 36 : 20,
  };

  const boxStyle = {
    width: version === "small" ? 45 : version === "large" ? 100 : 60,
    height: version === "small" ? 45 : version === "large" ? 100 : 60,
  };

  const buttonStyles = {
    position: "relative",
    zIndex: 1,
    top: version === "large" ? -103 : -60,
    left: pos == "left" ? 0 : version === "large" ? "100%" : 75,
    marginLeft: pos == "left" ? 0 : "-32px",
    bgcolor: "#FFFFFF",
    height: version === "large" ? "32px" : "24px",
    width: version === "large" ? "32px" : "24px",
    minWidth: version === "large" ? "32px" : "24px",
    boxShadow: "0px 0px 10px 0px #0000001A",
    borderRadius: "32px",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    stroke: "#2B3674",
  };

  const iconStyles = {
    transform: "scaleX(-1)",
    color: "#000000",
    width: "16.67px",
    height: "14.67px",
    strokeWidth: 0
  };

  return (
    <Box sx={boxStyle}>
      <Avatar
        sx={avatarStyles}
        src={
          user?.profileImage
            ? `data:image/jpeg;base64,${user.profileImage}`
            : initials
            ? undefined
            : defaultImage
        }
      >
        {!user?.profileImage && initials}
      </Avatar>
      {!!withUpload && (
        <>
          <input
            accept="image/*"
            ref={inputFile}
            style={{ display: "none" }}
            id="raised-button-file"
            multiple
            type="file"
            onChange={handleChange}
            onClick={(event) => {
              event.target.value = "";
              event.target.type = "text";
              event.target.type = "file";
            }}
          />
          <label htmlFor="raised-button-file">
            <Box variant="text" sx={buttonStyles}>
              {user?.profileImage ? (
                <EditOutlinedIcon sx={iconStyles} />
              ): (<AddAPhotoIcon sx={iconStyles} />)}
            </Box>
          </label>
        </>
      )}
    </Box>
  );
};

export default ProfileImage;
