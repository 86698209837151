import React, { useRef, props, useEffect, useReducer, useState, Suspense } from 'react';
import { Footer } from "../Footer/Footer";

import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form"
import { MainMenu } from "../Navigation/MainMenu";

import { TextareaAutosize } from "@mui/base/TextareaAutosize";

import {
  Typography,
  Grid,
  Stack,
  Box,
  Card,
  CardContent,
  Button,
  TextField,
  MenuItem,
  InputLabel,
  Switch,
} from "@mui/material";

import EditIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteForeverOutlined";

import ShowFileOfAsset from "../FileHandlers/ShowFileOfAsset";
import AddFileToAsset from "../FileHandlers/AddFileToAsset";

import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'html-react-parser';
import DefaultLayout from '../Layout/DefaultLayout';

import currencyJSON from "../Assets/Data/Common-Currency.json";
import currencyMinJSON from "../Assets/Data/Common-Currency-min.json";
import DateFormatter from '../Commons/DateFormatter';


const EditHousingSavings = ({globalEvent,setGlobalEvent,title}) => {

  const navigate = useNavigate();
  const {state} = useLocation();

  const { t, i18n } = useTranslation();	
  const [language, setLanguage] = useState(i18n.language);
  const [chid, setChid] = useState(localStorage.chid);
  const [lastUpdate, setLastUpdate] = useState(null);

  const formatValue = (price) => {
    let formatedPrice = price.toLocaleString("fr-FR");
    return formatedPrice.replaceAll(" ","  ");;
  };

  const mql = window.matchMedia("(max-width: 600px)");
  let mobileView = mql.matches;

  const [show, setShow] = useState(false);

  const handleShow = () => {
    setShow(!show);
  };

  const {
    register,
    handleSubmit,
    formState: { isDirty, isValid, errors },
    reset,
	setValue
  } = useForm({
    mode: "onBlur"
  });

  const onSubmit = (data, e) => {doSaveAsset(e,collectData(data, e)); 
  //console.log(data, e)
  }
  const onError = (errors, e) => {//console.log(errors, e)
  }

  const [id, setID] 				  = useState(null);
  const [draft, setDraft]             = useState(true);
  const [name, setName] 			  = useState("");
  const [value, setVal] 			  = useState("");
  const [currency, setCurrency]       = useState("");
  const [description, setDescription]          = useState("");  
  
  const [editMode, setEditMode]       = useState(false);

  const [attachmentIds, setAttachmentIds] = useState([]);
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  
  const formRef = useRef();
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);

  const [accountCurrencyMore, setAccountCurrencyMore] = useState(false);
  const [openAccountCurrency, setOpenAccountCurrency] = useState(false);

  
  useEffect(() => {
    
	if (isValid) {
	  setIsSaveEnabled(true)
	} else {
	  setIsSaveEnabled(false)		
	}    
  }, [isValid]);
  
  
  const doSaveAsset = (event, data) => {
    event.stopPropagation(); 
	setGlobalEvent({type:'doSaveAsset',data:data})
  };


  const onFileUpload	= (data) => {
	  setGlobalEvent({type:'doFileUpload',data:{file:data, uploadType: 'document'}})
  }

  const collectData	= (data, e) => {
	 
	let collectedData = {};
	collectedData['asset'] = {};
	
	if (id*1>0){
	collectedData['asset']['id'] = id;		
	}
	
	collectedData['asset']['assetType'] = 'housingSavings';
	collectedData['asset']['value'] = data.value ? data.value.replace(/\D/g,'')*1 : 0;	
	collectedData['asset']['currency'] = data.currency;
	//collectedData['asset']['draft'] = draft;
	
	collectedData['asset']['details'] = {};
	collectedData['asset']['details']['name'] = data.name;
	collectedData['asset']['details']['category'] = data.category;
	collectedData['asset']['details']['description'] = description;
		
	collectedData['attachmentIds'] = attachmentIds;

	return collectedData;
  }

  const setData	= (data, e) => {
    setLastUpdate(data?.updatedAt);
    setID(data?.id ? data?.id : null);
    setEditMode(false);
    setVal(data?.value ? formatValue(data?.value*1) : 0);
    setCurrency(data?.currency ? data?.currency : "HUF");	  
    
    setName(data?.details?.name ? data?.details?.name : "");	  
    setDescription(data?.details?.description ? data?.details?.description : "");	  
  
  }

  useEffect(() => {
	//console.log(state);
    setData(state?.data);
    setEditMode(state?.editmode?state?.editmode:false);
	if (state?.data?.id){
	  setGlobalEvent({type:'doGetAttachments',data:{id:state?.data?.id}})	
	}
	
  },[state]);
  
  
  useEffect(() => {
    setValue('name',name);
  },[name]);

  useEffect(() => {
    setValue('value',value);
  },[value]);
  

  useEffect(() => {
    setValue('currency',currency);
  },[currency]);    

  
  useEffect(() => {
	if (process.env.REACT_APP_LOG_MESSAGELOG == 'on'){
	//console.log('------ Global Event ------ EditHousingSavings');
	//console.log(globalEvent);
	//console.log('------------');
	}
	

if (globalEvent?.type == 'responseFileUpload'){
	
	let aryFiles = [];
	
	setAttachmentIds( [...attachmentIds, globalEvent?.data?.attachment?.id]	)
	
	aryFiles.push(globalEvent?.data.attachment);
	
	setAttachmentFiles( [...attachmentFiles, ...aryFiles] );	

		let collectedData = {};
		collectedData['asset'] = {};
		if (id){	
		   collectedData['asset']['id'] = id;		
		} 
		collectedData['asset']['assetType'] = 'housingSavings';
		collectedData['attachmentIds'] = [...attachmentIds, globalEvent?.data?.attachment?.id];

	    setGlobalEvent({type:'doSaveAssetImage',data:collectedData})	
	
	}
	
	if (globalEvent?.type == 'responseSaveAssetImage'){
		if (globalEvent?.data?.asset.id) {
			setID(globalEvent?.data?.asset.id ? globalEvent?.data?.asset.id : null);
		}
	}
	
	if (globalEvent?.type == 'responseDeleteAttachment'){
      if (state?.data?.id){	
	    setGlobalEvent({type:'doGetAttachments',data:{id:state?.data?.id}})	
	  }
	}
	
	
	if (globalEvent?.type == 'responseGetAttachments'){
	
	//console.log(globalEvent?.data.attachments)
	let aryFiles = [];
	
	const length = Array.isArray(globalEvent?.data.attachments) ? globalEvent?.data.attachments.length : 0;
	for (let i = 0; i < length; i++) {
		aryFiles.push(globalEvent?.data.attachments[i]);
	}
	
	setAttachmentFiles( [...aryFiles] );
	
	}


  }, [globalEvent])


  return (
  	
	<>
    <DefaultLayout globalEvent={globalEvent} setGlobalEvent={setGlobalEvent}>
	  
	  {((editMode !== true) && (
	        <Box className="my-container"
            sx={{
              mt: { xs: 0, sm: 8 },
              mb: 20,
              minHeight: "100vh",
              textAlign: "left",
            }}
          >
        <Grid
          container
        >
          <Grid item xs={12}>
            <Stack direction={{md: "row", xs: "column"}} justifyContent={"space-between"}>
                <Stack
                  direction={"row"}
                  spacing={2}
                  alignItems={"center"}
                  sx={{ mb: 6, pl: { xs: 2, sm: 0 } }}
                >
                  <Button
                    onClick={() => {
                      navigate('/asset-list');
                    }}
                    variant="text"
                    sx={{
                      height: "50px",
                      width: "50px",
                      minWidth: "50px",
                      p: 0,
                      boxShadow: "0px 0px 10px 0px #0000001A",
                      borderRadius: "32px",
                    }}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.19697 14.5546L8.4042 15.3477C8.06852 15.6834 7.52573 15.6834 7.19362 15.3477L0.251538 8.40706C-0.0841421 8.07128 -0.0841421 7.52833 0.251538 7.19612L7.19362 0.251947C7.5293 -0.0838342 8.07209 -0.0838342 8.4042 0.251947L9.19697 1.04496C9.53622 1.38431 9.52908 1.93798 9.18268 2.27018L4.87959 6.37096H15.1427C15.6177 6.37096 15.9998 6.75317 15.9998 7.22826V8.37134C15.9998 8.84643 15.6177 9.22865 15.1427 9.22865H4.87959L9.18268 13.3294C9.53264 13.6616 9.53978 14.2153 9.19697 14.5546Z"
                        fill="#2B3674"
                      />
                    </svg>
                  </Button>
                  <Typography variant="body1" sx={{ fontWeight: 700 }}>
                    {ReactHtmlParser(t("asset.housingSavingsData"))}
                  </Typography>
                </Stack>
                <Stack
                  direction={"row"}
                  spacing={2}
                  justifyContent={{md: "end", xs: "start"}}
                  sx={{ mr: { sm: -4 }, mb: { xs: 6, md: 0 } }}
                >
				{(!chid||chid=='null') && (<>
                  <Button
                    variant="outlined"
                    className="small-btn outlined-btn"
                    startIcon={<EditIcon />}
                    sx={{
                      boxShadow: "0px 0px 10px 0px #0000001A",
                    }}
					onClick={(e)=>{setEditMode(true)}}
                  >
                   {ReactHtmlParser(t("asset.buttondoedit"))}
                  </Button>
                  <Button
                    variant="text"
                    sx={{
                      minWidth: "50px",
                      width: "50px",
                      height: "50px",
                      p: 0,
                      boxShadow: "0px 0px 10px 0px #0000001A",
                      borderRadius: "32px",
                    }}
					onClick={(event)=>{event.stopPropagation(); setGlobalEvent({type:'doDeleteAsset',data:{id:id}})}}
                  >
                    <DeleteIcon sx={{ color: "info.secondary" }} />
                  </Button>
					</>)}					  				  
                </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={7} sx={{pr: {md:3}}}>
            <Card
              className="add-asset-form-card"
              sx={{ p: 2, width: { xs: "auto", md: "100%" } }}
            >
              <CardContent>
                <Typography variant="body2" sx={{ fontWeight: 700, mb: 0 }}>
					{name}
                </Typography>
                </CardContent>
            </Card>
            <Card
              className="add-asset-form-card"
              sx={{ p: 2, width: { xs: "auto", md: "100%" }, my: 2 }}
            >
              <CardContent>
                <Typography variant="body2" sx={{ fontWeight: 700, mb: 0 }}>
                  {ReactHtmlParser(t("asset.housingSavingsDescription"))}
                </Typography>
                <Typography
                  variant="body4"
                  sx={{ color: "primary.main", mt: 2 }}
                >
				{description}
                </Typography>
              </CardContent>
            </Card>
            <Card
              className="add-asset-form-card"
              sx={{ px: 2, py: 1, width: { xs: "auto", md: "100%" } }}
            >
              <CardContent
                sx={{
                  "&.MuiCardContent-root": {
                    py: 1,
                  },
                }}
              >
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  justifyContent={{ xs: "center", sm: "space-between" }}
                  alignItems={{ xs: "space-between", sm: "center" }}
                  sx={{ mb: 0 }}
                >
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{ fontWeight: 700, mb: 0, color: "primary.light" }}
                    >
                      {ReactHtmlParser(t("asset.actualBalance"))}:
                    </Typography>
                    <Typography variant="body4" sx={{ color: "primary.light" }}>
                      {ReactHtmlParser(t("common.refreshed"))}:  <DateFormatter timeStamp={lastUpdate} />
                    </Typography>
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 700 }}
                  >{`${formatValue(value)} ${currency}`}</Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={5} sx={{ pl: { xs: 0, md: 10 }, mt: {md :0, xs: 6} }}>
            <Box>
              <Box sx={{ mb: 6 }}>
                <Typography variant="body2" sx={{ fontWeight: 700, mb: 0 }}>
                  {ReactHtmlParser(t("asset.housingSavings.documentsTitle"))} {(attachmentFiles.length>0)&&(<>({attachmentFiles.length})</>)}
                </Typography>
                <Typography variant="caption" sx={{ color: "primary.main" }}>
                  
                </Typography>
              </Box>
               <ShowFileOfAsset  globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} itemList={attachmentFiles} editMode={editMode}/>
            </Box>
          </Grid>
        </Grid>
      </Box>
	  
	  
	  ))}
	  
	  {/***** ******/}
	  
	  {((editMode !== false) && (
	  <>
      <Box
        ref={formRef}
		component="form"
        className='my-container'
		onSubmit={handleSubmit(onSubmit, onError)}
      >
        <Grid
          sx={{
            mt: { xs: 0, sm: 8 },
            mb: 20,
            minHeight: "100vh",
            textAlign: "left",
          }}
          container
        >
          <Grid item xs={12}>
          <Typography
              variant="body1"
              sx={{ mb: 6, pl: { xs: 2, sm: 0 }, fontWeight: 700 }}
            >
              {ReactHtmlParser(t("asset.housingSavingsNew"))}
            </Typography>
          </Grid>
          <Grid item xs={12} md={7} sx={{pr: {md :3}}}>
            
<Card
              className="add-asset-form-card"
              sx={{ p: 2, width: { xs: "auto", md: "100%" } }}
            >
              <CardContent>
                <Typography variant="body2" sx={{ fontWeight: 700, mb: 4 }}>
                  {ReactHtmlParser(t("asset.housingSavings.housingSavingsData"))}
                </Typography>
                <Box
                  sx={{
                    mt: 4,
                  }}
                >
                  <Stack spacing={2} sx={{ mb: 4 }}>
                    
					
                <div>
				  <TextField key="name" className="asset-field-fullwidth" label={ReactHtmlParser (t('field.name'))} type="text" 

	    {...register("name", {
          required: t('errors.field.required')
		  /*,
          pattern: {
            value: ,
            message: "Entered value does not match format"
          },*/
        })}/>
		    
				  
				  {errors.name && <span role="alert">{errors.name.message}</span>}
			
			</div>	  
			
					
                  </Stack>
                </Box>
                <Box
                  sx={{
                    my: 4,
                    borderTop: "2px solid #EBEBEB",
                  }}
                >
                  <Stack
                    direction={"row"}
                    spacing={1}
                    sx={{ my: 4 }}
                    alignItems={"center"}
                  >
                    <Typography variant="body2" sx={{ fontWeight: 700 }}>
                      {ReactHtmlParser(t("field.description"))}
                    </Typography>
                    <Typography variant="caption">({ReactHtmlParser(t("common.optional"))})</Typography>
                  </Stack>
                  <TextareaAutosize
                    key="description"
                    placeholder={ReactHtmlParser(
                      t("asset.housingSavings.descriptionPlaceholder")
                    )}
{...register("description", {
          /*,
          pattern: {
            value: ,
            message: "Entered value does not match format"
          },*/
        })}
					value={description}
					onChange={(event) => {setDescription(event.target.value);}}
                    style={{
                      font: 'inherit',
                      width: "100%",
                      height: "133px",
                      resize: "none",
                      border: "1px solid #C6C6C6",
                      borderRadius: "24px",
                      paddingTop: "16px",
                      paddingLeft: "16px",
                      paddingRight: "5px",
                      paddingBottom: "16px",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    my: 4,
                  }}
                >
                  <Stack
				    onClick={handleShow}
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Stack
                      direction={"row"}
                      spacing={1}
                      sx={{ mb: 4 }}
                      alignItems={"center"}
                    >
                      <Typography variant="body2" sx={{ fontWeight: 700 }}>
                        {ReactHtmlParser(t("asset.actualBalance"))}
                      </Typography>
                      <Typography variant="caption">({ReactHtmlParser(t("common.optional"))})</Typography>
                    </Stack>
                    <Box
                      variant="text"
                      onClick={handleShow}
                      sx={{
                        mt: -2,
                        transform: !show ? "rotate(180deg)" : "rotate(0)",
                      }}
                    >
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M7.5 20L16 12L24.5 20" stroke="#2B3674" />
                      </svg>
                    </Box>
                  </Stack>
                  {show ? (
                    <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
                      
					  
					  
					  <div>
                  <TextField key="value" className="asset-field-small" label={ReactHtmlParser (t('field.value'))} type="text" onFocus={event => {
        event.target.select();
      }}	  
				  onChange={(event) => {setVal(event.target.value);}}
	    {...register("value", {
          required: t('errors.field.required'),
		  onChange: (event) => {
		  //console.log(formatValue(event.target.value.replace(/\D/g,'')*1)); 
		  event.target.value = formatValue(event.target.value.replace(/\D/g,'')*1)
		  }
		  /*,
          pattern: {
            value: ,
            message: "Entered value does not match format"
          },*/
        })}
				  />
				  
				  {errors.value && <span role="alert">{errors.value.message}</span>}
			</div>
			
			
			<div>
                 <TextField
                              select
							  closeMenuOnSelect={false}
							  blurInputOnSelect={false}
							  key="currency"
                              className="asset-field-small"
                              label={ReactHtmlParser(t("field.currency"))}
                              type="text"
							  defaultValue={currency}                            
							  onChange={(ev) => {
                              ev.preventDefault();
                              if (ev.target.value == "MORE") {
                                setAccountCurrencyMore(true);

                                setTimeout(() => {
                                  setOpenAccountCurrency(true);
                                }, 1000);
                              } else {
                                setValue("currency", ev.target.value);
                              }
                            }}
                            inputProps={{
                              open: openAccountCurrency,
                              onClose: (ev) => {
                                //console.log(ev.target);
                                ev.stopPropagation();
                                ev.preventDefault();
                                setOpenAccountCurrency(false);
                                return false;
                              },
                              onOpen: (ev) => {
                                //console.log(ev.target);
                                ev.stopPropagation();
                                ev.preventDefault();
                                setOpenAccountCurrency(true);
                                return false;
                              },
                            }}
                              {...register("currency", {
                                required: t("errors.field.required"),
								onChange: (ev) => {
                                if (ev.target.value == "MORE") {
                                  ev.defaultMuiPrevented = true;
                                  ev.stopPropagation();
                                  ev.preventDefault();
                                  setAccountCurrencyMore(true);
                                  setTimeout(() => {
                                    setOpenAccountCurrency(true);
                                  }, 250);
                                  return false;
                                } else {
                                  setCurrency(ev.target.value);
                                  setValue("currency", ev.target.value);
                                }
                              },
                                /*,
          pattern: {
            value: ,
            message: "Entered value does not match format"
          },*/
                              })}
                          >
{!accountCurrencyMore && (							  
							  <MenuItem
                                value={currency}
                                id={0}
                                key={0}
                                onClickHandler={(ev) => {
                                  ev.defaultMuiPrevented = true;
                                  ev.stopPropagation();
                                  ev.preventDefault();
                                  return false;
                                }}
                              >
							  {currency}
                              </MenuItem>
                            )}
                            {Object.keys(currencyMinJSON).map((key, index) => {
                              return (
                                !accountCurrencyMore && (currencyJSON[key].code != currency) && (
                                  <MenuItem
                                    closeOnSelect={false}
                                    value={currencyJSON[key].code}
                                    id={index}
                                    key={index}
                                  >
                                    {currencyJSON[key].code}
                                  </MenuItem>
                                )
                              );
                            })}
                            {!accountCurrencyMore && (
                              <MenuItem
                                value="MORE"
                                id={0}
                                key={0}
                                onClickHandler={(ev) => {
                                  ev.defaultMuiPrevented = true;
                                  ev.stopPropagation();
                                  ev.preventDefault();
                                  return false;
                                }}
                              >
                                {t("common.moreCurrency")}
                              </MenuItem>
                            )}

                            {Object.keys(currencyJSON).map((key, index) => {
                              return (
                                accountCurrencyMore && (
                                  <MenuItem
                                    value={currencyJSON[key].code}
                                    id={index}
                                    key={index}
                                  >
                                    {currencyJSON[key].code}
                                  </MenuItem>
                                )
                              );
                            })}
							
                          </TextField>
						  
				  {errors.currency && <span role="alert">{errors.currency.message}</span>}
			</div>
					  
					</Stack>
                  ) : (
                    ""
                  )}
                </Box>
                
              </CardContent>
            </Card>
            <Stack
              direction={"row"}
              spacing={2}
              sx={{ mt: 4, justifyContent: "end", alignItems: "center" }}
            >
              <Button variant="text" className='secondary-blue-text-btn' onClick={(e)=>{ if (!id) { navigate('/asset-list') } else { setEditMode(false); }  }}>{ReactHtmlParser(t("common.buttondocancel"))}</Button>
              <Button
                      type="button"
                      variant="contained"
                      className="small-btn secondary-blue-btn"
                      disabled={!isSaveEnabled}
                      onClick={(ev) => {
                        formRef.current.requestSubmit();
                      }}
                      endIcon={
                        <svg
                          width="12"
                          height="9"
                          viewBox="0 0 12 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.2929 5H0.5C0.223858 5 0 4.77614 0 4.5C0 4.22386 0.223858 4 0.5 4H10.2929L7.14645 0.853553C6.95118 0.658291 6.95118 0.341709 7.14645 0.146447C7.34171 -0.0488155 7.65829 -0.0488155 7.85355 0.146447L11.8536 4.14645C12.0488 4.34171 12.0488 4.65829 11.8536 4.85355L7.85355 8.85355C7.65829 9.04882 7.34171 9.04882 7.14645 8.85355C6.95118 8.65829 6.95118 8.34171 7.14645 8.14645L10.2929 5Z"
                            fill="#E5FE4A"
                          />
                        </svg>
                      }
                    >
                {ReactHtmlParser(t("asset.housingSavingsSave"))}
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} md={5} sx={{ pl: { xs: 0, md: 10 }, mt: {md: 0, xs: 6}}}>
            <Box>
              <Box sx={{ mb: 6 }}>
                <Typography variant="body2" sx={{ fontWeight: 700, mb: 0 }}>
                  {ReactHtmlParser(t("asset.housingSavings.documentsTitle"))} {(attachmentFiles.length>0)&&(<>({attachmentFiles.length})</>)}
                </Typography>
                <Typography variant="caption" sx={{ color: "primary.main" }}>
                  
                </Typography>
              </Box>
              <AddFileToAsset onFileUpload={onFileUpload}/>
			  <Box sx={{ mt: 2 }}>
			  <ShowFileOfAsset globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} itemList={attachmentFiles} editMode={editMode}/>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      
	</>	
	))}
	  	
		
	</DefaultLayout>
	</>
  
  );
	
};


export default EditHousingSavings;