import React, { useEffect, useReducer, useState, Suspense } from "react";
import { useForm } from "react-hook-form";

import {
  Box,
  Button,
  Stack,
  TextField,
  InputAdornment,
  Typography,
  IconButton,
  Checkbox,
  Link as MuiLink,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import EyeIcon from "@mui/icons-material/VisibilityOutlined";
import EyeOffIcon from "@mui/icons-material/VisibilityOffOutlined";
import PasswordChecklist from "react-password-checklist";

export const ForgotPasswordStep2 = ({
  globalEvent,
  setGlobalEvent,
  formtype,
}) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    mode: "onBlur",
  });

  const [type, setType] = useState("password");
  const [visibility, setVisibility] = useState(true);

  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const doStoreChangePassword = (event, data) => {
    event.stopPropagation();
    setGlobalEvent({ type: "doStoreChangePassword", data: data });
  };

  const doStoreForgotPassword = (event, data) => {
    event.stopPropagation();
    setGlobalEvent({ type: "doStoreForgotPassword", data: data });
  };

  const onSubmit = (data, e) => {
    if (passwordValid) {
      if (searchParams.get("password-reset-token")) {
        data["passwordResetToken"] = searchParams.get("password-reset-token");
        doStoreForgotPassword(e, data);
      } else {
        doStoreChangePassword(e, data);
      }
    }
    //console.log(data, e);
  };

  const onError = (errors, e) => {
    //console.log(errors, e);
  };

  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [passwordValid, setPasswordValid] = useState(false);

  useEffect(() => {
    if (process.env.REACT_APP_LOG_MESSAGELOG == "on") {
      //console.log("------ Global Event ------ ForgotPasswordStep2");
      //console.log(globalEvent);
      //console.log("------------");
    }

    if (globalEvent?.type == "responseStoreForgotPassword") {
      navigate("/");
      setGlobalEvent({
        type: "showToast",
        data: { message: t("fpwd.chngsuccess"), toastType: "success" },
      });
    }

    if (globalEvent?.type == "responseStoreForgotPasswordError") {
      setGlobalEvent({
        type: "showToast",
        data: { message: t("fpwd.chngerror"), toastType: "error" },
      });
    }
  }, [globalEvent]);

  return (
    <Box
      sx={{
        mt: 16,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        px: { xs: 2, sm: 0 },
      }}
    >
      <Box>
        <Typography
          variant="h6"
          sx={{
            mb: 4,
          }}
        >
          {ReactHtmlParser(t(formtype === "change" ? "changePassword.title" : "fpwd.title"))}
        </Typography>
        <Typography variant="body3">
          {ReactHtmlParser(t(formtype === "change" ? "changePassword.chngtext" : "fpwd.chngtext"))}
        </Typography>
        <Box component="form" onSubmit={handleSubmit(onSubmit, onError)}>
          {formtype === "change" && (
            <>
              <TextField
                key="old_password"
                className="fullwidth-field"
                label={ReactHtmlParser(t("field.old_password"))}
                type={type}
                {...register("old_password", {
                  required: t("errors.field.required"),
                  validate: (val) => {},
                })}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={(e) => {
                          setType(visibility ? "text" : "password");
                          setVisibility(!visibility);
                        }}
                        sx={{ position: "relative", top: "-5px" }}
                      >
                        {visibility ? <EyeIcon /> : <EyeOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {errors.old_password && (
                <span role="alert">{errors.old_password.message}</span>
              )}
            </>
          )}

          <TextField
            key="password"
            className="fullwidth-field"
            label={ReactHtmlParser(t("field.password"))}
            type={type}
            {...register("password", {
              required: t("errors.field.required"),
            })}
            InputProps={{
              onChange: (e) => {
                setPassword(e.target.value);
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={(e) => {
                      setType(visibility ? "text" : "password");
                      setVisibility(!visibility);
                    }}
                    sx={{ position: "relative", top: "-5px" }}
                  >
                    {visibility ? <EyeIcon /> : <EyeOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {errors.password && (
            <span role="alert">{errors.password.message}</span>
          )}

          <TextField
            key="confirm_password"
            className="fullwidth-field"
            label={ReactHtmlParser(t("field.confirm_password"))}
            type={type}
            {...register("confirm_password", {
              required: t("errors.field.required"),
              validate: (val) => {
                //if (watch("password") != val) {
                //return t("error.password.match");
                //}
              },
            })}
            InputProps={{
              onChange: (e) => {
                setPasswordAgain(e.target.value);
              },

              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={(e) => {
                      setType(visibility ? "text" : "password");
                      setVisibility(!visibility);
                    }}
                    sx={{ position: "relative", top: "-5px" }}
                  >
                    {visibility ? <EyeIcon /> : <EyeOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {errors.confirm_password && (
            <span role="alert">{errors.confirm_password.message}</span>
          )}

          <PasswordChecklist
            rules={["minLength", "specialChar", "number", "capital", "match"]}
            minLength={12}
            value={password}
            valueAgain={passwordAgain}
            messages={{
              match: t("error.password.match"),
              minLength: t("error.password.minLength"),
              specialChar: t("error.password.specialChar"),
              number: t("error.password.number"),
              capital: t("error.password.capital"),
            }}
            onChange={(isValid) => {
              setPasswordValid(isValid);
            }}
          />

          <Button
            type="submit"
            variant="contained"
            className="fullwidth-nextbtn"
            endIcon={
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.7929 13H8C7.72386 13 7.5 12.7761 7.5 12.5C7.5 12.2239 7.72386 12 8 12H17.7929L14.6464 8.85355C14.4512 8.65829 14.4512 8.34171 14.6464 8.14645C14.8417 7.95118 15.1583 7.95118 15.3536 8.14645L19.3536 12.1464C19.5488 12.3417 19.5488 12.6583 19.3536 12.8536L15.3536 16.8536C15.1583 17.0488 14.8417 17.0488 14.6464 16.8536C14.4512 16.6583 14.4512 16.3417 14.6464 16.1464L17.7929 13Z"
                  fill="#E5FE4A"
                />
              </svg>
            }
          >
            {ReactHtmlParser(t("fpwd.buttondosave"))}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
