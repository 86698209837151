import React, { useEffect, useState } from "react";

const DateFormatter = ({ timeStamp }) => {
    const [formattedDate, setFormattedDate] = useState(null);

  useEffect(() => {
    const dateStr = timeStamp;
    const date = new Date(dateStr);

    const formattedDate = `${date.getFullYear()}.${(date.getMonth() + 1).toString().padStart(2, '0')}.${date.getDate().toString().padStart(2, '0')}`;
    setFormattedDate(formattedDate);

  }, [timeStamp]);


  return (
    formattedDate
  );
};

export default DateFormatter;
