import { createTheme } from "@mui/material";
import CheckBoxIcons from "../Commons/Icons/CheckBoxIcons";

export const CustomTheme = createTheme({
  typography: {
    h2: {
      fontFamily: "Sora, sans-serif",
    },
    body3: {
      fontSize: "18px",
      fontFamily: "Sora, sans-serif",
      fontWeight: 400,
      color: "#2B3674",
      lineHeight: "22.68px",
    },
    body4: {
      fontSize: "14px",
      fontFamily: "Sora, sans-serif",
      fontWeight: 400,
      color: "#2B3674",
      lineHeight: "17.64px",
    },
    body1bold: {
      fontSize: "18px",
      fontFamily: "Sora, sans-serif",
      fontWeight: 700,
      color: "#2B3674",
      lineHeight: "22.68px",
    },
    body2regular: {
      fontSize: "16px",
      fontFamily: "Sora, sans-serif",
      fontWeight: 400,
      color: "#2B3674",
      lineHeight: "20.16px",
    },
  },
  palette: {
    primary: {
      main: "#2B3674",
      light: "#1542B9",
      dark: "#011638",
    },
    secondary: {
      main: "#FFF27E",
      light: "#E5FE4A",
    },
    info: {
      main: "#E9CCD0",
      secondary: "#EB8176",
    },
    socialLink: {
      main: "#62C275",
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          lineHeight: "32px",
          fontSize: "16px",
          fontWeight: 600,
          fontFamily: "Sora, sans-serif",
          "@media (max-width: 900px)": {
            width: "100%",
            maxWidth: "90vw",
          },
        },
        contained: {
          borderRadius: "64px",
          backgroundColor: "#1542B9",
          textTransform: "none",
          lineHeight: "20px",
          fontSize: "16px",
          fontWeight: 600,
          fontFamily: "Sora, sans-serif",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
          "&.fullwidth-nextbtn": {
            marginTop: 40,
            paddingTop: 10,
            paddingBottom: 10,
            backgroundColor: "#1542B9",
            lineHeight: "32px",
            width: "100%",
          },
          "&.fullwidth-btn": {
            paddingTop: 10,
            paddingBottom: 10,
            backgroundColor: "#1542B9",
            lineHeight: "32px",
            width: "100%",
          },
          "&.fullwidth-menuBtn": {
            marginTop: 15,
            paddingTop: 10,
            paddingBottom: 10,
            backgroundColor: "#1542B9",
            lineHeight: "32px",
            width: "100%",
          },
          "&.small-btn": {
            paddingTop: 10,
            paddingBottom: 10,
            paddingRight: 32,
            paddingLeft: 32,
            backgroundColor: "#1542B9",
            lineHeight: "32px",
          },
          "&.asset-categories-btn": {
            height: "42px",
            backgroundColor: "#F8F8F8",
            color: "#2B3674",
            borderRadius: "32px",
            lineHeight: "17.64px",
            fontSize: "14px",
            fontWeight: 700,
          },
          "&.asset-categories-selected-btn": {
            backgroundColor: "#2B3674",
            color: "#FFFFFF",
          },
          "&.main-blue-btn": {
            backgroundColor: "#2B3674",
            "&:hover": {
              backgroundColor: "#194BD7",
            },
            "&:focus": {
              backgroundColor: "#255BF2",
            },
            "&:disabled": {
              backgroundColor: "#C6C6C6",
              color: "white",
            },
          },
          "&.secondary-blue-btn": {
            paddingTop: "10px",
            paddingBottom: "10px",
            paddingRight: "32px",
            paddingLeft: "32px",
            backgroundColor: "#1542B9",
            "&:hover": {
              backgroundColor: "#194BD7",
            },
            "&:focus": {
              backgroundColor: "#3A6DFF",
            },
            "&:disabled": {
              backgroundColor: "#C6C6C6",
              color: "white",
            },
            "&:disabled .MuiButton-endIcon": {
              stroke: "white",
            },
          },
          "&.white-btn": {
            backgroundColor: "#FFFFFF",
            border: "2px solid #2B36741A",
            color: "#2B3674",
            "&:hover": {
              backgroundColor: "#F8F8F8",
            },
            "&:focus": {
              backgroundColor: "#78EF90",
            },
            "&:disabled": {
              backgroundColor: "#C6C6C6",
            },
          },
          "&.green-btn": {
            backgroundColor: "#62C275",
            "&:hover": {
              backgroundColor: "#6FD884",
            },
            "&:focus": {
              backgroundColor: "#78EF90",
            },
            "&:disabled": {
              backgroundColor: "#C6C6C6",
            },
          },
          "&.yellow-btn": {
            backgroundColor: "#62C275",
            "&:hover": {
              backgroundColor: "#6FD884",
            },
            "&:focus": {
              backgroundColor: "#78EF90",
            },
            "&:disabled": {
              backgroundColor: "#C6C6C6",
            },
          },
        },
        outlined: {
          borderWidth: "1px",
          borderRadius: "64px",
          backgroundColor: "#FFFFFF",
          textTransform: "none",
          lineHeight: "20px",
          fontSize: "16px",
          fontWeight: 600,
          fontFamily: "Sora, sans-serif",
          "&.small-btn": {
            paddingTop: 10,
            paddingBottom: 10,
            paddingRight: 32,
            paddingLeft: 32,
            height: "52px",
            lineHeight: "32px",
          },
          "&.outlined-btn": {
            color: "#2B3674",
            borderWidth: "1px",
            borderColor: "#2B36741A",
            borderRadius: "64px",
            backgroundColor: "#FFFFFF",
            "&:hover": {
              color: "#194BD7",
              borderWidth: "1px",
              borderColor: "#2B36741A",
              borderRadius: "64px",
              backgroundColor: "#FFFFFF",
            },
            "&:focus": {
              color: "#255BF2",
              borderColor: "#2B36741A",
              borderRadius: "64px",
              backgroundColor: "#FFFFFF",
            },
            "&:disabled": {
              color: "#C6C6C6",
              borderColor: "#2B36741A",
              borderRadius: "64px",
              backgroundColor: "#F8F8F8",
            },
            "& .MuiButton-endIcon": {
              "&:hover": {
                color: "#194BD7",
              },
              "&:focus": {
                color: "#255BF2",
              },
              "&:disabled": {
                color: "#C6C6C6",
              },
            },
          },
        },
        text: {
          "&:hover": { backgroundColor: "#FFFFFF", color: "#1542B9" },
          "&.main-blue-text-btn": {
            color: "#2B3674",
            "&:hover": {
              color: "#194BD7",
            },
            "&:focus": {
              color: "#255BF2",
            },
            "&:disabled": {
              color: "#C6C6C6",
            },
          },
          "&.secondary-blue-text-btn": {
            color: "#1542B9",
            "&:hover": {
              color: "#194BD7",
            },
            "&:focus": {
              color: "#3A6DFF",
            },
            "&:disabled": {
              color: "#C6C6C6",
            },
          },
          "&.secondary-blue-text-btn & .MuiButton-startIcon": {
            color: "#1542B9",
            "&:hover": {
              color: "#194BD7",
            },
            "&:focus": {
              color: "#3A6DFF",
            },
            "&:disabled": {
              color: "#C6C6C6",
            },
          },
          "&.green-text-btn": {
            color: "#62C275",
            "&:hover": {
              color: "#6FD884",
            },
            "&:focus": {
              color: "#78EF90",
            },
            "&:disabled": {
              color: "#C6C6C6",
            },
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body3: "p",
          body4: "p",
        },
      },
      styleOverrides: {
        h5: {
          fontWeight: 700,
          fontSize: "40px",
          lineHeight: "56px",
          "@media (max-width:900px)": {
            fontSize: "30px",
            lineHeight: "37.8px",
          },

          color: "#2B3674",
          fontFamily: "Sora, sans-serif",
        },
        h6: {
          fontWeight: 700,
          fontSize: "32px",
          lineHeight: "40.32px",
          "@media (max-width:900px)": {
            fontSize: 24,
            lineHeight: "32px",
          },

          color: "#2B3674",
          fontFamily: "Sora, sans-serif",
        },
        body1: {
          fontSize: "24px",
          lineHeight: "32px",
          fontFamily: "Sora, sans-serif",
          fontWeight: 400,
          color: "#2B3674",
          "@media (max-width:900px)": {
            fontSize: 18,
            lineHeight: "22.68px",
          },
        },
        body2: {
          fontSize: "16px",
          fontFamily: "Sora, sans-serif",
          fontWeight: 400,
          color: "#2B3674",
          lineHeight: "20px",
        },
        subtitle1: {
          fontSize: "20px",
          lineHeight: "30px",
          fontFamily: "Sora, sans-serif",
          fontWeight: 400,
          color: "#2B3674",
          "@media (max-width:900px)": {
            fontSize: 16,
            lineHeight: "20.16px",
          },
        },
        subtitle2: {
          fontSize: "18px",
          fontFamily: "Sora, sans-serif",
          fontWeight: 400,
          color: "#2B3674",
          lineHeight: "22.68px",
        },
        caption: {
          fontSize: "12px",
          fontFamily: "Sora, sans-serif",
          fontWeight: 400,
          color: "#BBBBBB",
          lineHeight: "15.12px",
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: { notched: false },
      styleOverrides: {
        inputRoot: {
          paddingLeft: "28px",
          paddingTop: "18px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          color: "#000000",
          fontWeight: 600,
          fontFamily: "Sora, sans-serif",
          fontSize: "16px",
          lineHeight: "20.16px",

          "&.fullwidth-field": {
            marginTop: 40,
            width: "100%",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "20.16px",
            "& .MuiInputBase-root": { borderRadius: "50px" },
            "& fieldset": {},
          },
          "&.fullwidth-textarea": {
            marginTop: 40,
            width: "100%",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "20.16px",
            "& .MuiInputBase-root": { borderRadius: "24px" },
            "& fieldset": {},
          },
          "&.asset-field-fullwidth": {
            width: "100%",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "20.16px",
            "& .MuiInputBase-root": { borderRadius: "50px" },
            "& fieldset": {},
          },
          "&.asset-field-small": {
            maxWidth: "220px",
            minWidth: "100px",
            "& .MuiInputBase-root": { borderRadius: "50px" },
            border: "1px",
          },
          "&.asset-field-large": {
            width: "430px",
            "& .MuiInputBase-root": { borderRadius: "50px" },
            border: "1px",
            "@media (max-width:900px)": {
              width: "220px",
            },
          },
          "&.asset-field-date": {
            "& .MuiInputLabel-root": {
              top: 23,
              backgroundColor: "transparent",
              transform: "translate(14px, -9px) scale(0.75)",
            },
          },
          "&.asset-field-currency": {
            width: "120px",
            "& .MuiInputBase-root": { borderRadius: "50px" },
            border: "1px",
            ".MuiOutlinedInput-notchedOutline": {
              borderRadius: "50px",
            },
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: "Sora, sans-serif",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          left: 20,
          top: 5,
          "&.Mui-focused": {
            top: 23,
            backgroundColor: "transparent",
          },
          "&.MuiFormLabel-filled": {
            top: 23,
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: { notched: false },
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {},
          },
        },
      },
    },
    MuiInput: {
      defaultProps: { notched: false },
    },
    MuiInputBase: {
      defaultProps: { notched: false },
      styleOverrides: {
        root: {
          color: "primary.main",
          fontWeight: 600,
          fontFamily: "Sora, sans-serif",
          fontSize: "14px",
          lineHeight: "22px",
          paddingLeft: 20,
          borderRadius: "50px",
          paddingTop: "12px",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: "#2B3674",
          fontWeight: 600,
          fontFamily: "Sora, sans-serif",
          fontSize: "14px",
          lineHeight: "22px",
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          color: "#2B3674",
          fontWeight: 500,
          fontFamily: "Sora, sans-serif",
          fontSize: "16px",
          lineHeight: "36px",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#C3F0FD",
          fontWeight: 600,
          fontFamily: "Sora, sans-serif",
          fontSize: "16px",
          lineHeight: "32px",
          "&.green-text-link": {
            color: "#62C275",
            "&:hover": {
              color: "#6FD884",
            },
            "&:focus": {
              color: "#78EF90",
            },
            "&:disabled": {
              color: "#C6C6C6",
            },
          },
          "&.secondary-blue-text-link": {
            color: "#1542B9",
            "&:hover": {
              color: "#194BD7",
            },
            "&:focus": {
              color: "#3A6DFF",
            },
            "&:disabled": {
              color: "#C6C6C6",
            },
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          "& .PrivateSwitchBase-input": { borderRadius: "8px" },
        },
      },
      defaultProps: {
        icon: <CheckBoxIcons checked={false} />,
        checkedIcon: <CheckBoxIcons checked={true} />,
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          "&.add-asset-form-card": {
            borderRadius: "16px",
            backgroundColor: "#FFFFFF",
            boxShadow: "0px 0px 16px 0px #0000001A",
          },
        },
      },
    },
  },
});
