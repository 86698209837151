import { Box, Grid, Button } from "@mui/material";
import { LoginTopMenu } from "../Navigation/LoginNavigation/LoginTopMenu";
import registrationbg from "../Assets/Registration-bg.png";
import { RegStep1 } from "./RegStep1";
import { RegStep2 } from "./RegStep2";
import { ValidationStep1 } from "../ValidationComponents/ValidationStep1";
import { RegStep4 } from "./RegStep4";
import { ValidationStep2 } from "../ValidationComponents/ValidationStep2";
import { Footer } from "../Footer/Footer";

import React, { useEffect, useReducer, useState, Suspense } from "react";

import { useTranslation } from "react-i18next";

import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import AuthLayout from "../Layout/AuthLayout";

const Registration = ({ globalEvent, setGlobalEvent, title, cStep }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [tokenInvited, setTokenInvited] = useState("");

  const { t, i18n } = useTranslation();

  const mql = window.matchMedia("(max-width: 600px)");

  //tokenReferral
  //tokenInvited
  //consentToHandling
  //consentToMarketing

  useEffect(() => {
    if (process.env.REACT_APP_LOG_MESSAGELOG == "on") {
      //console.log("------ Global Event ------ Registration");
      //console.log(globalEvent);
      //console.log("------------");
    }

    if (globalEvent?.type == "showRegStep1") {
      navigate("/registration/");
    }

    if (globalEvent?.type == "showRegStep2") {
      setStep(2);
      navigate("/registration/email");
    }

    if (globalEvent?.type == "showRegStep3") {
      navigate("/registration/verify", { replace: true });
    }

    if (globalEvent?.type == "showRegStep4") {
      navigate("/registration/consent", { replace: true });
    }

    if (globalEvent?.type == "responseRegisterError") {
      setStep(2);
      navigate("/registration/email");
      if (globalEvent?.data)
	    if (globalEvent?.data.errors){
			if (globalEvent?.data.errors[0].message){
				if (globalEvent?.data.errors[0].message == 'invalid_payload' || globalEvent?.data.errors[0].message == 'coupon_max_redemptions_reached'){
					setGlobalEvent({
						type: "showToast",
						data: {
							message: t(`errorResponse.invalid_promocode`), toastType: 'error'
						}
					});
				} else {
					setGlobalEvent({ type: "showRegisterError", data: globalEvent?.data });							
				}
			} else {
			  setGlobalEvent({ type: "showRegisterError", data: globalEvent?.data });						
			}
		} else {
		  setGlobalEvent({ type: "showRegisterError", data: globalEvent?.data });		
		}
	  
	  
    }
  }, [globalEvent]);

  useEffect(() => {
    setStep(cStep);
  }, [cStep]);

  useEffect(() => {
    if (window.location.pathname == "/registration") {
      setGlobalEvent({
        type: "setConsentToMarketing",
        data: { consentToMarketing: false },
      });
      //setGlobalEvent({ type: "setPromoCode", data: {promoCode:null}});
      if (searchParams.get("invitationToken"))
        setGlobalEvent({
          type: "setInvitedToken",
          data: { invitedToken: searchParams.get("invitationToken") },
        });
      if (searchParams.get("referralToken"))
        setGlobalEvent({
          type: "setReferralToken",
          data: { referralToken: searchParams.get("referralToken") },  
        });
    }
  }, [searchParams]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);


  const [step, setStep] = useState(cStep);

  return (
    <>
      <AuthLayout
        backgroundImage={registrationbg}
        globalEvent={globalEvent}
        setGlobalEvent={setGlobalEvent}
      >
        <div className="login-container">
          {step === 1 ? (
            <RegStep1
              globalEvent={globalEvent}
              setGlobalEvent={setGlobalEvent}
            />
          ) : step === 2 ? (
            <RegStep2
              globalEvent={globalEvent}
              setGlobalEvent={setGlobalEvent}
            />
          ) : step === 3 ? (
            <ValidationStep1
              globalEvent={globalEvent}
              setGlobalEvent={setGlobalEvent}
            />
          ) : step === 4 ? (
            <RegStep4
              globalEvent={globalEvent}
              setGlobalEvent={setGlobalEvent}
            />
          ) : (
            <ValidationStep2
              globalEvent={globalEvent}
              setGlobalEvent={setGlobalEvent}
              title="A regisztráció sikeres!"
              text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et"
			  link="/"
            />
          )}
        </div>
      </AuthLayout>
    </>
  );
};

export default Registration;
