import { fetchWithAuth } from "../api";

export const fetchDiaryEntries = () => {
  return async (dispatch) => {
    dispatch({ type: "FETCH_DIARY_ENTRIES_REQUEST" });
    try {
      const data = await fetchWithAuth("diary-items");
      dispatch({
        type: "FETCH_DIARY_ENTRIES_SUCCESS",
        payload: data.postMortemRequests,
      });
    } catch (error) {
      dispatch({ type: "FETCH_DIARY_ENTRIES_FAILURE", payload: error });
    }
  };
};

export const fetchDiaryImages = () => {
  return async (dispatch) => {
    dispatch({ type: "FETCH_DIARY_IMAGES_REQUEST" });
    try {
      const data = await fetchWithAuth("attachments/diary-items");
      dispatch({
        type: "FETCH_DIARY_IMAGES_SUCCESS",
        payload: data.attachments,
      });
    } catch (error) {
      dispatch({ type: "FETCH_DIARY_IMAGES_FAILURE", payload: error });
    }
  };
}


export const saveEntry = (entryId, entryData) => {
  return async (dispatch) => {
    try {
      await fetchWithAuth(
        entryId ? "diary-items/" + entryId : "diary-items",
        {
          method: entryId ? "PATCH" : "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            postMortemRequest: entryData,
          }),
        }
      );
      dispatch({ type: "SAVE_DIARY_ENTRY_SUCCESS" });
      dispatch(fetchDiaryEntries());
    } catch (error) {
      dispatch({ type: "SAVE_DIARY_ENTRY_FAILURE", payload: error });
    }
  };
};

export const deleteEntry = (entryId) => {
  return async(dispatch) => {
    try {
      await fetchWithAuth("diary-items/" + entryId, {
        method: 'DELETE',
        headers: { "Content-Type": "application/json" }
      });

      dispatch({ type: "DELETE_DIARY_ENTRY", payload: entryId });
    } catch(error) {
      //console.log('errror', error);
    }
  }
}
