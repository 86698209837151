import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  TextField,
  Link,
  Button,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/EditOutlined";
import ReactHtmlParser from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../../store/Actions/userAction";

export const LocaleCard = ({ globalEvent, setGlobalEvent }) => {
  const [editBtn, setEditBtn] = useState(true);
  const { t, i18n } = useTranslation();
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const handleLanguageChange = (event) => {
    setEditBtn(!editBtn);
    i18n.changeLanguage(event.target.value, (err, t) => {
      if (err) return; 
    });
    setGlobalEvent({
      type: "doSaveLocale",
      data: { user: { locale: event.target.value.toLowerCase() } },
    });

    localStorage.setItem('locale', event.target.value.toLowerCase());

    setTimeout(() => {
      dispatch(getCurrentUser());
    }, 1000)
  };

  return (
    <Card
      sx={{
        borderRadius: "16px",
        px: 2,
        pt: { xs: 1, sm: 2 },
        mt: 2,
        boxShadow: "4px 4px 20px 0px #0000001A",
      }}
    >
      <CardContent>
        <Grid container>
          <Grid item xs={6} sm={8}>
            <Box>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 700,
                }}
              >
                {t("profile.locale.title")}
              </Typography>

              <Box sx={{}}>
                <Typography variant="body2">
                  {t("profile.locale.description")}
                </Typography>
              </Box>
              <Typography
                variant="body2"
                sx={{ textTransform: "uppercase", fontWeight: 700 }}
              >
                {user?.locale}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            sm={4}
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            {editBtn ? (
              <Button
                onClick={() => {
                  setEditBtn(!editBtn);
                }}
                variant="text"
                sx={{
                  minWidth: "50px",
                  width: "50px !important",
                  height: "50px",
                  p: 0,
                  my: 0.3,
                  boxShadow: "0px 0px 10px 0.2px #0000001A",
                  borderRadius: "32px",
                }}
              >
                <EditIcon />
              </Button>
            ) : (
              <TextField
                select
                className="asset-field-currency"
                value={user?.locale}
                onChange={handleLanguageChange}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12ZM14.665 8C14.1428 5.03217 13.0819 3 12 3C10.9181 3 9.85717 5.03217 9.33498 8H14.665ZM3.93552 8H8.31375C8.65216 5.96286 9.24889 4.27723 10.028 3.21671C7.35208 3.81488 5.1259 5.60466 3.93552 8ZM8.1719 15H3.51212C3.18046 14.0617 3 13.0519 3 12C3 10.9481 3.18046 9.93834 3.51212 9H8.1719C8.05949 9.94765 8 10.9555 8 12C8 13.0445 8.05949 14.0524 8.1719 15ZM10.028 20.7833C7.35208 20.1851 5.1259 18.3953 3.93552 16H8.31375C8.65216 18.0371 9.24889 19.7228 10.028 20.7833ZM20.4879 15C20.8195 14.0617 21 13.0519 21 12C21 10.9481 20.8195 9.93834 20.4879 9H15.8281C15.9405 9.94765 16 10.9555 16 12C16 13.0445 15.9405 14.0524 15.8281 15H20.4879ZM15.6862 16H20.0645C18.8741 18.3953 16.6479 20.1851 13.972 20.7833C14.7511 19.7228 15.3478 18.0371 15.6862 16ZM9.1844 15H14.8156C14.934 14.0624 15 13.0528 15 12C15 10.9472 14.934 9.93761 14.8156 9H9.1844C9.06598 9.93761 9 10.9472 9 12C9 13.0528 9.06598 14.0624 9.1844 15ZM12 21C10.9181 21 9.85717 18.9678 9.33498 16H14.665C14.1428 18.9678 13.0819 21 12 21ZM13.972 3.21671C16.6479 3.81488 18.8741 5.60466 20.0645 8H15.6862C15.3478 5.96286 14.7511 4.27723 13.972 3.21671Z"
                          fill="#2B3674"
                        />
                      </svg>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  borderRadius: "32px",
                  "&.MuiTextField-root .MuiInputBase-root": {
                    paddingTop: "0px!important",
                  },
                  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                  "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                    {
                      border: 0,
                    },
                }}
              >
                <MenuItem value="hu">HU</MenuItem>
                <MenuItem value="en">EN</MenuItem>
              </TextField>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default LocaleCard;
