import { Box, Grid, Button } from "@mui/material";
import { useState } from "react";
import { LoginTopMenu } from "../Navigation/LoginNavigation/LoginTopMenu";
import addbg from "../Assets/add-user-data-bg.png";
import { Footer } from "../Footer/Footer";
import { Outlet, useLocation } from "react-router-dom";

export const AddUserData = ({ globalEvent, setGlobalEvent, title }) => {
  const mql = window.matchMedia("(max-width: 600px)");
  let mobileView = mql.matches;

  const location = useLocation();
  const isStep2 = location.pathname.includes("step2");

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Grid container sx={{}}>
        <Grid item xs={12} md={6} sx={{ width: "50%" }}>
          <LoginTopMenu backLink={isStep2 ? '/add-user-data' : null} />
          <div className="login-container">
            <Box
              sx={{
                mt: { xs: 0, sm: 16 },
              }}
            >
              <Outlet />
            </Box>
          </div>
        </Grid>
        <Grid item xs={12} md={6} sx={{ width: "50%" }}>
          {!mobileView ? (
            <Box
              component="img"
              alt="background"
              src={addbg}
              sx={{
                top: 0,
                width: "100%",
                height: "100%",
              }}
            ></Box>
          ) : (
            <Footer />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};
