import { Typography, Box } from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import StripePaymentForm from "./StripePaymentForm";
import { useSelector } from "react-redux";
import { t } from "i18next";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import i18n from "../../i18n";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || 'pk_test_51P9VVtAf'
);

const PaymentForm = () => {
  const navigate = useNavigate();

  const clientSecret = useSelector(
    (state) => state.payment.paymentIntentSecret
  );
  const options = {
    clientSecret,
    placeholder: "",
    locale: i18n.language || "hu",
  };

  const {plan: selectedPlanName, amount: originalPlanPrice, discountedPrice} = useSelector((state) => state.payment);
  const plans = useSelector((state) => state.plan.plans);
  const selectedPlanDetails = plans.find(
    (plan) => plan.name === selectedPlanName
  );

  useEffect(() => {
    if (clientSecret == null && selectedPlanName == null) {
      navigate("/profile");
    }

    if (clientSecret == null) {
      navigate("/payment");
    }
  }, []);

  return (
    <Box sx={{ mt: 1 }}>
      <Typography component="h1" variant="h5">
        {t("payment.stripe.title")}
      </Typography>
      {selectedPlanDetails && (
        <>
          <Typography variant="body3" sx={discountedPrice && originalPlanPrice !== discountedPrice ? { textDecoration: "line-through" } : {}}>
            {`${selectedPlanDetails.name} (${originalPlanPrice} Ft / ${t(`billingCycle.${selectedPlanDetails.billingCycle}`)})`}
          </Typography>

          {originalPlanPrice !== discountedPrice && discountedPrice > 0 && (
            <Typography variant="body3" sx={{ fontWeight: 700 }}>
              {t("payment.stripe.discountedPrice")} {discountedPrice}{" "} {t("payment.stripe.currency")}
            </Typography>
          )}
          
        </>
      )}
      <Elements stripe={stripePromise} options={options}>
        <StripePaymentForm />
      </Elements>
    </Box>
  );
};

export default PaymentForm;
