import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useRef, useState, useEffect } from "react";
import { getIconComponent } from "../../store/Actions/assetAction";

const NewAssetSelector = ({ globalEvent, setGlobalEvent }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const scrollRef = useRef(null);

  const assetTypes = useSelector((state) => state.asset.assetTypes)
    .filter((row) => row.displayHome === true);
  const categoryVisibility = useSelector((state) => state.asset.categoryVisibility);

  const [showScrollButtons, setShowScrollButtons] = useState(false);
  const [assetType, setAssetType] = useState("");

  // Ellenőrzi, hogy megjelenjenek-e a görgetőgombok
  const checkScrollButtons = () => {
    const scrollElement = scrollRef.current;
    if (scrollElement) {
      const { scrollWidth, clientWidth } = scrollElement;
      setShowScrollButtons(scrollWidth > clientWidth);
    }
  };

  useEffect(() => {
    checkScrollButtons();
    window.addEventListener("resize", checkScrollButtons);
    return () => window.removeEventListener("resize", checkScrollButtons);
  }, []);

  useEffect(() => {
    if (globalEvent?.type === "responseCheckPlan" && globalEvent?.data.link === assetType) {
      navigate(globalEvent?.data.link, {
        state: {
          editmode: true,
          data: { value: 0, currency: "HUF", details: {} },
        },
      });
    }
  }, [globalEvent, assetType, navigate]);

  const handleScroll = (direction) => {
    const { current: element } = scrollRef;
    const scrollAmount = 200;
    const scroll = direction === "left"
      ? element.scrollLeft - scrollAmount
      : element.scrollLeft + scrollAmount;
    element.scrollTo({ left: scroll, behavior: "smooth" });
  };

  const toggleCategoriesVisibility = () => {
    dispatch({
      type: "SET_HOMEPAGE_CATEGORY_VISIBILITY",
      payload: !categoryVisibility,
    });
  };

  const handleCardClick = (asset) => {
    const assetLink = `/add-new-asset/${asset.type}`;
    setAssetType(assetLink);
    setGlobalEvent({
      type: "checkPlan",
      data: {
        type: "asset",
        subtype:
          asset.type === "bank-account" ? "bankAccount"
          : asset.type === "housing-savings" ? "houseSavings"
          : asset.type === "real-estate" ? "realEstate"
          : asset.type,
        link: assetLink,
      },
    });
  };

  return categoryVisibility ? (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="body1" sx={{ fontSize: 18, fontWeight: 700 }}>
            {t("home.newAssetButton")}
          </Typography>
        </Grid>
        {showScrollButtons && (
          <Grid item>
            <ArrowBackIosIcon
              onClick={() => handleScroll("left")}
              sx={{ cursor: "pointer", fill: "#2B3674" }}
              fontSize="small"
            />
            <ArrowForwardIosIcon
              onClick={() => handleScroll("right")}
              sx={{ cursor: "pointer", fill: "#2B3674" }}
              fontSize="small"
            />
          </Grid>
        )}
      </Grid>

      <Stack
        ref={scrollRef}
        direction="row"
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 1.5,
          display: "flex",
          position: "relative",
          overflowX: "scroll",
          boxSizing: "content-box",
          "&::-webkit-scrollbar": {
            width: "100%",
            background: "transparent",
          },
        }}
      >
        {assetTypes.map((asset, index) => {
          const Icon = getIconComponent(asset.icon);
          return (
            <Box key={index} sx={{ minWidth: "160px", cursor: 'pointer' }}>
              <Card
                onClick={() => handleCardClick(asset)}
                sx={{
                  boxShadow: "0px 0px 16px 0px #0000001A",
                  borderRadius: "16px",
                  width: "160px",
                  height: "140px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "2px solid #FFFFFF",
                  "&:hover": { border: "2px solid #62C275" },
                }}
              >
                <CardContent>
                  <Stack
                    direction="column"
                    spacing={1}
                    justifyContent="center"
                    alignItems="center"
                  >
                    {Icon && (
                      <Icon
                        sx={{
                          width: "48px",
                          height: "48px",
                          color: "primary.main",
                          opacity: 0.6,
                        }}
                      />
                    )}
                    <Typography
                      variant="body3"
                      sx={{ fontSize: "14px", fontWeight: 700 }}
                    >
                      {t(`asset-type.${asset.labelCode}`)}
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </Box>
          );
        })}
      </Stack>
    </>
  ) : null;
};

export default NewAssetSelector;
