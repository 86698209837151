import React, { useEffect, useReducer, useState, Suspense } from "react";
import WavingHandIcon from "@mui/icons-material/WavingHandOutlined";
import EyeIcon from "@mui/icons-material/VisibilityOutlined";
import EyeOffIcon from "@mui/icons-material/VisibilityOffOutlined";

import { useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";

import {
  Box,
  Button,
  TextField,
  InputAdornment,
  Typography,
  IconButton,
  Modal,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";
export const DeleteModal = ({ open, handleClick }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
  });

  const navigate = useNavigate();

  const onSubmit = (data, e) => {
    //console.log(data, e);
  };
  const onError = (errors, e) => {
    //console.log(errors, e);
  };

  const [password, setPassword] = useState("");
  const [type, setType] = useState("password");
  const [visibility, setVisibility] = useState(true);

  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 608,
    height: 525,
    borderRadius: "32px",
    bgcolor: "background.paper",
    boxShadow: "4px 4px 20px 0px #0000001A",
    textAlign: "center",
  };
  return (
    <Modal
      open={open}
      onClose={handleClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ p: 6 }}>
          <WavingHandIcon
            sx={{
              width: "120px",
              height: "120px",
              color: "primary.main",
              mb: 2,
            }}
          />
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t("profle.deleteModal.title")}
          </Typography>
          <Typography
            id="modal-modal-description"
            variant="body3"
            sx={{ mt: 2 }}
          >
            {t("profle.deleteModal.text")}
          </Typography>
          <TextField
            key="password"
            className="fullwidth-field"
            label={ReactHtmlParser(t("field.password"))}
            type={type}
            {...register("password", {
              required: t("errors.field.required"),
            })}
            InputProps={{
              endAdornment: (
                <InputAdornment sx={{mt: -1.5}} position="end">
                  <IconButton
                    onClick={(e) => {
                      setType(visibility ? "text" : "password");
                      setVisibility(!visibility);
                    }}
                  >
                    {visibility ? <EyeIcon /> : <EyeOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {errors.password && (
            <span role="alert">{errors.password.message}</span>
          )}
          <Button
            variant="contained"
            sx={{
              lineHeight: "20.16px",
              my: 3,
              py: 2,
              px: 4,
              bgcolor: "#EB8176",
              width: "100%",
            }}
            endIcon={
              <svg
                width="13"
                height="9"
                viewBox="0 0 13 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.7929 5H1C0.723858 5 0.5 4.77614 0.5 4.5C0.5 4.22386 0.723858 4 1 4H10.7929L7.64645 0.853553C7.45118 0.658291 7.45118 0.341709 7.64645 0.146447C7.84171 -0.0488155 8.15829 -0.0488155 8.35355 0.146447L12.3536 4.14645C12.5488 4.34171 12.5488 4.65829 12.3536 4.85355L8.35355 8.85355C8.15829 9.04882 7.84171 9.04882 7.64645 8.85355C7.45118 8.65829 7.45118 8.34171 7.64645 8.14645L10.7929 5Z"
                  fill="#E5FE4A"
                />
              </svg>
            }
          >
            {t("profle.deleteModal.doDelete")}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
