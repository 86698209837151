import React, { useEffect, useReducer, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/CloseRounded';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import ReactHtmlParser from 'html-react-parser'; 
import ConfirmationModal from '../Commons/Modals/ConfirmationModal';
import DialogPlanUpg from './DialogPlanUpg';

import { t } from 'i18next';
import HandGestureIcon from '../Commons/Icons/HandGestureIcon';
import WarningIcon from '../Commons/Icons/WarningIcon';
import MessageModal from '../Commons/Modals/MessageModal';
import ToastModal from '../Commons/Modals/ToastModal';
import { useNavigate } from "react-router-dom";

const PopupHandler = ({globalEvent,setGlobalEvent,title}) => {

  const navigate = useNavigate();

  const [callroot, setCallRoot] = useState('');
  const [calldata, setCallData] = useState('');
  
  const [message, setMessage] = useState('');
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [msgTitle, setMsgTitle] = useState('');
  const [msgDescription, setMsgDescription] = useState('');
  
  const [snackOpen, setSnackOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [messageOpen, setMessageOpen] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);
  const [dialogPUOpen, setDialogPUOpen] = useState(false);
  
  const [vertical, setVertial] = useState('top');
  const [horizontal, setHorizontal] = useState('center');;
  
  const [dialogIcon, setDialogIcon] = useState(<WarningIcon/>);
  const [toastType, setToastType] = useState(null);


const confirmStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  borderRadius: '5px', 
  boxShadow: 24,
  p: 4,
  display: 'flex-column',
  justifyContent: 'center',
  alignItems: 'center'
};

const messageStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  borderRadius: '5px', 
  boxShadow: 24,
  p: 4,
  display: 'flex-column',
  justifyContent: 'center',
  alignItems: 'center'
};


  const handleConfirmOpen = () => setConfirmOpen(true);
  const handleConfirmClose = () => setConfirmOpen(false);
  const handleMessageOpen = () => setMessageOpen(true);
  const handleMessageClose = () => setMessageOpen(false);
  const handleToastOpen = () => {
    setToastOpen(true)
    const timer =  setTimeout(() => {
      setToastOpen(false);
  }, 4000)
   return () => clearTimeout(timer);
  }
  ;
  const handleToastClose = () => setToastOpen(false);

  const handleSnackClose = () => setSnackOpen(false);

  useEffect(() => {
	//if (process.env.REACT_APP_LOG_MESSAGELOG == 'on'){
	//console.log('------ Global Event ------ PopupHandler');
	//console.log(globalEvent);
	//console.log('------------');
	//}
	
	/*if (globalEvent?.type == 'showToast'){
		setMessage(globalEvent?.data?.message);
		setSnackOpen(true);
	}*/
     if (globalEvent?.type == 'showToast'){
		setMessage(globalEvent?.data?.message);
		setCallRoot(globalEvent?.data?.callroot);
		setCallData(globalEvent?.data?.calldata);
		if (globalEvent?.data?.toastType){
		  setToastType(globalEvent?.data?.toastType);
		} else {
		  setToastType(null);
		  setDialogIcon(<WarningIcon/>);			
		}
		
		handleToastOpen();
    }


	if (globalEvent?.type == 'showConfirm'){
		setMsgTitle(globalEvent?.data?.title);
		setMsgDescription(globalEvent?.data?.description);
		setCallRoot(globalEvent?.data?.callroot);
		setCallData(globalEvent?.data?.calldata);
		setDialogIcon(<WarningIcon/>);
		handleConfirmOpen();
	}

	if (globalEvent?.type == 'showPlanUpgrade'){
		setDialogPUOpen(true)
	}






	if (globalEvent?.type == 'showMessage'){
		setMessage(globalEvent?.data?.message);
		setCallRoot(globalEvent?.data?.callroot);
		setCallData(globalEvent?.data?.calldata);
		setDialogIcon(<WarningIcon/>);
		handleMessageOpen();
	}

  }, [globalEvent])
  

return (
	<>
	
		<Snackbar
		ContentProps={{
			sx: {
				backgroundColor: 'primary.main'
			}
		}}
		anchorOrigin={{ vertical, horizontal }}
        open={snackOpen}
        message={message}
		onClose={handleSnackClose}
		key={vertical + horizontal}
		/>


<Grid
  container
  direction="row"
  justifyContent="space-between"
  alignItems="center"
>
    <Grid item></Grid>
    <Grid item></Grid>
</Grid>

	  <DialogPlanUpg
        closeLabel={ReactHtmlParser(t("common.cancel"))}
        upgradeLabel={ReactHtmlParser(t("planupgrade.buttondoupgrade"))}
        dialogTitle={ReactHtmlParser(t("planupgrade.dialogtitle"))}
        dialogText={ReactHtmlParser(t("planupgrade.dialogtext"))}
        isOpen={dialogPUOpen}
        onClose={() => {
          setDialogPUOpen(false);
        }}
        onUpgrade={() => {
          setDialogPUOpen(false);
		  navigate("/subscription");          
        }}
      />

    <ConfirmationModal
      open={confirmOpen}
      title={ReactHtmlParser(msgTitle)}
      description={msgDescription}
      cancelText={t('common.modal.cancel')}
      confirmText={t('common.modal.confirm')}
      confirmIconColor="white"
      onClose={() => {setAnswer(false);setGlobalEvent({type:'confirmAnswered',data:{answer:false,callroot:callroot,calldata:calldata}});handleConfirmClose()}}
      onConfirm={() => {setAnswer(true);setGlobalEvent({type:'confirmAnswered',data:{answer:true,callroot:callroot,calldata:calldata}});handleConfirmClose()}}
      icon={dialogIcon}
    />


    <MessageModal
      open={messageOpen}
      onClose={handleMessageClose}
      title={t('common.modal.message')}
      description={ReactHtmlParser(message)}
      buttonText={t('common.modal.close')}
      onAccept={()=>{setGlobalEvent({type:'messageClosed',data:{callroot:callroot,calldata:calldata}});handleMessageClose()}}
      icon={CheckIcon}
    />


    <ToastModal open={toastOpen}
      onClose={handleToastClose}
      title={ReactHtmlParser(message)}
      description={null}
      success={toastType}
	  icon={toastType?null:dialogIcon}/>
	</>
);
}

export default PopupHandler;