import React, { useEffect, useReducer, useState, Suspense } from "react";
import { useForm } from "react-hook-form";

import {
  Box,
  Button,
  Stack,
  TextField,
  InputAdornment,
  Typography,
  IconButton,
  Checkbox,
  Link as MuiLink,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";
import { useNavigate } from "react-router-dom";

export const ForgotPasswordStep1 = ({
  globalEvent,
  setGlobalEvent,
  formtype,
}) => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm({
    mode: "onBlur",
  });

  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const doForgottenPassword = (event, data) => {
    event.stopPropagation();
    setGlobalEvent({ type: "doForgotPassword", data: data });
  };

  const onSubmit = (data, e) => {
    doForgottenPassword(e, data);
    //console.log(data, e);
  };
  const onError = (errors, e) => {
    //console.log(errors, e);
  };

  useEffect(() => {
    if (process.env.REACT_APP_LOG_MESSAGELOG == "on") {
      //console.log("------ Global Event ------ ForgotPasswordStep1");
      //console.log(globalEvent);
      //console.log("------------");
    }

    if (globalEvent?.type == "responseForgotPasswordGetCode") {
      setGlobalEvent({
        type: "showToast",
        data: { message: t("fpwd.emailsent"), toastType: "success" },
      });
      navigate("/");
    }

    if (globalEvent?.type == "responseForgotPasswordGetCodeError") {
      setGlobalEvent({
        type: "showToast",
        data: { message: t("fpwd.emailerror"), toastType: "error" },
      });
    }
  }, [globalEvent]);

  return (
    <Box
      sx={{
        mt: 16,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        px: { xs: 2, sm: 0 },
      }}
    >
      <Box component="form" onSubmit={handleSubmit(onSubmit, onError)}>
        <Typography
          variant="h6"
          sx={{
            mb: 4,
          }}
        >
          {ReactHtmlParser(t("fpwd.title"))}
        </Typography>
        <Typography variant="body3" display={{ xs: "none", sm: "block" }}>
          {ReactHtmlParser(t("fpwd.text"))}
        </Typography>
        <Box>
          <TextField
            key="email"
            label="email" //{ReactHtmlParser(t("field.email"))}
            type="email"
            className="fullwidth-field"
            {...register("email", {
              required: ReactHtmlParser(t("errors.field.required")),
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Entered value does not match email format",
              },
            })}
          />
          <Button
            type="submit"
            variant="contained"
            className="fullwidth-nextbtn"
            sx={{ minWidth: "100%!important" }}
            endIcon={
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.7929 13H8C7.72386 13 7.5 12.7761 7.5 12.5C7.5 12.2239 7.72386 12 8 12H17.7929L14.6464 8.85355C14.4512 8.65829 14.4512 8.34171 14.6464 8.14645C14.8417 7.95118 15.1583 7.95118 15.3536 8.14645L19.3536 12.1464C19.5488 12.3417 19.5488 12.6583 19.3536 12.8536L15.3536 16.8536C15.1583 17.0488 14.8417 17.0488 14.6464 16.8536C14.4512 16.6583 14.4512 16.3417 14.6464 16.1464L17.7929 13Z"
                  fill="#E5FE4A"
                />
              </svg>
            }
          >
            {ReactHtmlParser(t("fpwd.buttondofpwd"))}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
