import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchWithLocale } from '../api';

export const fetchContentBySlug = createAsyncThunk(
  'content/fetchContentBySlug',
  async (slug, { rejectWithValue, dispatch }) => {
    try {
      return await fetchWithLocale(`static-page/${slug}`);
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const contentSlice = createSlice({
  name: 'content',
  initialState: {
    content: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchContentBySlug.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchContentBySlug.fulfilled, (state, action) => {
        state.loading = false;
        state.content = action.payload;
      })
      .addCase(fetchContentBySlug.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default contentSlice.reducer;
