import { React, useEffect, useReducer, useState }  from "react";
import { DesktopTopMenu } from "../Navigation/HomeNavigation/DesktopTopMenu";
import { MobileTopMenu } from "../Navigation/HomeNavigation/MobileTopMenu";
import mainBackground from "../Assets/home-welcome-bg.png";
import mobileBackground from "../Assets/daniel-j-schwarz-YtY724tdl7Y-unsplash 1.png";
import { Box, Button, Typography, Stack } from "@mui/material";
import { HowItWorksComponent } from "./HowItWorks/HowItWorksComponent";
import { FunctionsComponent } from "./Functions/FunctionsComponent";
import { WhoIsItFor } from "./WhoIsItFor/WhoIsitForComponent";
import { PricesComponent } from "./Prices/PricesComponent";
import { FAQComponent } from "./FAQ/FAQComponent";
import { Footer } from "../Footer/Footer";
import { useNavigate } from "react-router-dom";

import { useTranslation } from 'react-i18next';

import ReactHtmlParser from 'html-react-parser'; 
import SystemMessages from "../Commons/SystemMessages";
import { scrollToSection } from "../../store/helper";



const HomeComponent = ({globalEvent,setGlobalEvent,title}) => {
  const navigate = useNavigate();
  const [mobileView, setMobileView] = useState(
    window.matchMedia("(max-width: 900px)").matches
  );

  useEffect(() => {
    const handleResize = () => {
      const mql = window.matchMedia("(max-width: 900px)");
      setMobileView(mql.matches);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  	
  const { t, i18n } = useTranslation();	

  const registrationPage = () => {
    navigate("/registration");
  };

  useEffect(() => {
	
	//console.log('------ Global Event ------ HomePage');
	//console.log(globalEvent);
	//console.log('------------');

  }, [globalEvent])



  return (
    <>
      <SystemMessages type="home" />
      {!mobileView ? (
        <>
          <DesktopTopMenu globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} />
          <Box
            component="img"
            alt="background"
            src={mainBackground}
            sx={{
              position: "absolute",
              right: 0,
              top: 50,
              zIndex: 0,
              overflow: "hidden",
            }}
          ></Box>
        </>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <MobileTopMenu  globalEvent={globalEvent} setGlobalEvent={setGlobalEvent} />
          <Box sx={{
            width:"100%",
            position: "absolute",
            top: 0,
            minHeight: "560px",
            
          }}>
            <Box
            sx={{
              bgcolor: "primary.main",
              mixBlendMode: "soft-light",
              position: "absolute",
              top: 0,
              zIndex: 1,
              width: "100%",
              height: "100%",
            }}
            ></Box>
            <Box
              component="img"
              alt="background"
              src={mobileBackground}
              sx={{
                position: "absolute",
                top: 0,
                zIndex: 0,
                width: "100%",
                height: 1,
              }}
            ></Box>
            <Box
            sx={{
              background: "linear-gradient(#D9D9D900, #FFFFFF)",
              position: "absolute",
              bottom: 0,
              zIndex: 1,
              width: "100%",
              minHeight: "300px",
            }}
            ></Box>    
          </Box>  
      </Box>
      )}
      <div className="my-container">
        <Box>
          <Box
            sx={{
              position: "relative",
              mt: 16,
              mb: 3,
              zIndex: 1,
            }}
          >
            <Box
              sx={{
                textAlign: { xs: "center", md: "left" },
                px: {md: 10},
              }}
            >
              <Typography
                variant={"h5"}
              >
                {ReactHtmlParser (t('home.title'))}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  py: 3,
                  maxWidth: { xs: "auto", md: "557px" },
                }}
              >
                {ReactHtmlParser (t('home.subtitle'))}
              </Typography>
              <Button
                className="secondary-blue-btn"
                onClick={registrationPage}
                sx={{
                  my: 3,
                  py: 2.75,
                  px: 4,
                  height: "64px",
                  boxShadow: "0px 0px 4px 4px #0000001A",
                  "&:hover": {
                    boxShadow: "0px 0px 4px 4px #0000001A",
                  }
                }}
                endIcon={
                  <svg
                    width="12"
                    height="9"
                    viewBox="0 0 12 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.2929 5H0.5C0.223858 5 0 4.77614 0 4.5C0 4.22386 0.223858 4 0.5 4H10.2929L7.14645 0.853553C6.95118 0.658291 6.95118 0.341709 7.14645 0.146447C7.34171 -0.0488155 7.65829 -0.0488155 7.85355 0.146447L11.8536 4.14645C12.0488 4.34171 12.0488 4.65829 11.8536 4.85355L7.85355 8.85355C7.65829 9.04882 7.34171 9.04882 7.14645 8.85355C6.95118 8.65829 6.95118 8.34171 7.14645 8.14645L10.2929 5Z"
                      fill="#62C275"
                    />
                  </svg>
                }
                variant={"contained"}
              >
                {ReactHtmlParser (t('common.tryitforfree'))}
              </Button>
            </Box>
            <Box
              sx={{
                mt: {xs: 6, md: 0},
                display: { xs: "flex", sm: "flex" },
                justifyContent: "center",
              }}
            >
              <Button variant="text" onClick={() => scrollToSection('howitworks')}>
                <Stack
                  direction={{md: "column", xs: "column-reverse"}}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <svg
                      width="53"
                      height="53"
                      viewBox="0 0 53 53"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M17.6907 31.4878V20.6053C17.6907 15.9836 21.4373 12.237 26.059 12.237C30.6807 12.237 34.4273 15.9836 34.4273 20.6053L34.4273 31.4878C34.4273 36.1094 30.6807 39.856 26.059 39.856C21.4373 39.856 17.6907 36.1094 17.6907 31.4878ZM19.2262 31.4878C19.2262 35.2614 22.2854 38.3205 26.059 38.3205C29.8326 38.3205 32.8917 35.2614 32.8917 31.4878L32.8917 20.6053C32.8917 16.8316 29.8326 13.7725 26.059 13.7725C22.2854 13.7725 19.2262 16.8316 19.2262 20.6053L19.2262 31.4878ZM26.059 17.6906C25.6349 17.6906 25.2912 18.0343 25.2912 18.4583L25.2912 20.6299C25.2912 21.0539 25.6349 21.3977 26.059 21.3977C26.483 21.3977 26.8267 21.0539 26.8267 20.6299L26.8267 18.4583C26.8267 18.0343 26.483 17.6906 26.059 17.6906Z"
                        fill="#2B3674"
                      />
                    </svg>
                  </div>
                  <Typography sx={{ fontWeight: 700, fontSize: 16 }}>
                    {ReactHtmlParser (t('common.knowmore'))}
                  </Typography>
                </Stack>
              </Button>
            </Box>
          </Box>

          <HowItWorksComponent registration = {registrationPage} />
          <FunctionsComponent registration = {registrationPage} />
          <WhoIsItFor registration = {registrationPage} />
          <PricesComponent />
          <FAQComponent />
          
        </Box>
      </div>
      <Footer />
    </>
  );
};

export default HomeComponent;