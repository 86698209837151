import React, { useEffect, useReducer, useState, Suspense } from "react";
import {
  Box,
  Button,
  Stack,
  TextField,
  InputAdornment,
  Typography,
  IconButton,
  Grid,
  Snackbar,
  Alert,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import EyeIcon from "@mui/icons-material/VisibilityOutlined";
import EyeOffIcon from "@mui/icons-material/VisibilityOffOutlined";
import DeleteIcon from "@mui/icons-material/DeleteForeverOutlined";
import { useDispatch, useSelector } from "react-redux";
import { hideSnackbar } from "../../store/Slices/snackbarSlice";

export const ChangeEmail = ({ globalEvent, setGlobalEvent }) => {
  const snackbar = useSelector((state) => state.snackbar);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    mode: "onBlur",
  });

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [type, setType] = useState("password");
  const [visibility, setVisibility] = useState(true);

  const doChangeEmail = (event, data) => {
    event.stopPropagation();
    //
    setGlobalEvent({ type: "doChangeEmailStart", data: data });
  };

  const onSubmit = (data, e) => {
    doChangeEmail(e, data);
    //console.log(data, e);
  };
  const onError = (errors, e) => {
    //console.log(errors, e);
  };

  useEffect(() => {
    if (process.env.REACT_APP_LOG_MESSAGELOG == "on") {
      //console.log("------ Global Event ------ ChangeEmail");
      //console.log(globalEvent);
      //console.log("------------");
    }

    if (globalEvent?.type == "responseChangeEmailStart") {
      navigate("/change-email/step2", { replace: true });
    }

    if (globalEvent?.type == "responseChangeEmailStartError") {
      //console.log(globalEvent?.data);

      if (
        globalEvent?.data?.errors?.length > 0 &&
        globalEvent?.data?.errors[0].message
      ) {
        if (globalEvent?.data?.toastType) {
          globalEvent.data.errors[0].toastType = globalEvent.data.toastType;
        }
        setGlobalEvent({
          type: "showToast",
          data: {
            message: t(`errorResponse.${globalEvent?.data?.errors[0].message}`),
            toastType: "error",
          },
        });
      }
    }
  }, [globalEvent]);

  const handleSnackbarClose = () => {
    dispatch(hideSnackbar());
  };

  return (
    <Box
      sx={{
        mt: { sx: 0, sm: 16 },
        mb: 5,
        px: { xs: 2, lg: 0 },
      }}
    >
      <Box>
        <Typography
          variant="h6"
          sx={{
            mb: 4,
          }}
        >
          {ReactHtmlParser(t("changeemail.title"))}
        </Typography>
        <Typography variant="body3">
          {ReactHtmlParser(t("changeemail.text"))}
        </Typography>
      </Box>

      <Box component="form" onSubmit={handleSubmit(onSubmit, onError)}>
        <TextField
          key="email"
          className="fullwidth-field"
          label={ReactHtmlParser(t("field.email"))}
          type="email"
          {...register("email", {
            pattern: {
              value: /\S+@\S+\.\S+/,
              message: ReactHtmlParser(t("changeemail.error")),
            },
          })}
        />

        {errors.email && <span role="alert">{errors.email.message}</span>}

        <Button
          type="submit"
          variant="contained"
          className="fullwidth-nextbtn"
          sx={{
            bgcolor: "#EB8176",
          }}
        >
          {ReactHtmlParser(t("changeemail.buttondochangeemail"))}
        </Button>
      </Box>

      <Snackbar
        open={false}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};
export default ChangeEmail;
