import Fuse from "fuse.js";

const initialState = {
  data: [],
  home: [],
  groupsData: [],
  loading: false,
  error: null,
  filterType: [],
  searchText: "",
  searchResults: [],
  searchListHL: [],
};

const highlight = (fuseSearchResult, searchText, highlightClassName = "highlight") => {
  const set = (obj, path, value) => {
    const pathValue = path.split(".");
    let i;

    for (i = 0; i < pathValue.length - 1; i++) {
      obj = obj[pathValue[i]];
    }

    obj[pathValue[i]] = value;
  };

  const generateHighlightedText = (inputText, regions = []) => {
    let content = "";
    let nextUnhighlightedRegionStartingIndex = 0;

    regions.forEach((region) => {
      const lastRegionNextIndex = region[1] + 1;

      content += [
        inputText.substring(nextUnhighlightedRegionStartingIndex, region[0]),
        inputText.substring(region[0], lastRegionNextIndex).toLowerCase() == searchText.toLowerCase() ? `<span style="color:#62C275">` : '',
        inputText.substring(region[0], lastRegionNextIndex),
        inputText.substring(region[0], lastRegionNextIndex).toLowerCase() == searchText.toLowerCase() ? "</span>" : "",
      ].join("");

      nextUnhighlightedRegionStartingIndex = lastRegionNextIndex;
    });

    content += inputText.substring(nextUnhighlightedRegionStartingIndex);

    return content;
  };

  return fuseSearchResult
    .filter(({ matches }) => matches && matches.length)
    .map(({ item, matches }) => {
      const highlightedItem = { ...item };

      matches.forEach((match,intialState) => {        
		set(
          highlightedItem,
          match.key,
          generateHighlightedText(match.value, match.indices),
		  
        );
      });

      return highlightedItem;
    });
};

const faqReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FETCH_FAQ_REQUEST":
      return { ...state, loading: true, error: null };
    case "FETCH_FAQ_SUCCESS":
      const groupsData = action.payload.map((row) => ({
        id: row.id,
        title: row.title,
        icon: row.icon,
      }));
      return { ...state, loading: false, data: action.payload, groupsData };
    case "FETCH_FAQ_FAILURE":
      return { ...state, loading: false, error: action.payload };
    case "SET_FILTER_TYPE":
      return { ...state, filterType: action.payload };
    case "SET_SEARCH_TEXT":
      return { ...state, searchText: action.payload };
    case "SET_SEARCH_RESULTS":
      if (!Array.isArray(action.payload) || !action.payload.length) {
        console.error("Invalid data structure");
        return state;
      }

      const fuse = new Fuse(action.payload, {
        keys: ["title", "content"],
        distance: 100,
        isCaseSensitive: false,
        includeMatches: true,
		threshold: 0,
        ignoreLocation: true, 

      });

      const searchResults = fuse.search(state.searchText).slice(0, 99999);
      const searchListHL = highlight(searchResults,state.searchText);
      return { ...state, searchResults, searchListHL };

    case "FETCH_FAQ_HOME_REQUEST":
      return { ...state, loading: true, error: null };
    case "FETCH_FAQ_HOME_SUCCESS":
      return { ...state, loading: false, home: action.payload };
    case "FETCH_FAQ_HOME_FAILURE":
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default faqReducer;
