import { Box, Button, Typography, Stack, dividerClasses } from "@mui/material";
import { FAQCardComponent } from "./FAQCardComponent";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";
import { useDispatch, useSelector } from "react-redux";
import { fetchFaqHome } from "../../../store/Actions/faqActions";

export const FAQComponent = () => {
  const dispatch = useDispatch();
  const { home: homeQuestions = [] } = useSelector((state) => state.faq);
  const [questionsNum, setQuestionsNum] = useState(4);

  const { t } = useTranslation();

  useEffect(() => {
    if (homeQuestions.length === 0) {
      dispatch(fetchFaqHome());

      //console.log("homeQuestions", homeQuestions);
    }
  }, [dispatch, homeQuestions.length]);

  const handleQuestionsNum = () => {
    let newNum = 0;
    if (questionsNum <= homeQuestions.length - 2) {
      newNum = questionsNum + 2;
    } else {
      newNum = homeQuestions.length;
    }
    setQuestionsNum(newNum);
  };

  return (
    <div id="faq">
      <Box
        sx={{
          mt: 12,
          textAlign: "center",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            mb: 3,
          }}
        >
          {ReactHtmlParser(t("faq.homeTitle"))}
        </Typography>
        <svg
          width="175"
          height="13"
          viewBox="0 0 175 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_279_8604)">
            <path
              d="M38.0874 2.64715C54.7105 2.2664 71.3427 2.02163 87.9749 1.91284C104.607 1.80406 121.085 1.84032 137.636 2.01256C147.038 2.11229 156.449 2.24827 165.851 2.42958L167.127 0.571142C145.663 0.94283 124.207 1.40517 102.752 1.95817C81.2967 2.51117 59.8414 3.15482 38.386 3.8982C26.3417 4.31521 14.3064 4.75036 2.26211 5.22177C1.55628 5.24897 0.49754 5.43028 0.0993808 6.11926C-0.271631 6.76291 0.416098 7.09834 0.98619 7.08021C23.3464 6.50908 45.7248 6.45468 68.0941 6.92609C90.4634 7.3975 112.815 8.40378 135.139 9.92679C147.681 10.779 160.214 11.8034 172.729 12.9909C173.407 13.0544 174.529 12.719 174.891 12.0935C175.289 11.4135 174.547 11.1778 174.004 11.1325C151.735 9.02024 129.411 7.4247 107.068 6.35496C84.7263 5.28523 62.3479 4.75036 39.9786 4.7413C27.4095 4.7413 14.8403 4.89541 2.27116 5.22177L0.995239 7.08021C22.4415 6.23711 43.8969 5.49374 65.3522 4.83195C86.8076 4.17923 108.263 3.6081 129.718 3.13669C141.763 2.87379 153.816 2.62902 165.86 2.42051C166.566 2.41145 167.634 2.20294 168.023 1.52303C168.412 0.843109 167.697 0.571142 167.136 0.562077C150.495 0.253848 133.872 0.0634713 117.24 0.0181436C100.607 -0.0271842 84.1291 0.0544058 67.5783 0.281045C58.1763 0.407962 48.7653 0.580208 39.3633 0.797781C38.6575 0.815912 37.5897 1.01535 37.2006 1.69527C36.8296 2.33892 37.5173 2.67435 38.0874 2.65622V2.64715Z"
              fill="#2B3674"
            />
          </g>
          <defs>
            <clipPath id="clip0_279_8604">
              <rect width="175" height="13" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <Stack direction={"column"} spacing={4} sx={{ mt: 12 }}>
          {homeQuestions?.slice(0, questionsNum).map((faq) => (
            <FAQCardComponent key={faq.id} faq={faq} />
          ))}
        </Stack>
        <Button
          className="outlined-btn"
          sx={{
            mt: 6,
            boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.1)",
            mb: 2,
            py: 2,
            px: 4,
          }}
          endIcon={
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.2929 13H7.5C7.22386 13 7 12.7761 7 12.5C7 12.2239 7.22386 12 7.5 12H17.2929L14.1464 8.85355C13.9512 8.65829 13.9512 8.34171 14.1464 8.14645C14.3417 7.95118 14.6583 7.95118 14.8536 8.14645L18.8536 12.1464C19.0488 12.3417 19.0488 12.6583 18.8536 12.8536L14.8536 16.8536C14.6583 17.0488 14.3417 17.0488 14.1464 16.8536C13.9512 16.6583 13.9512 16.3417 14.1464 16.1464L17.2929 13Z"
                fill="#2B3674"
              />
            </svg>
          }
          variant={"outlined"}
          onClick={handleQuestionsNum}
        >
          {ReactHtmlParser(t("common.loadmore"))}
        </Button>
      </Box>
    </div>
  );
};
