import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Paper, Stack, Button, Typography } from "@mui/material";
import "./lightGallery.css";

import DeleteIcon from "@mui/icons-material/DeleteForeverOutlined";
import DragBox from "../DragBox/DragBox";
import DownloadFileWithAuth from "../DataModule/DownloadFileWithAuth";

// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

import LightGallery from "lightgallery/react";
import lgZoom from "lightgallery/plugins/zoom";
import lgVideo from "lightgallery/plugins/video";

function ShowPictureOfAsset({
  globalEvent,
  setGlobalEvent,
  itemList,
  editMode = false,
}) {
  const lightGallery = useRef(null);

  const onInit = useCallback((detail) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);

  useEffect(() => {
    //console.log(itemList);
    lightGallery.current.refresh();
  }, [itemList]);

  const getItems = useCallback(() => {
    return itemList.map((item) => {
      return (
        <>
          <Box
            className="gallery-item"
            data-src={"data:" + item.mimeType + ";base64, " + item.base64}
            sx={{
              minWidth: 137,
              maxWidth: 200,
              height: "auto",
              background:
                "radial-gradient(50% 50% at 50% 50%, rgba(217, 217, 217, 0) 0%, #DADADA 100%)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "16px",
              position: "relative",
            }}
          >
            {editMode && (
              <Button
                variant="text"
                sx={{
                  position: "absolute",
                  minWidth: "50px",
                  width: "50px !important",
                  height: "50px",
                  p: 0,
                  boxShadow: "0px 0px 10px 0px #0000001A",
                  borderRadius: "32px",
                  right: "5px",
                  top: "5px",
                  backgroundColor: "white",
                }}
                onClick={(event) => {
                  event.stopPropagation();
                  setGlobalEvent({
                    type: "doDeleteAttachment",
                    data: { id: item.id ? item.id : 0 },
                  });
                }}
              >
                <DeleteIcon sx={{ color: "info.secondary" }} />
              </Button>
            )}

            <Box
              component={"img"}
              src={"data:" + item.mimeType + ";base64, " + item.base64}
              sx={{
                minWidth: 137,
                maxWidth: 200,
                height: "auto",
                background:
                  "radial-gradient(50% 50% at 50% 50%, rgba(217, 217, 217, 0) 0%, #DADADA 100%)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "16px",
              }}
              fileUrl={"/attachments/" + item.id}
              fileName={item.originalName}
            ></Box>
          </Box>
        </>
      );
    });
  }, [itemList]);

  return (
    <Box
      style={{
        textAlign: "center",
        cursor: "pointer",
        background: "#FAFCFC",
      }}
      sx={{
        padding: 0,
        display: "block",
        pointerEvents: "auto",
      }}
    >
      <LightGallery
        spacing={1}
        elementClassNames="asset-gallery"
        sx={{
          display: "flex",
        }}
        plugins={[lgVideo, lgZoom]}
        onInit={onInit}
      >
        {getItems()}
      </LightGallery>
    </Box>
  );
}

export default ShowPictureOfAsset;
