import React, { useState } from "react";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Box,
} from "@mui/material";

import FacebookIcon from "@mui/icons-material/Facebook";
import FacebookMessengerIcon from "@mui/icons-material/Message";
import ShareIcon from "@mui/icons-material/Share";
import MailIcon from "@mui/icons-material/Mail";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const DialogReferral = ({
  dialogTitle,
  dialogText,
  closeLabel,
  isOpen,
  onClose,
  url,
  subject,
}) => {
  const style = {
    "& .MuiPaper-root": {
      borderRadius: "32px",
      bgcolor: "background.paper",
      boxShadow: "4px 4px 20px 0px #0000001A",
      textAlign: "center",
      padding: 6,
    },
  };

  return (
    <Dialog disableRestoreFocus open={isOpen} onClose={onClose} sx={style}>
      <DialogTitle>
        <Box
          sx={{
            display: "inline-flex",
            width: "3rem",
            height: "3rem",
            mb: 4,
            p: 8,
            borderRadius: "100%",
            boxShadow: "4px 4px 50px 0px #0000001A",
          }}
        >
          <ShareIcon
            sx={{
              width: "3rem",
              height: "3rem",
              color: "primary.main",
              mb: 2,
            }}
          />
        </Box>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {dialogTitle}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography
            id="modal-modal-description"
            variant="body3"
            sx={{ mt: 2 }}
          >
            {dialogText}
          </Typography>
        </DialogContentText>
        <div>
          <Button
            sx={{
              m: 2,
              width: "3.5rem",
              height: "3.5rem",
              borderRadius: "15%",
              backgroundColor: "#00A86B",
            }}
            variant="contained"
            className="email"
            target="blank"
            title="copy"
            onClick={() => {
              navigator.clipboard.writeText(url);
              onClose();
            }}
          >
            <ContentCopyIcon />
          </Button>

          <Button
            sx={{
              m: 2,
              width: "3.5rem",
              height: "3.5rem",
              borderRadius: "15%",
              backgroundColor: "#FFD800",
            }}
            variant="contained"
            className="email"
            href={"mailto://?body=" + url + "&subject=" + subject}
            target="blank"
            title="email"
            onClick={onClose}
          >
            <MailIcon />
          </Button>

          <Button
            sx={{
              m: 2,
              width: "3.5rem",
              height: "3.5rem",
              borderRadius: "15%",
              backgroundColor: "#1877F2",
            }}
            variant="contained"
            className="facebook"
            href={"https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(url)}
            target="blank"
            title="facebook"
            onClick={onClose}
          >
            <FacebookIcon />
          </Button>
		  
          <Button
            sx={{
              m: 2,
              width: "3.5rem",
              height: "3.5rem",
              borderRadius: "15%",
              backgroundColor: "#1877F2",
            }}
            variant="contained"
            className="messenger"
            href={"fb-messenger://share?link=" + encodeURIComponent(url)}
            target="blank"
            title="messenger"
            onClick={() => {
              navigator.clipboard.writeText(url);
              onClose();
            }}
          >
            <FacebookMessengerIcon />
          </Button>		  
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{closeLabel}</Button>
      </DialogActions>
    </Dialog>
  );
};
export default DialogReferral;
