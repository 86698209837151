import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchPlans } from "../../../store/Actions/planAction";
import { getCurrentUser } from "../../../store/Actions/userAction";
import { loadCountries } from "../../../store/Actions/countryAction";
import { t } from "i18next";
import { EditButton } from "../EditButton";

export const BillingDetailsCard = () => {
  const dispatch = useDispatch();
  const { user, loading: userLoading } = useSelector((state) => state.user);
  const { countries } = useSelector((state) => state.country);

  const billingAddress = user?.billingAddress;

  const userCountry = countries.find(
    (row) => billingAddress && row.code === billingAddress.country
  );
  const editTarget = "/billing-details";

  useEffect(() => {
    dispatch(fetchPlans());
    dispatch(loadCountries());
    dispatch(getCurrentUser());
  }, []);

  return (
    <Card
      sx={{
        borderRadius: "16px",
        px: 2,
        pt: { xs: 1, sm: 2 },
        mt: 2,
        boxShadow: "4px 4px 20px 0px #0000001A",
      }}
    >
      <CardContent>
        <Grid container>
          <Grid item xs={6}>
            <Box>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: 700,
                }}
              >
                {t("billingDetailsCard.title")}
              </Typography>

              <Box sx={{ mt: 3 }}>
                {billingAddress && userLoading === false ? (
                  <>
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 700,
                      }}
                    >
                      {billingAddress.name}
                    </Typography>
                    <Typography variant="body2">
                      {billingAddress.postalCode} {billingAddress.city}{" "}
                      {billingAddress.street} {billingAddress.number}
                    </Typography>
                    <Typography variant="body2">{userCountry?.name}</Typography>
                  </>
                ) : (
                  <Typography variant="body2">
                    {t("billingDetailsCard.noData")}
                  </Typography>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <EditButton target={editTarget} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
