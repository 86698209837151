import { Card, CardContent, Typography, Stack, Button, Grid } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";

export const FAQCardComponent = (faq) => {

  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  const [showAnswer, setShowAnswer] = useState(false);
  const handleShowAnswer = () => {
    setShowAnswer(!showAnswer);
  };

  return (
    <Card
      sx={{
        borderRadius: "16px",
        boxShadow: "4px 4px 20px  0px rgba(0, 0, 0, 0.1)",
        px: 4,
        py: { xs: 4, sm: 4 },
        display: { xs: "flex", sm: "block" },
      }}
    >
      <CardContent
        sx={{
          textAlign: "start",
          py: 0,
          px: 0,
          pb: '0 !important',
          width: '100%'
        }}
      >
        <Grid
          container
        >
          <Grid item xs={10} sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: 700,
            }}
          >
            {faq.faq.title}
          </Typography>
          </Grid>
          <Grid item xs={2}
            sx={{
              display: { xs: "flex", md: "none" },
              justifyContent: "end",
              alignItems: "flex-start",
            }}>
            <Button
              variant="text"
              onClick={handleShowAnswer}
              sx={{
                display: { xs: "flex", md: "none" },
                alignItems: "center",
                justifyContent: "end"
              }}
            >
              <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.2327 13.494C20.5122 13.2544 20.9331 13.2868 21.1727 13.5663C21.4123 13.8459 21.3799 14.2667 21.1004 14.5063L16.4337 18.5063C16.1841 18.7203 15.8156 18.7203 15.566 18.5063L10.8993 14.5063C10.6198 14.2667 10.5874 13.8459 10.827 13.5663C11.0666 13.2868 11.4875 13.2544 11.767 13.494L15.9998 17.1221L20.2327 13.494Z"
                    fill="#2B3674"
                  />
                </svg>
            </Button>
          </Grid>
        </Grid>

        <Typography
          variant="subtitle1"
          sx={{
            mt: { md: 0, xs: 4 },
            fontSize: 16,
            display: { md: "block", xs: showAnswer ? "block" : "none" },
          }}
        >
          {faq.faq.content}
        </Typography>
      </CardContent>
    </Card>
  );
};
