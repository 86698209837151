import { jwtDecode } from "jwt-decode";

export const getToken = () => {
  return window.localStorage.getItem("token");
};

export const checkHasToken = () => {
  return typeof getToken() === "string";
};

export const checkTokenIsValid = () => {
  if (checkHasToken()) {
    try {
      const token = getToken();
      const decoded = jwtDecode(token);

      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        //console.log("Token has expired.");
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.error("Invalid token:", error);
      return false;
    }
  }

  return false;
};
