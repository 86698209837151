import React, { useEffect, useReducer, useState, Suspense } from "react";
import EntryList from "./EntryList";
import DefaultLayout from "../Layout/DefaultLayout";
import { Typography, Grid, Box, Stack } from "@mui/material";
import { t } from "i18next";
import { HelpCard } from "../Shared/Cards/HelpCard";
import { ReferralCard } from "../Shared/Cards/ReferralCard";
import WhoSees from "./WhoSees";
import PostMortemEntryForm from "./PostMortemEntryForm";
import { useDispatch, useSelector } from "react-redux";
import { fetchPostMortemEntries } from "../../store/Actions/postMortemActions";
import BannerDisplayer from "../Banner/BannerDisplayer";
import { fetchContacts } from "../../store/Actions/contactAction";
import NoAccess from "../Commons/NoAccess";
import LimitReached from "../Commons/LimitReached";
import HintDisplay from "../Commons/HintDisplay";

export const PostMortem = ({ globalEvent, setGlobalEvent, title }) => {
  const dispatch = useDispatch();
  const { items, loading, error } = useSelector((state) => state.postMortem);
  const { items: cItems, loading: cLoading } = useSelector(
    (state) => state.contact
  );
  const [user, setUser] = useState();
  const [contacts, setContacts] = useState();
  const [whoSeesUsers, setWhoSeesUsers] = useState([]);
  const [chid, setChid] = useState(localStorage.chid);

  const { access, status: hatsStatus } = useSelector((state) => state.hats);

  useEffect(() => {

    dispatch(fetchPostMortemEntries());
    if (cItems?.length === 0 && cLoading === false) {
      dispatch(fetchContacts());
    }
  }, [dispatch]);

  useEffect(() => {
    setGlobalEvent({ type: "doGetUser", data: {} });
    if(!chid || chid == "null") {
      setGlobalEvent({ type: "checkPlan", data: { type: "postMortem" } });
    }
  }, []);

  useEffect(() => {
    const usrList = cItems?.map((cItem) => {
      let date = "";
      let added = false;
      if (cItem.accessTypePostMortemRequests === "instant") {
        date = t("contact.accesstype.instant");
        added = true;
      } else if (cItem.accessTypePostMortemRequests === "timed") {
        date = "betekintés: " + cItem.accessAtPostMortemRequests;
        added = true;
      } else if (cItem.accessTypePostMortemRequests === "onDeath") {
        date = t("contact.accesstype.ondeath");
        added = true;
      }
      return {
        id: cItem.id,
        name: `${cItem.firstName} ${cItem.lastName}`,
        date,
        type: cItem.accessTypePostMortemRequests,
        added,
      };
    });

    setWhoSeesUsers(usrList);
  }, [cItems]);

  if (
    access?.accessPostMortemRequests == false &&
    access?.accessTypePostMortemRequests == "onDeath" &&
    items?.length * 1 == 0
  ) {
    return (
      <NoAccess
        globalEvent={globalEvent}
        setGlobalEvent={setGlobalEvent}
        title={title}
      />
    );
  } else {
    return (
      <>
        <DefaultLayout
          globalEvent={globalEvent}
          setGlobalEvent={setGlobalEvent}
        >
          <BannerDisplayer position={"top"} page={"post_mortem"} />
          <Box className="my-container">
            <Grid
              sx={{
                mt: { xs: 0, sm: 6 },
                mb: 20,
                textAlign: "left",
              }}
              container
              spacing={4}
            >
              <Grid item xs={12} sx={{ px: { md: 3 } }}>
                <Typography variant="h6">
                  {t("postMortemRequest.title")}
                </Typography>
                <Typography variant="body3" sx={{ fontSize: "14px", display: (!chid || chid == "null")?'auto':'none' }}>
                  {t("postMortemRequest.description")}
                </Typography>
              </Grid>

              <LimitReached limitType="postMortemRequests" count={items?.length} />

              <Grid item xs={12} md={7} sx={{ px: { md: 3 } }}>
                <Stack
                  direction={"column"}
                  spacing={2}
                  alignItems={"left"}
                  sx={{ mb: 6 }}
                >
                  {(!chid || chid == "null") && (
                    <PostMortemEntryForm
                      defaultTitle=""
                      isNewEntry={true}
                      globalEvent={globalEvent}
                      setGlobalEvent={setGlobalEvent}
                    />
                  )}
                  
                  <HintDisplay />

                  <EntryList
                    entries={items}
                    isLoading={loading}
                    error={null}
                    entryType={"postMortem"}
					displayMenu={(!chid || chid == "null")}
                  />
                </Stack>

                <BannerDisplayer position={"bottom"} page={"post_mortem"} />
              </Grid>

              <Grid item xs={12} md={5} sx={{ px: { md: 3 } }}>
                <Stack direction={"column"} spacing={2} alignItems={"left"}>
                  {(!chid || chid == "null") && (
                    <WhoSees
                      globalEvent={globalEvent}
                      setGlobalEvent={setGlobalEvent}
                      users={whoSeesUsers}
                      suffix={"PostMortemRequests"}
                    />
                  )}
                  <HelpCard />
                  <ReferralCard />

                  <BannerDisplayer position={"right"} page={"post_mortem"} />
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </DefaultLayout>
      </>
    );
  }
};

export default PostMortem;
