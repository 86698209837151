import { Card, CardContent, Typography, Avatar, Box, Grid } from "@mui/material";
import { t } from "i18next";
import ScreenShare from "./Icons/ScreenShare";
import ButtonwithArrow from "./ButtonWithArrow";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { fetchPlans } from "../../store/Actions/planAction";
import { getCurrentUser } from "../../store/Actions/userAction";

const LimitReached = ({limitType, count}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isVisible, setIsVisible] = useState(false);

  const { user } = useSelector((state) => state.user);
  const { plans } = useSelector((state) => state.plan);
  const chid = localStorage.getItem("chid");

  useEffect(() => {
    if (plans.length === 0) {
      dispatch(fetchPlans());
    }

    dispatch(getCurrentUser());
  }, []);

  useEffect(() => {
    if(user &&plans.length > 0) {
        const plan = plans.find((plan) => plan.name === user?.subscription?.planName);
        if(plan && plan?.limits[limitType].count <= count) {
          if(!chid || chid === "null") {
            setIsVisible(true);
          }
        }
    }
  }, [user, plans]);

  
  return (isVisible ? (
    <Grid item xs={12} sx={{mb: 4, mr:3}}>
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        py: 2,
        px: 5,
        borderRadius: "24px",
        border: "1px solid #2B36741A",
        boxShadow: "none",
      }}
    >
      <Avatar sx={{ bgcolor: "#FFF27E", marginRight: 1, padding: 2 }}>
        <ScreenShare />
      </Avatar>
      <CardContent sx={{ flex: "1 0 auto" }}>
        <Typography
          variant="body1bold"
          component="div"
          sx={{
            fontSize: "18px",
          }}
        >
          {t("limitReached.title")}
        </Typography>
        <Typography variant="body2regular">
          {t("limitReached.description")}
        </Typography>
      </CardContent>
      <Box>
        <ButtonwithArrow
          text={t("limitReached.button")}
          className={"green-btn"}
          onClick={() => {
            navigate("/subscription");
          }}
        />
      </Box>
    </Card>
    </Grid>
  ) : null);
};

export default LimitReached;
