import Fuse from "fuse.js";
import { fetchWithLocale } from "../api";

export const fetchFaqData = () => {
  return async (dispatch) => {
    dispatch({ type: "FETCH_FAQ_REQUEST" });
    try {
      const response = await fetchWithLocale("/faq");
      dispatch({ type: "FETCH_FAQ_SUCCESS", payload: response.groups });
    } catch (error) {
      dispatch({ type: "FETCH_FAQ_FAILURE", payload: error.message });
    }
  };
};

export const fetchFaqHome = () => {
  return async (dispatch) => {
    dispatch({ type: "FETCH_FAQ_HOME_REQUEST" });
    try {
      const response = await fetchWithLocale("/faq/home");
      dispatch({ type: "FETCH_FAQ_HOME_SUCCESS", payload: response.faqItems });
    } catch (error) {
      dispatch({ type: "FETCH_FAQ_HOME_FAILURE", payload: error.message });
    }
  };
};

export const setFilterType = (filterType) => {
  return {
    type: "SET_FILTER_TYPE",
    payload: filterType,
  };
};

export const setSearchText = (searchText) => {
  return {
    type: "SET_SEARCH_TEXT",
    payload: searchText,
  };
};

export const searchFaq = (searchText, faqData, filterType) => {
  return (dispatch) => {
    if (!searchText) {
      dispatch({ type: "SET_SEARCH_RESULTS", payload: [] });
      return;
    }

    const allItems = faqData.flatMap((group) =>
      group.items.map((item) => ({ ...item, groupid: group.id }))
    );

    const fuse = new Fuse(allItems, {
      keys: ["title", "content"],
      distance: 0,
      isCaseSensitive: false,
      includeMatches: false,
      minMatchCharLength: searchText.length,
      threshold: 0,
      ignoreLocation: true, 
    });

    const results = fuse.search(searchText).map((result) => result.item);
    const filteredResults = results.filter(
      (item) => filterType.length === 0 || filterType.includes(item.groupid)
    );

    dispatch({ type: "SET_SEARCH_RESULTS", payload: filteredResults });
  };
};
