import { useState, useEffect } from "react";
import b120Text from "../../Assets/b120-text.png";
import lifetresor from "../../Assets/the-LIFETRESOR.png";
import {
  Box,
  Button,
  Stack,
  TextField,
  MenuItem,
  Menu,
  InputAdornment,
  Link,
  Drawer,
} from "@mui/material";
import ProfileMenu from "./ProfileMenu";
import { LanguageSelector } from "./LanguageSelector";
import { t } from "i18next";

import { useNavigate, useLocation } from "react-router-dom";
import { logoutUser } from "../../store/Actions/userAction";

import { useDispatch, useSelector } from "react-redux";

export const MainMobileMenu = ({globalEvent, setGlobalEvent}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [user, setUser] = useState(null);
  const [chid, setChid] = useState(localStorage.chid);

  const { access, status: hatsStatus } = useSelector((state) => state.hats);


  const [language, setLanguage] = useState("hu");
  const [toggleMenu, setToggleMenu] = useState(false);
  const toggleDrawer = () => {
    setToggleMenu(!toggleMenu);
  };
  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
    //console.log(language);
  };

  const showDashboard = (event) => {
    navigate("/");
  };

  const logOff = (event) => {
    dispatch(logoutUser());
    if (location.pathname === "/") {
      navigate("/login", { replace: true });
    } else {
      navigate("/", { replace: true });
    }
  };

  const navigationItems = [
    {
      label: t("mainMenu.dashboard"),
      path: "/",
    },
    (access?.accessAssets === false ? {}:{
      label: t("mainMenu.assetList"),
      path: "/asset-list",
    }),
    ((!chid||chid=='null') ? {
      label: t("mainMenu.contactList"),
      path: "/contact-list",
    }:{}),
    (access?.accessPostMortemRequests === false ? {}:{
      label: t("mainMenu.instructions"),
      path: "/post-mortem-request",
    }),
    (access?.accessDiaryItems === false ? {}:{
      label: t("mainMenu.diary"),
      path: "/time-capsule",
    }),

  ];
  
   useEffect(() => {
    if (process.env.REACT_APP_LOG_MESSAGELOG == "on") {
      //console.log("------ Global Event ------ MobileMainMenu");
      //console.log(globalEvent);
      //console.log("------------");
    }
	
	if (globalEvent?.type == "responseChangeHat") {
  	  setChid(globalEvent?.data.currentHatId);
    }
	
	
  }, [globalEvent]);

  return (
    <>
      <Box sx={{ displa: "flex", justifyContent: "center", px: 3 }}>
        <Stack
          direction={"row"}
          spacing={2}
          sx={{
            position: "relative",
            zIndex: 1,
            bgcolor: "#FFFFFF",
            borderRadius: "12px",
            mt: 6,
            mb: 6,
            py: 1,
            px: 2,
            alignItems: "center",
            justifyContent: "space-between",
            boxShadow: "0px 0px 10px 0px #0000001A",
          }}
        >
          <ProfileMenu
            globalEvent={globalEvent}
            setGlobalEvent={setGlobalEvent}
          />
          <Button sx={{ ml: 8, width:"auto !important" }} onClick={toggleDrawer}>
            <svg
              width="46"
              height="46"
              viewBox="0 0 46 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect width="46" height="46" rx="23" fill="#2B3674" />
              <line x1="13" y1="15.5" x2="32" y2="15.5" stroke="white" />
              <line x1="13" y1="22.5" x2="32" y2="22.5" stroke="white" />
              <line x1="13" y1="29.5" x2="32" y2="29.5" stroke="white" />
            </svg>
          </Button>
        </Stack>
      </Box>
      <Drawer
        open={toggleMenu}
        PaperProps={{
          sx: { width: "100%", bgcolor: "primary.dark" },
        }}
      >
        <Box sx={{ mt: 10, px: 3 }}>
          <Stack
            direction={"row"}
            sx={{
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                bgcolor: "#FFFFFF",
                borderRadius: "100px",
              }}
            >
              <LanguageSelector />
            </Box>

            <Button
              onClick={toggleDrawer}
              variant="text"
              sx={{
                bgcolor: "#FFFFFF",
                minWidth: "50px",
                width: "50px !important",
                height: "50px",
                p: 0,
                borderRadius: "32px",
              }}
            >
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.9997 15.0578L21.5283 9.52925C21.7886 9.2689 22.2107 9.2689 22.4711 9.52925C22.7314 9.7896 22.7314 10.2117 22.4711 10.4721L16.9425 16.0007L22.4711 21.5292C22.7314 21.7896 22.7314 22.2117 22.4711 22.4721C22.2107 22.7324 21.7886 22.7324 21.5283 22.4721L15.9997 16.9435L10.4711 22.4721C10.2107 22.7324 9.78862 22.7324 9.52827 22.4721C9.26792 22.2117 9.26792 21.7896 9.52827 21.5292L15.0569 16.0007L9.52827 10.4721C9.26792 10.2117 9.26792 9.7896 9.52827 9.52925C9.78862 9.2689 10.2107 9.2689 10.4711 9.52925L15.9997 15.0578Z"
                  fill="#2B3674"
                />
              </svg>
            </Button>
          </Stack>
          <Box
            sx={{
              height: "350px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Stack direction={"column"} spacing={2} sx={{ mt: 4, pl: 2 }}>
              {navigationItems.map((item) => (
                <Link
                  sx={{ color: "#FFFFFF" }}
                  onClick={(event) => {
                    item.label === t("navigation.dashboard")
                      ? showDashboard(event)
                      : navigate(item.path);
                  }}
                >
                  {item.label}
                </Link>
              ))}
            </Stack>
          </Box>
          <Button
            variant="contained"
            endIcon={
              <svg
                width="16"
                height="9"
                viewBox="0 0 16 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.6535 5H1.34244C0.995288 5 0.713867 4.77614 0.713867 4.5C0.713867 4.22386 0.995288 4 1.34244 4H13.6535L9.69797 0.853553C9.4525 0.658291 9.4525 0.341709 9.69797 0.146447C9.94344 -0.0488155 10.3414 -0.0488155 10.5869 0.146447L15.6155 4.14645C15.861 4.34171 15.861 4.65829 15.6155 4.85355L10.5869 8.85355C10.3414 9.04882 9.94344 9.04882 9.69797 8.85355C9.4525 8.65829 9.4525 8.34171 9.69797 8.14645L13.6535 5Z"
                  fill="white"
                />
              </svg>
            }
            sx={{
              bgcolor: "primary.dark",
              border: "2px solid #FFFFFF",
              borderRadius: "50px",
              lineHeight: "32px",
              height: "52px",
              width: "100%",
            }}
            onClick={logOff}
          >
            {t("mainMenu.logout")}
          </Button>
        </Box>
      </Drawer>
    </>
  );
};
