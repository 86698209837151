import React, { useEffect } from "react";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
import { Typography, Grid, Box, Stack, Card, CardContent } from "@mui/material";

export const NewAssetCard = ({ link, subtype, icon, title, datas, globalEvent, setGlobalEvent }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (process.env.REACT_APP_LOG_MESSAGELOG == "on") {
      //console.log("------ Global Event ------ NewAssetCard");
      //console.log(globalEvent);
      //console.log("------------");
    }

    if (globalEvent?.type == "responseCheckPlan") {
		  if (globalEvent?.data.link == link){
		  navigate(globalEvent?.data.link, {
            state: {
              editmode: true,				
              data: datas,
            },
          });
		  }
    }

  }, [globalEvent]);



  return (
    <>
      <Card
        onClick={(event) => {
          setGlobalEvent({ type: "checkPlan", data: {type:'asset',subtype:subtype,link:link} });
		  /*navigate(link, {
            state: {
              editmode: true,				
              data: datas,
            },
          });*/
        }}
        sx={{
          boxShadow: "0px 0px 16px 0px #0000001A",
          borderRadius: "16px",
          width: "160px",
          height: "140px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "2px solid #FFFFFF",
          "&:hover": {
            border: "2px solid #62C275",
          },
          cursor: "pointer",
        }}
      >
        <CardContent>
          <Stack
            direction={"column"}
            spacing={1}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {icon}
            <Typography
              varinat="body3"
              sx={{
                fontSize: "14px",
                lineHeight: "17.64px",
                fontWeight: 700,
              }}
            >
              {title}
            </Typography>
          </Stack>
        </CardContent>
      </Card>
    </>
  );
};
