import {
    Card,
    CardContent,
    Typography,
    Stack,
    Button,
    Box,
  } from "@mui/material";
  import { useNavigate } from "react-router-dom";
  
  export const EmptyCard = ({ icon, title, description, buttonText, buttonLink, buttonClass="small-btn", hasShadow=true }) => {
    
    const navigate = useNavigate();
    
    const handleClick = (e) => {
      //console.log('handleclick');
      if (typeof buttonLink === 'string') {
        navigate(buttonLink);
      } else if (typeof buttonLink === 'function') {
        buttonLink(e);
      }
    };
    
    return (
      <Card
        sx={{
          borderRadius: "16px",
          p: 4,
          boxShadow: hasShadow?"4px 4px 20px 0px #0000001A":"default",
        }}
      >
        <CardContent sx={{ display: "flex", justifyContent: "center" }}>
          <Stack
            direction={"column"}
            spacing={2}
            alignItems={"center"}
            textAlign={"center"}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "64px",
                height: "80px",
                width: "80px",
                bgcolor: "#F8F8F8",
              }}
            >
              {icon}
            </Box>
            <Typography variant="body2" sx={{ fontWeight: 700 }}>
              {title}
            </Typography>
            <Typography variant="body2">
              {description}			
            </Typography>
            <Button
              onClick={handleClick}
              className={buttonClass}
              variant="contained"
              sx={{ height: "52px", width:"auto !important" }}
              endIcon={
                <svg
                  width="12"
                  height="9"
                  viewBox="0 0 12 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.2929 5H0.5C0.223858 5 0 4.77614 0 4.5C0 4.22386 0.223858 4 0.5 4H10.2929L7.14645 0.853553C6.95118 0.658291 6.95118 0.341709 7.14645 0.146447C7.34171 -0.0488155 7.65829 -0.0488155 7.85355 0.146447L11.8536 4.14645C12.0488 4.34171 12.0488 4.65829 11.8536 4.85355L7.85355 8.85355C7.65829 9.04882 7.34171 9.04882 7.14645 8.85355C6.95118 8.65829 6.95118 8.34171 7.14645 8.14645L10.2929 5Z"
                    fill="#E5FE4A"
                  />
                </svg>
              }
            >
              {buttonText}
            </Button>
          </Stack>
        </CardContent>
      </Card>
    );
  };
  