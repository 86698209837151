import React, {
  props,
  useRef,
  useEffect,
  useReducer,
  useState,
  Suspense,
} from "react";
import { Paper, Grid, Box, Typography, Link, Stack } from "@mui/material";
import youtube from "../Assets/youtube-footer-logo.png";
import instagram from "../Assets/Instagram-footer-logo.png";
import facebook from "../Assets/facebook-footer-logo.png";
import linkedin from "../Assets/LinkedIN-footer-logo.png";

import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";

export const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  return (
    <Paper
      sx={{
        position: "relative",
        mt: 10,
        bottom: 0,
        width: "100%",
        bgcolor: "primary.dark",
        borderRadius: 0,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Grid
        container
        className="my-container"
        sx={{
          py: 10,
          pl: { xs: 6, md: 0 },
          height: 1,
        }}
      >
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            order: { xs: 3, md: 1 },
          }}
        >
          <Typography
            variant="body2"
            sx={{ fontWeight: 700, color: "#FFFFFF" }}
          >
            {ReactHtmlParser(t("footer.title"))}
          </Typography>
          <Typography variant="caption" color={"#FFFFFF"} fontWeight={500}>
            {ReactHtmlParser(t("footer.description"))}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          sx={{ display: { xs: "none", md: "block" }, order: { xs: 0, md: 2 } }}
        ></Grid>
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            order: { xs: 1, md: 3 },
            display: "flex",
            mb: { md: 0, xs: 1 },
            pr: { xs: 0, md: 5 },
            justifyContent: { xs: "left", md: "end" },
          }}
        >
          <Box>
            <Link
              onClick={(event) => {
                navigate("/static/about-us");
              }}
              sx={{ cursor: "pointer" }}
            >
              {ReactHtmlParser(t("link.aboutUs"))}
            </Link>
            <br />
            <Link
              onClick={(event) => {
                navigate("/static/termsofuse");
              }}
              sx={{ cursor: "pointer" }}
            >
              {ReactHtmlParser(t("link.termsofuse"))}
            </Link>
            <br />
            <Link
              onClick={(event) => {
                navigate("/static/privacystatement");
              }}
              sx={{ cursor: "pointer" }}
            >
              {ReactHtmlParser(t("link.privacystatement"))}
            </Link>
            <br />
            <Link
              onClick={(event) => {
                navigate("/static/impressum");
              }}
              sx={{ cursor: "pointer" }}
            >
              {ReactHtmlParser(t("link.impressum"))}
            </Link>
            <br />
            <Link
              onClick={(event) => {
                navigate("/static/cookie");
              }}
              sx={{ cursor: "pointer" }}
            >
              {ReactHtmlParser(t("link.cookie"))}
            </Link>
            <br />
            <Link
              onClick={(event) => {
                navigate("/static/impressum");
              }}
              sx={{ cursor: "pointer" }}
            >
              {ReactHtmlParser(t("link.contact"))}
            </Link>
            <br />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          sx={{
            order: { xs: 2, md: 4 },
            mb: { md: 0, xs: 6 },
            mt: { xs: 2, md: 0 },
          }}
        >
          <Stack
            direction={"row"}
            spacing={2}
            sx={{
              justifyContent: { xs: "left", md: "end" },
              pr: { xs: 2, xl: 0 },
            }}
          >
            <a
              href="https://www.facebook.com/profile.php?id=61555972480496"
              target="_blank"
              rel="noreferrer"
            >
              <img src={facebook} alt="facebook" />
            </a>
            <a
              href="https://www.linkedin.com/company/b120-lifetresor"
              target="_blank"
              rel="noreferrer"
            >
              <img src={linkedin} alt="linkedin" />
            </a>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  );
};
