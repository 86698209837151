import React, { useEffect, useReducer, useState, Suspense } from "react";
import LinkOffIcon from "@mui/icons-material/LinkOffRounded";

import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import { useForm } from "react-hook-form";

import {
  Box,
  Button,
  Stack,
  TextField,
  MenuItem,
  InputAdornment,
  Grid,
  Typography,
  IconButton,
  Modal,
} from "@mui/material";

import { useTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";
export const ConnectModal = ({ type, open, handleClose, handleClick }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: "onBlur",
  });

  const navigate = useNavigate();

  const onSubmit = (data, e) => {
    //console.log(data, e);
  };
  const onError = (errors, e) => {
    //console.log(errors, e);
  };

  const [password, setPassword] = useState("");
  const [visibility, setVisibility] = useState(true);

  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);

  useEffect(() => {
    setTimeout(() => {
      if (type == "add_apple")
        window.AppleID.auth.init({
          clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
          scope: "name email",
          redirectURI: process.env.REACT_APP_CLIENT_URL,
          state: "SignInUserAuthenticationRequest",
          nonce: String(Date.now()),
          usePopup: true,
        });
      if (type == "add_google")
        window.google.accounts.id.renderButton(
          document.getElementById("googleButtonDiv"),
          {
            theme: "outline",
            size: "large",
            shape: "circle",
            width: "375",
            logo_alignment: "center",
          }
        );
    }, 300);
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 628,
    height: 563,
    borderRadius: "32px",
    bgcolor: "background.paper",
    boxShadow: "4px 4px 20px 0px #0000001A",
    textAlign: "center",
  };
  return (
    <Modal
      open={open}
      onClose={handleClick}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            p: 6,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "120px",
              height: "120px",
              mb: 4,
              p: 8,
              borderRadius: "100%",
              boxShadow: "4px 4px 50px 0px #0000001A",
            }}
          >
            <svg
              width="76"
              height="77"
              viewBox="0 0 76 77"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M60.8091 45.3509L56.4583 41.0001L64.7916 32.6667C66.1805 31.2778 67.2569 29.6459 68.0208 27.7709C68.7847 25.8959 69.1666 23.9515 69.1666 21.9376C69.1666 17.7709 67.6909 14.2119 64.7396 11.2605C61.7882 8.30909 58.2291 6.83339 54.0625 6.83339C52.0486 6.83339 50.1042 7.21534 48.2292 7.97923C46.3542 8.74312 44.7222 9.81951 43.3333 11.2084L35 19.5417L30.6491 15.1909L38.9424 6.85756C40.9777 4.84902 43.2925 3.30121 45.887 2.21413C48.4815 1.12704 51.2025 0.583496 54.0502 0.583496C59.9985 0.583496 65.0466 2.65694 69.1946 6.80381C73.3426 10.9507 75.4165 15.9997 75.4165 21.9509C75.4165 24.7999 74.873 27.5211 73.7859 30.1145C72.6988 32.708 71.151 35.0224 69.1425 37.0577L60.8091 45.3509ZM25.9375 65.1667C27.9514 65.1667 29.8958 64.7848 31.7708 64.0209C33.6458 63.257 35.2778 62.1806 36.6666 60.7917L46.1299 51.3285L24.6715 29.8702L15.2083 39.3334C13.8194 40.7223 12.743 42.3542 11.9791 44.2292C11.2153 46.1042 10.8333 48.0487 10.8333 50.0626C10.8333 54.2292 12.309 57.7883 15.2604 60.7396C18.2118 63.691 21.7708 65.1667 25.9375 65.1667ZM50.4807 55.6793L41.0576 65.1426C39.049 67.1511 36.7419 68.6989 34.1361 69.786C31.5304 70.8731 28.7975 71.4166 25.9375 71.4166C19.99 71.4166 14.9441 69.3432 10.7999 65.1963C6.65557 61.0494 4.58342 56.0004 4.58342 50.0492C4.58342 47.2002 5.12696 44.4723 6.21405 41.8655C7.30113 39.2587 8.84894 36.951 10.8575 34.9425L20.3207 25.5193L1.07384 6.27246C0.496962 5.69551 0.201823 4.97038 0.188421 4.09704C0.175087 3.22364 0.470226 2.48513 1.07384 1.88152C1.67745 1.27791 2.40929 0.976105 3.26936 0.976105C4.12936 0.976105 4.86116 1.27791 5.46478 1.88152L74.5352 70.9519C75.1121 71.5288 75.4072 72.254 75.4206 73.1273C75.4339 74.0008 75.1388 74.7393 74.5352 75.3429C73.9316 75.9465 73.1997 76.2483 72.3397 76.2483C71.4797 76.2483 70.7479 75.9465 70.1442 75.3429L50.4807 55.6793Z"
                fill="#2B3674"
              />
            </svg>
          </Box>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {(type == "remove_google" || type == "remove_apple") && (
              <>{t("profile.connectModal.titleRemove")}</>
            )}

            {(type == "add_google" || type == "add_apple") && (
              <>{t("profile.connectModal.titleAdd")}</>
            )}
          </Typography>
          <Typography
            id="modal-modal-description"
            variant="body3"
            sx={{ mt: 2 }}
          >
            {(type == "remove_google" || type == "remove_apple") && (
              <>{t("profile.connectModal.textRemove")}</>
            )}
            {(type == "add_google" || type == "add_apple") && (
              <>{t("profile.connectModal.textAdd")}</>
            )}
          </Typography>
          <Stack
            direction={"row"}
            spacing={2}
            sx={{ mt: 4, alignItems: "center" }}
          >
            <Button
              onClick={handleClose}
              variant="text"
              sx={{
                lineHeight: "20.16px",
              }}
            >
              {ReactHtmlParser(t("common.buttondocancel"))}
            </Button>

            {type == "add_google" && (
              <div>
                <div
                  id="googleButtonDiv"
                  className="google-btn"
                  data-context="signin"
                  data-ux_mode="popup"
                  data-auto_prompt="false"
                ></div>
              </div>
            )}
            {type == "add_apple" && (
              <div>
                <div
                  style={{ marginTop: "0px" }}
                  id="appleid-signin"
                  className="signin-button apple-btn"
                  data-color="black"
                  data-border="true"
                  data-type="sign-in"
                  data-border-radius="50"
                  data-height="44"
                ></div>
              </div>
            )}
            {(type == "remove_google" || type == "remove_apple") && (
              <Button
                onClick={(ev) => {
                  handleClick();
                }}
                endIcon={<LinkOffIcon sx={{ color: "secondary.light" }} />}
                variant={"contained"}
                className="small-btn"
              >
                {t("profile.connectModal.disconnect")}
              </Button>
            )}
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};
